import React, {useContext} from "react";
import {
    Box, Button,
    Grid, Stack,
    Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import '../App.css';
import TextWithBr from "./TextWithBr";
import DoneIcon from '@mui/icons-material/Done';
import TireServiceStatic from "../services/TireServiceStatic";
import {ServiceType} from "../constants/ServiceType";
import MobileContext from "../contexts/mobileContext";
import FormatUtils from "../utils/FormatUtils";

function ServicePriceItem({
                              serviceData,
                              selected,
                              clickedHandler: clickedHandler
                          }) {
    const {t} = useTranslation('common');

    const mobileContext = useContext(MobileContext);
    const isMobile = mobileContext.isMobile;

    let description = serviceData.description;

    let border = 1;
    let borderColor = '';
    if (selected) {
        border = isMobile ? 1 : 2;
        borderColor = '#fbc70f';
    }

    let tireOrWheelAsset = null;
    if (serviceData.service_type_id == ServiceType.TIRES) {
        tireOrWheelAsset = TireServiceStatic.getTireOrWheelById(serviceData.tire_or_wheel).icon;
    }

    const reserveButton = <Button
        sx={{
            m: isMobile ? '5px' : null,
            mt: isMobile ? '5px' : 'auto',
            mb: '20px',
            width: isMobile ? null : '50%',
            alignSelf: isMobile ? null : 'center'
        }}
        variant="contained"
        color="yellow"
        onClick={(e) => clickedHandler(serviceData)}
    >
        {
            selected ? <DoneIcon/> :
                t('service.step3.reserve')}
    </Button>

    return (
        <Box
            border={border}
            borderColor={borderColor}
            height={isMobile ? '50vh' : 400}
            width={isMobile ? null : 400}
            marginLeft={isMobile ? '5px' : null}
            marginRight={isMobile ? '5px' : null}
        >
            <Grid container justifyContent="center" height="100%">
                <Stack height="100%">
                    <Typography mt={2} fontSize={isMobile ? 15 : 20} textAlign="center">{serviceData.name}</Typography>
                    <Grid container justifyContent="center" alignItems="center">
                        <Typography color="#fbc70f"
                                    fontSize={isMobile ? 20 : 50}>{FormatUtils.formatPrice(serviceData.price.price)} </Typography>
                    </Grid>
                    <Grid
                        sx={{
                            borderBottom: 1,
                            borderColor: 'divider',
                            marginTop: isMobile ? '5px' : '10px',
                            marginBottom: isMobile ? '5px' : '10px'
                        }}/>
                    {
                        isMobile && reserveButton
                    }
                    {
                        tireOrWheelAsset != null &&
                        <Grid container justifyContent="center" alignItems="center">
                            <img
                                height="75px"
                                width="75px"
                                src={tireOrWheelAsset}
                                style={{
                                    objectFit: 'contain'
                                }}
                            />
                        </Grid>
                    }

                    <TextWithBr
                        textAlign="center"
                        text={description}
                        useTypographyTag={true}
                        fontSize={'0.8em'}
                    />
                    {
                        !isMobile && reserveButton
                    }
                </Stack>
            </Grid>
        </Box>
    )
};

export default ServicePriceItem;