import {CardElement, PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import React, {useEffect, useState} from "react";
import useFetch from "../hooks/useFetch";
import {useOutletContext} from "react-router-dom";
import Swal from "sweetalert2";
import {useTranslation} from "react-i18next";
import {Button} from "@mui/material";

// https://www.mohammadfaisal.dev/blog/how-to-create-a-stripe-subscription-with-reactjs-and-nodejs
function SubscriptionCheckoutForm({
                                      clientSecret
                                  }) {
    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const {t} = useTranslation('common');

    useEffect(() => {
        // console.log('UE stripe', stripe, clientSecret);
        if (!stripe) {
            return;
        }

        // const clientSecret = new URLSearchParams(window.location.search).get(
        //   "payment_intent_client_secret"
        // );

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({paymentIntent}) => {
            switch (paymentIntent.status) {
                case "succeeded":
                    setMessage("Payment succeeded!");
                    break;
                case "processing":
                    setMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    // setMessage("Your payment was not successful, please try again.");
                    break;
                default:
                    setMessage("Something went wrong.");
                    break;
            }
        });
    }, [stripe]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        setIsLoading(true);
        let location = window.location;
        let callbackUrl = location.protocol + '//' + location.hostname + ':' + location.port + '/test_stripe_abo_callback';

        const {error} = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: callbackUrl,
            },
        });

        if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            if (error.type === "card_error" || error.type === "validation_error") {
                setMessage(error.message);
            } else {
                setMessage("An unexpected error occurred.");
            }
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
        setIsLoading(false);
    };

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            {/*<CardElement/>*/}
            <PaymentElement id="payment-element"/>
            <Button
                id="submit"
                type={null}
                disabled={isLoading || !stripe || !elements}
                variant="contained"
                color="yellow"
                sx={{mt: '15px', mb: '20px'}}
            >
                {t('service.step_stripe.pay_button')}
            </Button>

            {/* Show any error or success messages */}
            {message && <div id="payment-message">{message}</div>}
        </form>
    );
}

export default SubscriptionCheckoutForm;