import moment from "moment";
import 'moment/locale/fr';

export default class FormatUtils {

    static getDateFormatByLanguage(language, withYear = true) {
        let format = 'MM/DD';
        if (language == 'fr') {
            format = 'DD/MM';
        }
        if (withYear) {
            format += '/YYYY';
        }
        return format;
    }

    static formatDayDate(sqlDate, language) {
        moment.locale(language);
        let date = moment(sqlDate);
        let str = date.format("dddd " + this.getDateFormatByLanguage(language));
        str = str.toUpperCase();
        return str;
    }

    // Pas de jour , pas d'année
    static formatDayDateShort(sqlDate, language) {
        moment.locale(language);
        let date = moment(sqlDate);
        let str = date.format(this.getDateFormatByLanguage(language, false));
        str = str.toUpperCase();
        return str;
    }

    static formatSqlDateToCondensed(sqlDate, language, withTime = false) {
        moment.locale(language);
        let date = moment(sqlDate);
        let str;
        if (withTime) {
            str = date.format(this.getDateFormatByLanguage(language) + ' HH:mm')
        } else {
            str = date.format(this.getDateFormatByLanguage(language));
        }

        str = str.toUpperCase();
        return str;
    }

    static formatSqlDateWithFullMonth(sqlDate, language) {
        moment.locale(language);
        let date = moment(sqlDate);
        let str;
        str = date.format('DD MMMM YYYY');
        return str;
    }

    static formatServiceDateWithTimeSlot(sqlDate, timeSlot, language, t) {
        let result = '';
        result += this.formatDayDate(sqlDate, language);
        result += ' - ' + t('common.' + timeSlot);
        return result;
    }

    static formatTimeSlot(timeSlot, dayData, t) {
        let result = '';
        let schedules = '';
        if (timeSlot == 'am') {
            result += t('common.am');
            schedules += dayData.am_start + ' - ' + dayData.am_end;
        } else {
            result += t('common.pm');
            schedules += dayData.pm_start + ' - ' + dayData.pm_end;
        }

        result += ' (' + schedules + ')';

        return result;
    }

    static formatSchedule(dayData, t) {
        let result = '';
        if (dayData.am_closed == 0) {
            result += dayData.am_start + ' ' + t('schedule.to') + ' ' + dayData.am_end;
            result += ' - ';
        }
        if (dayData.pm_closed == 0) {
            result += dayData.pm_start + ' ' + t('schedule.to') + ' ' + dayData.pm_end;
        }

        return result;
    }

    static formatAddress(location) {
        let result = location.address + ', ' + location.postal_code + ' ' + location.city;
        return result;
    }

    static formatOrderStatus(statusId, t) {
        return t('order_status.' + statusId);
    }

    static formatPaymentType(paymentTypeId, t) {
        return t('payment_type.' + paymentTypeId);
    }

    static formatTire(tire, t) {
        let value = tire.count + tire.position;
        let str = tire.width + '/' + (tire.height != null ? tire.height : '??') + 'R' + tire.tire_category.label.substr(0, 2)
            + ' ' + (tire.is_wheel == '1' ? t('vehicle.item.info.tire_wheel') : t('vehicle.item.info.tire_tire'))
            + ' ' + t('vehicle.item.info.tire_' + value)
        return str;
    }

    static formatOrderTire(orderTire, t) {
        let tire = orderTire.tire;
        tire.count = orderTire.count;
        tire.value = orderTire.position;
        return FormatUtils.formatTire(tire, t);
    }

    // Remplace les valeurs {truc} dans un texte par les jokers du tableau values : truc:123
    // values = json
    static changeTextWithValues(text, values) {
        if (values == null) {
            return text;
        }
        Object.entries(values).forEach(([key, value]) => {
            // console.log(key, '=>', value);
            text = text.replace('{' + key + '}', value);
        })
        return text;
    }

    static cloneData(data) {
        return (JSON.parse(JSON.stringify(data)));
    }

    // => SAMEDI 21/01/2023 08h00 - 12h00
    // TODO Utiliser getPerdioStartEnd ?
    static getPeriodHuman(dayData, period, language) {
        let value = '';
        switch (period) {
            case 'am':
                value = dayData.am_start + ' - ' + dayData.am_end;
                break;
            case 'pm':
                value = dayData.pm_start + ' - ' + dayData.pm_end;
                break;
            default:
                return null;
        }
        value = value.replaceAll(':', 'h');
        value = FormatUtils.formatDayDate(dayData.date, language) + ' ' + value;
        return value;
    }

    static getPeriodStartEnd(dayData, period) {
        switch (period) {
            case 'am':
                return [dayData.am_start, dayData.am_end];
                break;
            case 'pm':
                return [dayData.pm_start, dayData.pm_end];
                break;
        }
        return null;
    }


    static isNotSet(value) {
        let isNotSet = value == null || value == undefined || value == '';
        // console.log('isNotSet', value, isNotSet);
        return isNotSet;
    }

    static calendarDayDataIsException(calendarDayData) {
        if (!('schedulesExceptionId' in calendarDayData)) {
            return false;
        }
        // console.log(calendarDayData.schedulesExceptionId);
        return calendarDayData.schedulesExceptionId.toString().charAt(0) != 'D';
    }

    static formatRetrieveAt(retrieveAt, language) {
        let day = this.formatDayDate(retrieveAt, language);
        let date = moment(retrieveAt);
        let str = day + (language == 'fr' ? ' à ' : ' at ') + date.hours() + ':' + date.minutes().toString().padStart(2, '0');
        return str;
    }

    static fileNameExtension(filename) {
        return filename.split('.').pop();
    }

    static fileNameFromURL(url) {
        return url.substring(url.lastIndexOf('/') + 1);
    }

    static getLeasingCompaniesText(locationData, t) {
        // console.log(locationData);
        if ('leasing_companies' in locationData && locationData.leasing_companies.length > 0) {
            let text = t('location.leasing_companies_label');
            let companies = [];
            locationData.leasing_companies.forEach(company => {
                companies.push(company.label);
            });
            text += companies.join(', ');
            return text;
        } else {
            return null;
        }
    }

    static locationIsSame(location1, location2) {
        if (location1 == null || location2 == null) {
            return false;
        }
        return location1.id == location2.id;
    }

    static getSqlDateWithDayDiff(sqlDate, diff) {
        let result = moment(sqlDate).add(diff, 'day');
        return result.format('YYYY-MM-DD');
    }

    static formatStripeAmount(stripeAmount, withCurrency = false) {
        let value = Math.round(stripeAmount / 100).toFixed(2);
        if (withCurrency) {
            return FormatUtils.formatPrice(value);
        } else {
            return value;
        }
    }

    static formatAmount(valetAmount, withCurrency = false) {
        // console.log(valetAmount , '=>', Math.round(valetAmount / 100).toFixed(2));
        let value = (valetAmount / 100).toFixed(2);
        if (withCurrency) {
            return FormatUtils.formatPrice(value);
        } else {
            return value;
        }
    }

    static convertAmountCentToDecimal(amount) {
        let value = (amount / 100);
        return value;
    }

    // Meters to km
    static formatDistance(meters) {
        return (meters / 1000).toFixed(2) + ' kms';
    }

    // CHF 10.- , CHF 10.55 , CHF 10.30
    static formatPrice(chf) {
        let value = parseFloat(chf);
        if (value % 1 == 0) {
            return 'CHF ' + value + '.-';
        } else {
            return 'CHF ' + value.toFixed(2);
        }
    }
}
