import {Box, Container, Grid, InputAdornment, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";
import FormatUtils from "../utils/FormatUtils";

export default function LocationTireItem({priceData, priceChangedHandler, currency}) {
    const {t} = useTranslation('common');

    const [price, setPrice] = useState(priceData.price);


    useEffect(() => {
        if (priceData != null) {
            setPrice(priceData.price);
        }
    }, [priceData]);

    useEffect(() => {
        priceData.price = price;
    }, [price]);

    return (
        <Container sx={{border: 1, p: 1}}>
            <Grid container justifyContent="center">
                <Typography>{priceData.category_name}</Typography>
                <TextField
                    type="number"
                    margin="normal"
                    size="small"
                    fullWidth
                    id="label"
                    label={t('location.services.price')}
                    onChange={(e) => {
                        let newPrice = parseFloat(e.target.value);
                        if (newPrice < 0) {
                            newPrice = 0;
                        }
                        if (isNaN(newPrice)) {
                            newPrice = null;    // So it is compatible with isNotSet
                        }
                        setPrice(newPrice);
                        priceChangedHandler(e);
                        priceData.price = newPrice;
                    }}
                    value={price || ''}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{currency}</InputAdornment>
                    }}
                    error={FormatUtils.isNotSet(price)}
                />

            </Grid>
        </Container>
    )
}