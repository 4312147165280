import React, {useEffect, useState} from "react";
import {useDispatch} from 'react-redux'
import {exitBranded, loadFromSessionStorage} from "./locationGroupSlice";
import RedirectToEntryPoint from "../../components/RedirectToEntryPoint";

export default function BrandedExit({}) {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(exitBranded());
    }, []);

    return <RedirectToEntryPoint/>
}