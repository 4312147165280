import {Box, Container, FormControlLabel, FormGroup, Grid, InputLabel, Stack, Switch, TextField} from "@mui/material";
import React, {useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";
import FormatUtils from "../utils/FormatUtils";

export default function LocationScheduleRow({
                                                scheduleData,
                                                type,
                                                shortVersion = false,
                                                dataChangedHandler = null
                                            }) {
    const {t} = useTranslation('common');

    const [disabled, setDisabled] = React.useState(type == 'am' ? (scheduleData.am_closed == 1) : (scheduleData.pm_closed == 1));

    useEffect(() => {
        // console.log("scheduleData", scheduleData);
        setDisabled(type == 'am' ? (scheduleData.am_closed == 1) : (scheduleData.pm_closed == 1));
    }, [scheduleData]);

    function userChangedDisable(e) {
        if (type == 'am') {
            scheduleData.am_closed = e.target.checked ? 1 : 0;
        } else {
            scheduleData.pm_closed = e.target.checked ? 1 : 0;
        }
        if (dataChangedHandler != null) {
            dataChangedHandler();
        }
    }

    const disableNode = <FormGroup><FormControlLabel control={<Switch
        checked={disabled}
        onChange={function (e) {
            userChangedDisable(e);
        }}/>} label={t('location.schedules.closed')}/></FormGroup>

    let capacity = (type == 'am' ? scheduleData.am_capacity : scheduleData.pm_capacity) || '';

    if (shortVersion) {
        let isException = FormatUtils.calendarDayDataIsException(scheduleData);
        return (
            <>
                <Grid container justifyContent="center">
                    <Stack spacing={1.5} mt={1} sx={{
                        width: '90%'
                    }}>
                        <Grid container pl={1} pr={1} justifyContent="space-between"
                              style={{'opacity': disabled ? 0.5 : 1}}>
                            <Grid item>
                                {
                                    (type == 'am' ? t('location.schedules.morning') : t('location.schedules.evening'))
                                }
                            </Grid>
                            <Grid item>
                                <Switch
                                    size="small"
                                    checked={disabled}
                                    onChange={function (e) {
                                        userChangedDisable(e);
                                    }}/>
                                {t('location.schedules.closed')}
                            </Grid>
                        </Grid>
                        <TextField
                            label={t('location.schedules.from')}
                            type="time"
                            disabled={disabled}
                            size="small"
                            sx={{
                                width: '150px',
                                alignSelf: 'center'
                            }}
                            value={type == 'am' ? scheduleData.am_start : scheduleData.pm_start}
                            onChange={(e) => {
                                type == 'am' ? (scheduleData.am_start = e.target.value) : (scheduleData.pm_start = e.target.value);
                                if (dataChangedHandler != null) {
                                    dataChangedHandler();
                                }
                            }}
                        />

                        <TextField
                            label={t('location.schedules.to')}
                            type="time"
                            disabled={disabled}
                            size="small"
                            sx={{
                                width: '150px',
                                alignSelf: 'center'
                            }}
                            value={type == 'am' ? scheduleData.am_end : scheduleData.pm_end}
                            onChange={(e) => {
                                type == 'am' ? (scheduleData.am_end = e.target.value) : (scheduleData.pm_end = e.target.value);
                                if (dataChangedHandler != null) {
                                    dataChangedHandler();
                                }
                            }}
                        />

                        <TextField
                            disabled={disabled}
                            label={t('location.schedules.capacity_short')}
                            type="number"
                            size="small"
                            sx={{
                                width: '150px',
                                alignSelf: 'center'
                            }}
                            value={capacity}
                            onChange={(e) => {
                                type == 'am' ? (scheduleData.am_capacity = e.target.value) : (scheduleData.pm_capacity = e.target.value);
                                if (dataChangedHandler != null) {
                                    dataChangedHandler();
                                }
                            }}
                            error={!disabled && isException && FormatUtils.isNotSet(capacity)}
                        />
                    </Stack>
                </Grid>
            </>)
    }

    return (
        <>
            <Grid container>
                <Grid container item xs={2} alignContent="center" style={{
                    paddingLeft: '15px',
                }}>
                    <span style={{'opacity': disabled ? 0.5 : 1}}>
                        {
                            type == 'am' ? t('location.schedules.morning') : t('location.schedules.evening')
                        }
                    </span>
                </Grid>
                <Grid container item xs={8} alignContent="center" justifyContent="center">
                    <FormGroup>
                        <FormControlLabel disabled={disabled} labelPlacement="start" control={<TextField
                            type="time"
                            style={{
                                width: 150,
                                paddingLeft: '20px',
                            }}
                            size="small"
                            inputProps={{style: {textAlign: 'center'}}}
                            value={type == 'am' ? scheduleData.am_start : scheduleData.pm_start}
                            onChange={(e) => {
                                type == 'am' ? (scheduleData.am_start = e.target.value) : (scheduleData.pm_start = e.target.value);
                                if (dataChangedHandler != null) {
                                    dataChangedHandler();
                                }
                            }}
                        />} label={t('location.schedules.from')}/>
                    </FormGroup>
                    <FormGroup style={{paddingLeft: '20px'}}>
                        <FormControlLabel disabled={disabled} labelPlacement="start" control={<TextField
                            type="time"
                            style={{
                                width: 150,
                                paddingLeft: '20px',
                            }}
                            size="small"
                            inputProps={{style: {textAlign: 'center'}}}
                            value={type == 'am' ? scheduleData.am_end : scheduleData.pm_end}
                            onChange={(e) => {
                                type == 'am' ? (scheduleData.am_end = e.target.value) : (scheduleData.pm_end = e.target.value);
                                if (dataChangedHandler != null) {
                                    dataChangedHandler();
                                }
                            }}
                        />} label={t('location.schedules.to')}/>
                    </FormGroup>
                </Grid>
                <Grid container item xs={2} alignContent="center" justifyContent="center">
                    {disableNode}
                </Grid>
            </Grid>
            <Grid container style={{marginTop: 10}}>
                <FormGroup>
                    <FormControlLabel disabled={disabled} labelPlacement="start" control={<TextField
                        type="number"
                        style={{
                            width: 100,
                            paddingLeft: '20px',
                        }}
                        size="small"
                        value={capacity}
                        onChange={(e) => {
                            type == 'am' ? (scheduleData.am_capacity = e.target.value) : (scheduleData.pm_capacity = e.target.value);
                            if (dataChangedHandler != null) {
                                dataChangedHandler();
                            }
                        }}
                        error={!disabled && FormatUtils.isNotSet(capacity)}
                    />} label={t('location.schedules.capacity')}/>
                </FormGroup>
            </Grid>
        </>)
}