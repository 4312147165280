import {Box, Checkbox, Grid, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import TireServiceStatic from "../services/TireServiceStatic";
import React from "react";

export default function TireListSelector({selectedValues, selectionChangedHandler}) {
    const {t} = useTranslation('common');

    const tireCategories = TireServiceStatic.tireCategories;

    function selectedHandler(id, value) {
        let values = selectedValues.slice();
        if (value) {
            if (values.indexOf(id) == -1) {
                values.push(id);
            }
        } else {
            if (values.indexOf(id) != -1) {
                values.splice(values.indexOf(id), 1);
            }
        }
        selectionChangedHandler(values);
    }

    function getChecked(tireCategory) {
        let checked = selectedValues.indexOf(tireCategory.id) != -1
        return checked;
    }

    return (
        <Box>
            <Typography>{t('location.services.tire.tire_selection_title')}</Typography>
            <Grid container justifyContent="left">
                {tireCategories.map(tireCategory => {
                    return <Box key={tireCategory.id} alignContent="center" style={{
                        // backgroundColor:'red'
                    }}>
                        <Grid container justifyContent="center" alignItems="center" style={{
                            // backgroundColor:'blue'
                        }}>
                            <Checkbox
                                sx={{
                                    '& .MuiSvgIcon-root': {
                                        fontSize: 40,
                                    },
                                }}
                                checked={getChecked(tireCategory)}
                                onChange={e => selectedHandler(tireCategory.id, e.target.checked)}/>
                            <Typography style={{
                                // backgroundColor:''
                            }}>{tireCategory.label}</Typography>
                        </Grid>
                    </Box>
                })}
            </Grid>
        </Box>
    )
}