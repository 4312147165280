import LockIcon from '@mui/icons-material/Lock';
import {Link, Tooltip, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";

export default function LocationLabel({
                                          locationData,
                                          locationGroupData = null
                                      }) {
    const {t, i18n} = useTranslation('common');

    // useEffect(() => {
    //     console.log('LGD:', locationGroupData);
    // }, [locationGroupData])

    return <span style={{
        color: '#fbc70f'
    }}>
        {
            locationData.is_private == 1
            &&
            <Tooltip title={t('vehicle.item.service_history.private_location_label')}>
                <LockIcon
                    fontSize='small'
                    style={{
                        verticalAlign: '-4px'
                    }}
                />
            </Tooltip>
        }
        {locationData.name}
        {
            locationGroupData != null &&
            <span>
                <span style={{
                    color: 'white'
                }}>
                {" " + t('vehicle.item.service_history.via_location_group_label') + " "}
                </span>
                <Link
                    underline="none"
                    href={locationGroupData.KucUrl}
                    style={{
                        color: '#fbc70f',
                        fontWeight: 'bold'
                    }}>

                {locationGroupData.label}
            </Link>
                </span>
        }
    </span>
}