import React, {useEffect, useState} from 'react';
import Swal from "sweetalert2";
import {Link, useNavigate, useOutletContext} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {
    Box,
    Button,
    Container,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select, Tabs,
    TextField
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ProviderOrderList from "./ProviderOrderList";
import ServiceCardModal from "./ServiceCardModal";
import ProviderOrderBookTabLabel from "./ProviderOrderBookTabLabel";
import moment from "moment";
import FormatUtils from "../utils/FormatUtils";
import useFetch from "../hooks/useFetch";
import {OrderStatus} from "../constants/OrderStatus";

export default function ProviderOrderBook({locationId = null, tabIndexDeeplink = null}) {
    const {t} = useTranslation('common');
    const [apiUri] = useOutletContext();

    const [loaded, setLoaded] = useState(false);
    const [locationList, setLocationList] = useState(null);
    const [location, setLocation] = useState(null);

    const [tabIndex, setTabIndex] = useState(0);

    const [lastDoneOrder, setLastDoneOrder] = useState(null);

    const [searchText, setSearchText] = useState("");
    const [validatedSearchText, setValidatedSearchText] = useState(null);

    const [cardOrder, setCardOrder] = useState(null);

    const [tabCount, setTabCount] = useState([null, null, null, null, null]);
    const {call} = useFetch();

    const navigate = useNavigate();

    useEffect(() => {
        getLocationList();
    }, [])

    useEffect(() => {
        if (loaded) {
            getOrderCounts();
        }
    }, [location, lastDoneOrder]);

    useEffect(() => {
        if (locationId != null && locationList != null) {
            // On tente d'afficher le bon emplacement
            let found = locationList.find(location => location.id == locationId);
            if (found) {
                setLocation(found);
                if (tabIndexDeeplink != null) {
                    let tabIndexInt = parseInt(tabIndexDeeplink);
                    if (isNaN(tabIndexInt) || tabIndexInt > 4) {
                        console.log('tabIndex introuvable : ' + tabIndexInt);
                    } else {
                        setTabIndex(tabIndexInt);
                    }
                }
            } else {
                console.log('LocationId introuvable : ' + locationId);
            }
        }
    }, [locationList, locationId, tabIndexDeeplink]);

    function tabChangedHandler(e, newValue) {
        // console.log(e, newValue);
        navigate('/dashboard/' + locationId.toString() + '/' + newValue.toString());
        // setTabIndex(newValue);
    }

    function orderStatusChangedHandler(order) {
        // console.log("orderStatusChangedHandler", order);
        // To refresh the list
        setLastDoneOrder(order);

        // To refresh the modal ( kind of hack )
        setCardOrder(FormatUtils.cloneData(cardOrder));
    }

    function searchClickedHandler() {
        setValidatedSearchText(searchText);
    }

    function showOrderCardHandler(order) {
        setCardOrder(order);
    }

    function closeOrderCardHandler() {
        setCardOrder(null);
    }

    function orderChangedHandler(changedOrder) {
        // To reload orders
        setLastDoneOrder(changedOrder);
    }

    // TODO Use the generic way with changeServiceStateClickedHandler ?
    function serviceDoneClickedHandler(order) {
        // console.log("serviceDoneClickedHandler")
        Swal.fire({
            html: t('provider_order_book.confirmation_done'),
            showCancelButton: true,
            confirmButtonText: t('common.yes'),
            cancelButtonText: t('common.no'),
            target: document.getElementById('swal_container'),
        }).then((result) => {
            if (result.isConfirmed) {
                postNewStatus(order, OrderStatus.DONE)
            }
        })
    }

    function changeServiceStateClickedHandler(order, newStatus) {
        // console.log(order, newStatus);
        let prompt;
        switch (newStatus) {
            case OrderStatus.QUOTATION_SENT:
                prompt = t('service_card.quotation_sent_confirmation');
                break;
            case OrderStatus.QUOTATION_REFUSED:
                prompt = t('service_card.quotation_refused_confirmation');
                break;
            case OrderStatus.ACCEPTED:
                prompt = t('service_card.quotation_accepted_confirmation');
                break;
        }

        if (prompt != null) {
            Swal.fire({
                html: prompt,
                showCancelButton: true,
                confirmButtonText: t('common.yes'),
                cancelButtonText: t('common.no'),
                target: document.getElementById('swal_container'),
            }).then((result) => {
                if (result.isConfirmed) {
                    postNewStatus(order, newStatus)
                }
            })
        }
    }

    // TODO Same as LocationIndex.js
    async function getLocationList() {
        call(apiUri + 'location_index', (resultData => {
            setLoaded(true);
            setLocationList(resultData.locations);
        }));
    }


    async function postNewStatus(order, newStatus) {
        // console.log("postNewStatus");
        let data = {
            order_id: order.id,
            new_status: newStatus
        }

        call(apiUri + 'provider_order_change_status', (resultData) => {
            orderStatusChangedHandler(resultData.order);
        }, 'POST', data, true);
    }

    function getOrderCounts() {
        for (let t = 0; t <= 4; t++) {
            getOrderListCount(t);
        }
    }

    // TODO REdondant avec ProviderOrderList.js
    async function getOrderListCount(tabIndex) {
        let data = {
            location_id: location.id,
            count_only: 1
        }

        data.order_status_id = '1';

        switch (tabIndex) {
            case 0 :    // Today
                const today = moment().format('YYYY-MM-DD');
                data.date_start = today;
                data.date_end = today;
                break;
            case 1 :    // Tomorrow
                const tomorrow = moment().add(1, 'days').format('YYYY-MM-DD');
                data.date_start = tomorrow;
                data.date_end = tomorrow;
                break;
            case 2: // This week
                data.date_start = moment().format('YYYY-MM-DD');
                data.date_end = moment().add(6, 'days').format('YYYY-MM-DD');
                break;
            case 3: // All
                break;
            case 4: // Archives
                data.is_archive = 1;
                break;
        }

        call(apiUri + 'provider_order_list', (resultData) => {
            tabCount[tabIndex] = resultData.count;
            setTabCount(FormatUtils.cloneData(tabCount));
        }, 'POST', data, true);
    }

    return (
        <>
            <Container
                disableGutters={true}
                sx={{
                    // backgroundColor: 'green'
                }}
            >
                {loaded &&
                <>
                    <Grid
                        p={1}
                        style={{
                            backgroundColor: 'dimgrey',
                            width: '100%'
                        }}
                        container
                        justifyContent="space-between"
                    >

                        <Grid
                            item
                            xs={6}
                            container
                            alignItems="center"
                        >
                            <FormControl
                                size="small"
                                style={{
                                    minWidth: 300
                                }}>
                                <InputLabel
                                    // shrink={false}
                                >
                                    {t('provider_order_book.choose_location_label')}
                                </InputLabel>
                                <Select
                                    style={{
                                        minWidth: 300
                                    }}
                                    id="vehicle"
                                    label={t('service.step1.vehicle_label')}
                                    placeholder={t('provider_order_book.choose_location_label')}
                                    value={location ? location.id : ''}
                                    onChange={
                                        e => {
                                            let locationId = e.target.value;
                                            navigate('/dashboard/' + locationId.toString() + '/' + tabIndex.toString());
                                            // let pLocation = locationList.find((elem => elem.locationId == locationId));
                                            // setLocation(pLocation);
                                        }
                                    }
                                >
                                    {
                                        locationList.map((pLocation, i) => {
                                            return (<MenuItem key={i}
                                                              value={pLocation.id}>{pLocation.name}</MenuItem>);
                                        })
                                    }
                                </Select>
                            </FormControl>
                            <Link to="/location/add">
                                <Button sx={{height: '100%'}}>
                                    <AddCircleIcon fontSize="large"/>
                                </Button>
                            </Link>
                        </Grid>
                        <Grid item xs={6}
                              container
                              justifyContent="end"
                              alignContent="center"
                              style={{
                                  // backgroundColor: 'red'
                              }}>
                            <TextField
                                value={searchText}
                                onChange={(e) => setSearchText(e.currentTarget.value)}
                                size="small"
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        searchClickedHandler();
                                    }
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <SearchIcon
                                            style={{
                                                cursor: 'pointer'
                                            }}
                                            onClick={searchClickedHandler}
                                        />
                                    </InputAdornment>
                                }}
                            />
                        </Grid>
                    </Grid>

                    {location &&
                    <>
                        <Tabs
                            value={tabIndex}
                            onChange={tabChangedHandler}
                            variant="scrollable"
                            scrollButtons={false}
                        >
                            <ProviderOrderBookTabLabel
                                label={t('provider_order_book.tab_today_label')}
                                badgeValue={tabCount[0]}
                            />
                            <ProviderOrderBookTabLabel
                                label={t('provider_order_book.tab_tomorrow_label')}
                                badgeValue={tabCount[1]}
                            />
                            <ProviderOrderBookTabLabel
                                label={t('provider_order_book.tab_week_label')}
                                badgeValue={tabCount[2]}
                            />
                            <ProviderOrderBookTabLabel
                                label={t('provider_order_book.tab_all_label')}
                                badgeValue={tabCount[3]}
                            />
                            <ProviderOrderBookTabLabel
                                label={t('provider_order_book.tab_archives_label')}
                                badgeValue={tabCount[4]}
                            />
                        </Tabs>
                        <Box
                            sx={{
                                marginTop: "10px"
                            }}
                        >
                            <ProviderOrderList location={location} tabIndex={tabIndex} orderStatusId="1"
                                               lastDoneOrder={lastDoneOrder}
                                               orderDoneHandler={orderStatusChangedHandler}
                                               searchText={validatedSearchText}
                                               showOrderCardHandler={showOrderCardHandler}
                                               serviceDoneClickedHandler={serviceDoneClickedHandler}
                                               countChangedHandler={(count) => {
                                                   // console.log('countChangedHandler', count);
                                                   tabCount[tabIndex] = count;
                                                   setTabCount(FormatUtils.cloneData(tabCount))
                                                   // getOrderCounts();
                                               }}
                            />
                        </Box>
                    </>
                    }
                </>
                }
            </Container>
            {
                cardOrder && <ServiceCardModal
                    order={cardOrder}
                    closeHandler={closeOrderCardHandler}
                    cardType="provider"
                    orderChangedHandler={orderChangedHandler}
                    serviceDoneClickedHandler={serviceDoneClickedHandler}
                    changeServiceStateClickedHandler={changeServiceStateClickedHandler}
                />
            }
        </>
    )
}