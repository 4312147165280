import React, {useEffect, useState} from 'react';
import {
    Box, Button, Grid
} from "@mui/material";
import {Link, Outlet, useNavigate, useOutletContext} from "react-router-dom";
import {useTranslation} from "react-i18next";
import LocationItem from "./LocationItem";
import useFetch from "../hooks/useFetch";

export default function LocationIndex(props) {
    const {t} = useTranslation('common');
    const [apiUri] = useOutletContext();
    const [loaded, setLoaded] = useState(false);
    const [locationList, setLocationList] = useState([]);
    const navigate = useNavigate();
    const {call} = useFetch();

    useEffect(() => {
        props.setLabel('location.title');
        locationIndexGet();
    }, [])

    async function locationIndexGet() {
        call(apiUri + 'location_index', (data) => {
            setLoaded(true);
            setLocationList(data.locations);
        });
    }

    function locationUpdatedHandler() {
        navigate("/location");
        locationIndexGet();
    }

    return (
        <>
            {/*<Typography variant="h4">*/}
            {/*    {t("location.title")}*/}
            {/*</Typography>*/}
            <Grid justifyContent="center" container>
                <Link to="/location/add" style={{textDecoration: 'none'}}>
                    <Button
                        variant="contained"
                        color="yellow"
                        sx={{mt: '15px', mb: '20px'}}
                    >
                        {t('location.button_create_location')}
                    </Button>
                </Link>
            </Grid>
            <Box width="100%" sx={{mt: 1}}/>
            {loaded &&
            <>
                {locationList.length == 0 ?
                    <>
                        <h3>
                            {t("location.empty_list_message")}
                        </h3>
                    </>
                    :
                    <>
                        {
                            locationList.map((location, i) => {
                                return (<LocationItem key={i} locationData={location}/>);
                            })
                        }
                    </>
                }

                <Grid justifyContent="center" container>
                    <Link to="/location/add" style={{textDecoration: 'none'}}>
                        <Button
                            variant="contained"
                            color="yellow"
                            sx={{mt: '15px', mb: '20px'}}
                        >
                            {t('location.button_create_location')}
                        </Button>
                    </Link>
                </Grid>

                <Outlet context={[apiUri, locationUpdatedHandler]}/>
            </>
            }
        </>
    );
}