import {
    Grid,
    Stack, TextField,
    Typography
} from "@mui/material";
import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import InfoIcon from "../assets/buttons/info/info-white-black.png"
import SaveIcon from "../assets/buttons/save/save-white-yellow.png"
import SaveIcon2 from "../assets/buttons/save/save-white-black.png"
import ImageButton from "./ImageButton";
import Swal from "sweetalert2";
import {useOutletContext} from "react-router-dom";
import VehicleItemSection from "./VehicleItemSection";
import VehicleItemSubSectionTire from "./VehicleItemSubSectionTire";
import useFetch from "../hooks/useFetch";
import MobileContext from "../contexts/mobileContext";

export default function VehicleItemSectionInfo({
                                                   vehicleData,
                                                   tireCategories,
                                                   selected = true,
                                                   reloadHandler
                                               }) {
    const [apiUri] = useOutletContext();
    const {t} = useTranslation('common');
    const formRef = React.useRef();

    const {callPost} = useFetch();
    const mobileContext = useContext(MobileContext);
    const isMobile = mobileContext.isMobile;

    function saveClickedHandler(e) {
        let data = {
            id: vehicleData.id,
            notes: formRef.current.elements['notes'].value
        }

        callPost(apiUri + 'vehicle_notes', data, (resultData => {
            Swal.fire({
                text: t('vehicle.form.success'),
                icon: 'success',
                timer: 2000,
                target: document.getElementById('swal_container'),
            })
        }));
    }

    return (
        <VehicleItemSection
            icon={InfoIcon}
            label={t('vehicle.item.info.label')}
            detailContent={<>
                <Grid item xs={12} sm={4}>
                    <Stack>
                        <Typography>{t('vehicle.item.info.vin_label')} {vehicleData.vin}</Typography>
                        <Typography>{t('vehicle.item.info.first_traffic_date_label')} {vehicleData.first_date_traffic != null ? vehicleData.first_date_traffic : t('vehicle.item.info.not_specified')}</Typography>
                        <Typography>{t('vehicle.item.info.motor_label')} {vehicleData.motor != null ? vehicleData.motor : t('vehicle.item.info.not_specified')}</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={8} mt={isMobile ? 1 : ''} mb={isMobile ? 1 : ''}>
                    <form ref={formRef}>
                        <TextField
                            id="notes"
                            inputProps={{
                                style: {color: "black"}
                            }}
                            InputProps={{
                                endAdornment:
                                    <ImageButton src={SaveIcon2}
                                                 srcOver={SaveIcon}
                                                 height="30px"
                                                 onClickHandler={saveClickedHandler}/>
                            }}
                            multiline
                            fullWidth
                            minRows={2}
                            label={t('vehicle.item.info.note_label')}
                            defaultValue={vehicleData.notes}
                        />
                    </form>
                </Grid>
                <VehicleItemSubSectionTire
                    vehicleData={vehicleData}
                    tireCategories={tireCategories}
                    reloadHandler={reloadHandler}
                />
            </>}
        />
    )
}