import {QRCodeSVG} from 'qrcode.react';
import {QRCodeCanvas} from 'qrcode.react';
import {Button, Grid} from "@mui/material";
import React, {forwardRef} from "react";
import {useTranslation} from "react-i18next";

function QrcodePopin({
                         url
                     }, ref) {
    const {t} = useTranslation('common');

    // https://imran-ahmad.medium.com/how-to-generate-and-download-a-qr-code-image-in-react-a3e924a672f5
    function downloadHandler() {
        const canvas = document.getElementById('qrcode');
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = "qrcode.png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    return <Grid container
                 justifyContent="center"
                 alignContent="center"
                 alignItems="center"
                 flexDirection="column"
                 style={{
                     backgroundColor: 'black',
                     width: '400px',
                     height: '400px'
                 }}
    >
        <Grid item>
            <QRCodeCanvas
                id="qrcode"
                value={url}
                size={256}
            />
        </Grid>
        <Grid item mt={2}>
            <Button
                color="yellow"
                variant="contained"
                onClick={downloadHandler}
            >
                {t('showcase.download_qrcode')}
            </Button>
        </Grid>
    </Grid>
};

export default forwardRef(QrcodePopin);