import {Container, Typography} from "@mui/material";
import React, {useEffect} from "react";
import {Navigate, useLocation, useNavigate, useParams} from "react-router-dom";
import {useSelector, useDispatch} from 'react-redux'
import {exitBranded, setLocationGroup, setSlug, truc} from "./locationGroupSlice";

import {useGetLocationGroupBySlugQuery} from "./LocationGroupApi";
import {AccountType} from "../../constants/AccountType";
import BrandedExit from "./BrandedExit";
import BrandedDisabledContent from "./BrandedDisabledContent";
import useLocalStorageState from "use-local-storage-state";

export default function LocationGroupRedirect({}) {
    let params = useParams();
    // const count = useSelector(state => state.counter.value);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [accessToken, setAccessToken] = useLocalStorageState('accessToken');


    // http://localhost:3000/lg/lolderire/email_validation?email=v.e.n.drouxf@gmail.com&key=c85c4459e71f12e00292e1af5b719b52

    // TODO Intégrer ca à locationGroupSlice ? ( c'est du model.. )
    const {data, error, isLoading} = useGetLocationGroupBySlugQuery(params.lgSlug);
    const accountType = localStorage.getItem('accountType');

    function isPreview() {
        return accountType == AccountType.PROVIDER;
    }

    function canShow() {
        return isPreview() || data?.active == 1;
    }

    // useEffect(() => {
    //     console.log('LG!', params.lgSlug);
    //     console.log('location=', location, location.pathname);
    // }, []);


    useEffect(() => {
        // console.log('DATA!', data);
        if (data != undefined) {
            if (data.state == 'OK') {
                // Pas payé l'abo etc..
                if (!canShow()) {
                    dispatch(exitBranded());
                    return;
                }
                dispatch(setLocationGroup(data.location_group));
                if (isEmailValidation()) {
                    navigate('/email_validation' + location.search);
                } else {
                    // Non loggé
                    if (accessToken == null) {
                        navigate('/login');
                    } else {
                        navigate('/service_order_b');
                    }
                }
            } else {
                // navigate('/');
            }
        }
    }, [data]);

    function isEmailValidation() {
        let path = location.pathname;
        let pathArr = path.split('/');
        let last = pathArr[pathArr.length - 1];
        return last == 'email_validation';
    }

    return (data != undefined) && <Container maxWidth={false}>
        {!canShow() &&
            <BrandedDisabledContent/>
            // <BrandedExit/>
        }
    </Container>
}