import {
    Box,
    Button,
    Grid, Input, MenuItem, Modal, Select,
    Stack,
    Typography
} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import DocumentIcon from "../assets/buttons/document/document-white-black.png"
import Swal from "sweetalert2";
import {useOutletContext} from "react-router-dom";
import VehicleItemSection from "./VehicleItemSection";
import VehicleDocumentRow from "./VehicleDocumentRow";
import useFetch from "../hooks/useFetch";

export default function VehicleItemSectionDocuments({
                                                        vehicleData,
                                                        selected = true,
                                                        reloadHandler,
                                                        vehicleDocumentTypes
                                                    }) {
    const [apiUri] = useOutletContext();
    const {t} = useTranslation('common');
    const formRef = React.useRef();

    const maxFileSize = 5;  // Mb

    const [popupOpen, setPopupOpen] = React.useState(false);
    const addDocumentClickedHandler = () => setPopupOpen(true);
    const closeAddDocumentHandler = () => setPopupOpen(false);

    const {callPostForm, isSending} = useFetch();

    function submitHandler(event) {
        event.preventDefault();
        if (!formRef.current.reportValidity()) {
            return;
        }

        let data = new FormData();

        let documentTypeId = formRef.current.elements['document_type'].value;
        let documentType = getVehicleDocumentTypeById(documentTypeId);

        data.append('vehicle_id', vehicleData.id);
        data.append('vehicle_document_type_id', documentTypeId);
        data.append('label', t(documentType.label));
        data.append('file', formRef.current.elements['file'].files[0]);

        callPostForm(apiUri + 'vehicle_document_insert', data, (resultData) => {
            Swal.fire({
                text: t('vehicle.item.documents.add.success'),
                icon: 'success',
                timer: 2000,
                target: document.getElementById('swal_container'),
            })
                .then(() => {
                    // TODO Pas ouf de recharger tous les vehicules, mais ca fonctionne
                    reloadHandler();
                    closeAddDocumentHandler();
                });
        })
    }

    function getVehicleDocumentTypeById(id) {
        return vehicleDocumentTypes.find(documentType => documentType.id == id);
    }

    function fileChangedHandler(e) {
        let fileInput = document.getElementById("file");
        var ext = fileInput.value.match(/\.([^\.]+)$/)[1];
        switch (ext) {
            case 'jpg':
            case 'bmp':
            case 'png':
            case 'tif':
            case 'pdf':
                break;
            default:
                Swal.fire({
                    text: t('vehicle.item.documents.add.error_format'),
                    icon: 'error',
                    target: document.getElementById('swal_container'),
                });
                fileInput.value = '';
                return;
        }
        if (fileInput.files[0].size > maxFileSize * 1024 * 1024) {
            Swal.fire({
                text: t('vehicle.item.documents.add.error_size'),
                icon: 'error',
                target: document.getElementById('swal_container'),
            });
            fileInput.value = '';
            return
        }
    }

    return (
        <VehicleItemSection
            icon={DocumentIcon}
            label={t('vehicle.item.documents.label')}
            detailContent={<>
                <Stack>
                    {
                        vehicleData.documents.length == 0 &&
                        <Typography sx={{fontStyle: 'italic'}}>{t('vehicle.item.documents.no_document')}</Typography>
                    }
                    {
                        vehicleData.documents.map((document, i) => {
                            return (<VehicleDocumentRow
                                vehicleData={vehicleData} key={i} document={document} reloadHandler={reloadHandler}
                            />)
                        })}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="yellow"
                        sx={{mt: '15px', mb: '20px'}}
                        onClick={addDocumentClickedHandler}
                    >
                        {t('vehicle.item.documents.add.label')}
                    </Button>

                    <Modal
                        open={popupOpen}
                        onClose={closeAddDocumentHandler}
                    >
                        <Box sx={style}>
                            <Grid container>
                                <Grid item xs={0}>
                                    <img src={DocumentIcon} style={{width: '50px'}}></img>
                                </Grid>
                                <Grid item xs={10} container alignContent="center">
                                    <Typography fontSize="18px"
                                                marginLeft="10px"><b>{t('vehicle.item.documents.add.label')}</b></Typography>
                                </Grid>
                            </Grid>
                            <form onSubmit={submitHandler} ref={formRef}>
                                <Stack>
                                    <Select
                                        id="document_type"
                                        name="document_type"
                                        defaultValue={1}
                                        required
                                        onChange={
                                            e => {
                                                //setCountry(e.target.value);
                                            }
                                        }
                                    >
                                        <MenuItem>{window['test']}</MenuItem>
                                        {vehicleDocumentTypes != null &&

                                        vehicleDocumentTypes.map((documentType, i) => {
                                            return (
                                                <MenuItem key={i}
                                                          value={documentType.id}>{t(documentType.label)}</MenuItem>)
                                        })
                                        }
                                    </Select>
                                    <Input
                                        sx={{
                                            height: '50px'
                                        }}
                                        inputProps={{accept: 'image/*,application/pdf'}}
                                        required
                                        id="file"
                                        type="file"
                                        onChange={fileChangedHandler}
                                    />
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="yellow"
                                        sx={{mt: '15px', mb: '20px'}}
                                        disabled={isSending}
                                    >
                                        {isSending ? t('common.sending') : t('vehicle.item.documents.add.save')}
                                    </Button>
                                </Stack>
                            </form>
                        </Box>
                    </Modal>
                </Stack>
            </>}
        />
    )
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};