import React, {useContext, useState} from "react";
import {
    Button,
    FormControl, Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TableCell,
    TableRow,
    TextField
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import {useTranslation} from "react-i18next";
import MobileContext from "../contexts/mobileContext";

export default function NewTireWheelComponent({
                                                  widths,
                                                  heights,
                                                  tireCategories,
                                                  buyVersion,
                                                  vehicleData,
                                                  formRef,
                                                  showInfoHandler,
                                                  submitHandler,
                                                  cancelHandler,
                                              }) {
    const [width, setWidth] = useState('');
    const [height, setHeight] = useState('');
    const [tireCategory, setTireCategory] = useState('');
    const [isWheel, setIsWheel] = useState('');
    const [countPosition, setCountPosition] = useState('4');
    const [description, setDescription] = useState('');

    // const formRef = React.useRef();

    const {t} = useTranslation('common');
    const mobileContext = useContext(MobileContext);
    const isMobile = mobileContext.isMobile;

    function getTireCountAndPositionFromValue(value) {
        switch (value) {
            case '4':
                return {'count': 4, 'position': null};
            case '2F':
                return {'count': 2, 'position': 'F'};
            case '2B':
                return {'count': 2, 'position': 'B'};
            default:
                return null;
        }
    }

    function addTireClickedHandler() {
        if (!formRef.current.reportValidity()) {
            return;
        }

        let data = new FormData();
        let countPositionObj = getTireCountAndPositionFromValue(countPosition);

        data.append('vehicle_id', vehicleData.id);
        data.append('tire_category_id', tireCategory);
        data.append('is_wheel', isWheel);
        data.append('width', width);
        data.append('height', height);
        data.append('description', description);
        data.append('count', countPositionObj.count);
        if (countPositionObj.position != null) {
            data.append('position', countPositionObj.position);
        }

        setTireCategory('');
        setWidth('');
        setHeight('');
        setDescription('');
        setIsWheel('');

        submitHandler(data);
    }

    return <>
        <TableRow
            sx={{
                'td, th': {
                    borderTop: 1,
                    borderTopColor: 'inherit',
                    borderBottom: 0,
                }
            }}
        >
            <TableCell align={"center"}>
                <Grid display="flex" alignItems="center" textAlign="center" justifyContent="center"
                      style={{
                          width: "100%"
                      }}
                >
                    <InfoIcon
                        cursor="pointer"
                        onClick={showInfoHandler}
                        sx={
                            {
                                // marginLeft: '5px',
                                // marginTop: '-1px',
                                color: 'orange',
                                fontSize: 28,
                            }}/>
                </Grid>
            </TableCell>
            <TableCell style={{
                paddingTop: isMobile ? '10px' : ''
            }}
                       colSpan={isMobile ? '2' : ''}
            >
                <FormControl fullWidth variant="outlined">
                    <InputLabel shrink>{t('vehicle.item.info.tire_width')}</InputLabel>
                    {/*https://github.com/mui/material-ui/issues/21394*/}
                    <Select
                        required={true}
                        input={<OutlinedInput notched
                                              label={t('vehicle.item.info.tire_width')}/>}
                        size="small"
                        // defaultValue={i18n.language}
                        label={t('vehicle.item.info.tire_width')}
                        value={width}
                        onChange={(e) => {
                            setWidth(e.target.value);
                        }}
                    >
                        {widths.map((value) => {
                            return <MenuItem key={value}
                                             value={value}>{value}mm</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </TableCell>
            <TableCell style={{
                paddingTop: isMobile ? '10px' : ''
            }} colSpan={isMobile ? '2' : ''}>
                <FormControl fullWidth>
                    <InputLabel shrink>{t('vehicle.item.info.tire_height')}</InputLabel>
                    <Select
                        input={<OutlinedInput notched
                                              label={t('vehicle.item.info.tire_height')}/>}
                        size="small"
                        value={height}
                        onChange={(e) => {
                            setHeight(e.target.value);
                        }}
                    >
                        {heights.map((value) => {
                            return <MenuItem key={value}
                                             value={value}>{value}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </TableCell>
            <TableCell
                style={{
                    paddingTop: isMobile ? '10px' : ''
                }}
                colSpan={isMobile ? '2' : ''}>
                <FormControl fullWidth>
                    <InputLabel
                        shrink>{t('vehicle.item.info.tire_category')}</InputLabel>
                    <Select
                        required={true}
                        input={<OutlinedInput notched
                                              label={t('vehicle.item.info.tire_category')}/>}
                        size="small"
                        value={tireCategory}
                        onChange={(e) => {
                            setTireCategory(e.target.value)
                        }}
                    >
                        {tireCategories.map((category, i) => {
                            return <MenuItem key={i}
                                             value={category.id}>{category.label}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </TableCell>
            {!isMobile &&
            <>
                <TableCell>
                    <FormControl fullWidth>
                        <InputLabel
                            shrink>{t('vehicle.item.info.tire_wheel_or_tire')}</InputLabel>
                        <Select
                            required={true}
                            input={<OutlinedInput notched
                                                  label={t('vehicle.item.info.tire_wheel_or_tire')}/>}
                            size="small"
                            value={isWheel}
                            onChange={(e) => {
                                setIsWheel(e.target.value);
                            }}
                        >
                            <MenuItem
                                value="0">{t('vehicle.item.info.tire_tire')}</MenuItem>
                            <MenuItem
                                value="1">{t('vehicle.item.info.tire_wheel')}</MenuItem>
                        </Select>
                    </FormControl>
                </TableCell>
                <TableCell>
                    <FormControl fullWidth>
                        <InputLabel
                            shrink>{t('vehicle.item.info.tire_count_and_position')}</InputLabel>
                        <Select
                            required={true}
                            input={<OutlinedInput notched
                                                  label={t('vehicle.item.info.tire_count_and_position')}/>}
                            size="small"
                            value={countPosition}
                            onChange={(e) => {
                                setCountPosition(e.target.value);
                            }}
                        >
                            <MenuItem value="2F">{t('vehicle.item.info.tire_2F')}</MenuItem>
                            <MenuItem value="2B">{t('vehicle.item.info.tire_2B')}</MenuItem>
                            <MenuItem value="4">{t('vehicle.item.info.tire_4')}</MenuItem>
                        </Select>
                    </FormControl>
                </TableCell>
            </>
            }

            {!buyVersion && !isMobile &&
            <TableCell colSpan={3}><TextField
                InputLabelProps={{shrink: true}}
                size="small"
                value={description}
                onChange={(e) => {
                    setDescription(e.target.value);
                }}
                fullWidth
                label={t('vehicle.item.info.tire_description')}
            /></TableCell>
            }
            <TableCell></TableCell>
        </TableRow>

        {isMobile &&
        <TableRow sx={{
            'td, th': {
                borderTop: 0,
                borderBottom: 0,
            }
        }}>
            {/*<TableCell>*/}
            {/*</TableCell>*/}
            <TableCell colSpan={4} style={{
                borderTop: '0px',
                paddingTop: '10px'
            }}>
                <FormControl fullWidth>
                    <InputLabel
                        shrink>{t('vehicle.item.info.tire_wheel_or_tire')}</InputLabel>
                    <Select
                        required={true}
                        input={<OutlinedInput notched
                                              label={t('vehicle.item.info.tire_wheel_or_tire')}/>}
                        size="small"
                        value={isWheel}
                        onChange={(e) => {
                            setIsWheel(e.target.value);
                        }}
                    >
                        <MenuItem
                            value="0">{t('vehicle.item.info.tire_tire')}</MenuItem>
                        <MenuItem
                            value="1">{t('vehicle.item.info.tire_wheel')}</MenuItem>
                    </Select>
                </FormControl>
            </TableCell>
            <TableCell colSpan={3} style={{
                paddingTop: '10px'
            }}>
                <FormControl fullWidth>
                    <InputLabel
                        shrink>{t('vehicle.item.info.tire_count_and_position')}</InputLabel>
                    <Select
                        required={true}
                        input={<OutlinedInput notched
                                              label={t('vehicle.item.info.tire_count_and_position')}/>}
                        size="small"
                        value={countPosition}
                        onChange={(e) => {
                            setCountPosition(e.target.value);
                        }}
                    >
                        <MenuItem value="2F">{t('vehicle.item.info.tire_2F')}</MenuItem>
                        <MenuItem value="2B">{t('vehicle.item.info.tire_2B')}</MenuItem>
                        <MenuItem value="4">{t('vehicle.item.info.tire_4')}</MenuItem>
                    </Select>
                </FormControl>
            </TableCell>
        </TableRow>
        }

        {!buyVersion && isMobile &&
        <TableRow sx={{
            'td, th': {
                borderTop: 0,
                borderBottom: 0
            }
        }}>
            <TableCell colSpan={7} style={{
                paddingTop: '10px',
                // paddingLeft: '10px',
                // paddingRight: '10px',
            }}><TextField
                InputLabelProps={{shrink: true}}
                size="small"
                value={description}
                onChange={(e) => {
                    setDescription(e.target.value);
                }}
                fullWidth
                label={t('vehicle.item.info.tire_description')}
            /></TableCell>
        </TableRow>
        }
        <TableRow
            sx={{

                'td, th': {
                    padding: 0,
                    paddingLeft: '16px',
                    border: 0,
                    // backgroundColor: '#afbff0'
                },

            }}>
            <TableCell colSpan="7">
                <Grid container justifyContent={isMobile ? 'center' : ''}>
                    <Button
                        onClick={addTireClickedHandler}
                        variant="contained"
                        color="yellow"
                        sx={{mt: '15px', mb: '20px', mr: isMobile ? '10px' : ''}}
                    >
                        {t(isMobile ? 'vehicle.item.info.add_tire_mobile_btn' : 'vehicle.item.info.add_tire_btn')}
                    </Button>
                    {isMobile &&
                    <Button
                        onClick={cancelHandler}
                        variant="contained"
                        color="yellow"
                        sx={{mt: '15px', mb: '20px', ml: '10px'}}
                    >
                        {t('common.cancel')}
                    </Button>
                    }
                </Grid>
            </TableCell>
        </TableRow>
    </>
}