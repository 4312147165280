import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import useFetch from "../hooks/useFetch";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import useLocalStorageState from "use-local-storage-state";

const {useTranslation} = require("react-i18next");

function LanguageSelector({apiUri}) {
    const [t, i18n] = useTranslation('common');
    const {call, callPost, isSending} = useFetch();
    const accountData = useSelector(state => state.account.accountData);
    const [accessToken, setAccessToken] = useLocalStorageState('accessToken');

    // NB Au démarage de l'appli, dans index.js, le systeme de localisation est déjà initialisé par rapport au localStorage
    // Si le language est différent dans le account, on le met à jour
    useEffect(() => {
        if (accountData != null) {
            changeLanguage(accountData.user.language);
        }
    }, [accountData]);

    // useEffect(() => {
    //     console.log("LS TOKEN : ", accessToken);
    // }, [accessToken]);

    function changeLanguageHandler(e) {
        console.log("changeLanguageHandler accessToken=", accessToken);
        let language = e.target.value;
        if (accessToken != null) {
            callPost(apiUri + 'change_language', {'language': language}, (resultData => {
                changeLanguage(language);
            }));
        } else {
            changeLanguage(language);
        }
    }

    function changeLanguage(language) {
        // TODO ressortir les langues authorisées
        if (['fr', 'en'].includes(language)) {
            localStorage.setItem('language', language);
            i18n.changeLanguage(language);
        } else {
            // Ignoré
        }
    }

    return <FormControl>
        <InputLabel id="demo-simple-select-label">{t('common.language')}</InputLabel>
        <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={i18n.language}
            label={t('common.language')}
            onChange={changeLanguageHandler}
        >
            <MenuItem value='en'>English</MenuItem>
            <MenuItem value='fr'>Français</MenuItem>
        </Select>
    </FormControl>
}

export default LanguageSelector;