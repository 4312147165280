import React, {forwardRef, useContext, useEffect, useRef, useState} from "react";
import {
    Box,
    Container,
    Grid, InputAdornment,
    TextField,
    Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import Swal from "sweetalert2";
import {useOutletContext} from "react-router-dom";
import ImageButton from "./ImageButton";

import IconCarwash from "../assets/services/carwash/Car-Wash-B-G.png";
import IconCarwashSelected from "../assets/services/carwash/Car-Wash-B-j.png";

import IconTires from "../assets/services/tires/Pneu-B-G.png";
import IconTiresSelected from "../assets/services/tires/Pneu-B-J.png";
import ServiceMap from "./ServiceMap";
import ServiceLocationItem from "./ServiceLocationItem";
import SearchIcon from '@mui/icons-material/Search';
import useFetch from "../hooks/useFetch";
import MobileContext from "../contexts/mobileContext";
import {useSelector} from "react-redux";
import {getIsBranded} from "../features/branded/locationGroupSlice";
import VehicleSelector from "./VehicleSelector";
import FormatUtils from "../utils/FormatUtils";
import {AccountType} from "../constants/AccountType";

function ServiceStep2({
                          previousBtn,
                          nextBtn,
                          contentLoadedHandler,
                          setLabel,
                          validateLocation,
                          selectedLocation,
                          selectedDay,
                          selectedTimeslot,
                          vehicle,
                          validateVehicle
                      }, ref) {
    const [apiUri] = useOutletContext();
    const {t} = useTranslation('common');

    const [loaded, setLoaded] = useState(false);
    const [serviceTypeList, setServiceTypeList] = useState([]); // Liste des serviceTypes dispo
    const [locationList, setLocationList] = useState(null);
    const [userPosition, setUserPosition] = useState();

    const [localSelectedLocation, setLocalSelectedLocation] = useState(selectedLocation);
    const [localSelectedDay, setLocalSelectedDay] = useState(selectedDay);
    const [localSelectedTimeslot, setLocalSelectedTimeslot] = useState(selectedTimeslot);

    const [searchText, setSearchText] = useState("");

    // Refs, nécéssaire pour le next
    const selectedLocationRef = useRef(null);
    selectedLocationRef.current = localSelectedLocation;

    const selectedDayRef = useRef(null);
    selectedDayRef.current = localSelectedDay;

    const selectedPeriodRef = useRef(null);
    selectedPeriodRef.current = localSelectedTimeslot;

    const mobileContext = useContext(MobileContext);
    const isMobile = mobileContext.isMobile;

    const isBranded = useSelector(getIsBranded);
    const locationGroupId = useSelector(state => state.locationGroup.locationGroupData?.id);
    const accountType = localStorage.getItem('accountType');

    // const [localVehicle, setLocalVehicle] = useState();
    // const localVehicleRef = useRef(null);
    // localVehicleRef.current = localVehicle;

    const vehicleRef = useRef(null);
    vehicleRef.current = vehicle;

    const firstUpdate = useRef(true);

    // Geneve
    const defaultUserPosition = {
        'lat': 46.2043907,
        'lng': 6.1431577
    }

    const [serviceOrderData, setServiceOrderData] = useState(
        {
            ...{
                'origin': null,
                'vehicle_id': vehicle?.id,
                // 'service_types': [1, 2]
            },
            ...(locationGroupId != null ? {'location_group_id': locationGroupId} : {})
        });


    // localVehicleIdRef.current = localVehicleId;

    const {call, callPost} = useFetch();

    useEffect(() => {
        // console.log('SS2 : FIRST RENDER!');

        setLabel(isBranded ? '' : 'service.step2.title');
        previousBtn.current.style.display = isBranded ? 'none' : '';


        nextBtn.current.style.display = '';
        nextBtn.current.addEventListener('click', nextClickedHandler);

        serviceTypeIndexGet();
        getUserPosition();
        return () => {
            if (nextBtn.current) {
                nextBtn.current.removeEventListener('click', nextClickedHandler);
            }
        }
    }, [])

    useEffect(() => {
        // console.log('userPosition!', userPosition);
        if (userPosition != null && serviceTypeList != []) {
            serviceOrderData.origin = userPosition;
            serviceOrderData.service_types = serviceTypeList.map(serviceType => serviceType.id);
            setServiceOrderData(JSON.parse(JSON.stringify(serviceOrderData)));
        }
    }, [userPosition, serviceTypeList]);

    useEffect(() => {
        if (serviceOrderData.origin != null && serviceOrderData.service_types.length > 0) {
            serviceSearch();
        }
    }, [serviceOrderData]);

    useEffect(() => {
        // TODO Scroller au bon endroit : mettre une ref sur chaque element
        // if (localSelectedLocation != null && scrollToDown.current != null) {
        //     scrollToDown.current.scrollIntoView({behavior: "instant"});
        // }
        // console.log('localSelectedLocation = ', localSelectedLocation)

    }, [localSelectedLocation]);


    useEffect(() => {
        // NB : On reset l'emplacement selectionné => Il n'est ptet pas dispo avec ce véhicule
        // => Que si la valeur a changée au cours du cycle de vie de ce composant ( sinon ca empeche de revenir correctement en arrière depuis la step3 )
        // console.log('vehicle', vehicle);

        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        setLocalSelectedLocation(null);
        setLocalSelectedDay(null);
        setLocalSelectedTimeslot(null);
        serviceOrderData.vehicle_id = vehicle?.id;
        setServiceOrderData(FormatUtils.cloneData(serviceOrderData));
    }, [vehicle]);

    useEffect(() => {
        // On a un jour qui a été passé, on doit "scroller" jusqu'à lui

        // TODO

    }, [selectedDay]);

    // if (token == null) {
    //     return ( //
    //         <Navigate to='/login'/>
    //     );
    // }

    async function serviceTypeIndexGet() {
        call(apiUri + 'service_type_index' + (locationGroupId != null ? ('/' + locationGroupId) : ''), (resultData => {
            resultData.service_types.forEach((serviceType) => {
                switch (serviceType.id) {
                    case 1 :
                        serviceType.icon = IconCarwash;
                        serviceType.iconSelected = IconCarwashSelected;
                        break;
                    case 2:
                        serviceType.icon = IconTires;
                        serviceType.iconSelected = IconTiresSelected;
                        break;
                    default :
                        console.log("Unknown service type : " + serviceType.id);
                }
                serviceType.label = t('service_types.' + serviceType.id);
            });
            setServiceTypeList(resultData.service_types);
        }));
    }

    async function serviceSearch() {
        callPost(apiUri + 'service_search', serviceOrderData, (resultData) => {
            resultData.locations.forEach(location => {
                location.dayScheduleHuman = getHumanSchedule(location.daySchedule);
            });
            setLocationList(resultData.locations);
            setLoaded(true);
            contentLoadedHandler();
        });
    }

    function nextClickedHandler() {
        if (isPreview()) {
            showPreviewWarning();
            return;
        }

        if (selectedLocationRef.current == null) {
            Swal.fire({
                text: t('service.step2.warning_must_select_location'),
                icon: 'warning',
                target: document.getElementById('swal_container')
            });
            return;
        }

        if (selectedDayRef.current == null || selectedPeriodRef.current == null) {
            Swal.fire({
                text: t('service.step2.warning_must_select_period'),
                icon: 'warning',
                target: document.getElementById('swal_container')
            });
            return;
        }

        // Pour la version branded le choix de véhicule se fait sur cette étape
        if (vehicleRef.current == null) {
            Swal.fire({
                text: t('service.step2.warning_select_vehicle'),
                icon: 'warning',
                target: document.getElementById('swal_container')
            });
            return;
        }

        validateLocation(selectedLocationRef.current, selectedDayRef.current, selectedPeriodRef.current);

        // if (localVehicleIdRef.current != '') {
        //     setVehicleId(localVehicleIdRef.current);
        // }
    }

    // Au click sur un type de service ( toggle )
    function serviceTypeSelectedHandler(serviceType) {
        // console.log('serviceTypeSelectedHandler');
        let index = serviceOrderData.service_types.indexOf(serviceType.id);
        if (index === -1) {
            serviceOrderData.service_types.push(serviceType.id);
        } else {
            if (serviceOrderData.service_types.length > 1) {
                serviceOrderData.service_types.splice(index, 1);
            }
        }
        setServiceOrderData(JSON.parse(JSON.stringify(serviceOrderData)));
    }

    function locationClickedHandler(locationData) {
        setLocalSelectedLocation(locationData);
        setLocalSelectedDay(null);
        setLocalSelectedTimeslot(null);
    }

    function locationCloseClickedHandler(locationData) {
        setLocalSelectedLocation(null);
        // console.log("CLOSE", locationData);
    }

    function periodClickedHandler(calendarDayData, period) {
        setLocalSelectedDay(calendarDayData);
        setLocalSelectedTimeslot(period);
    }

    function searchClickedHandler() {
        // console.log('searchClickedHandler');
        serviceOrderData.search = searchText;
        setServiceOrderData(JSON.parse(JSON.stringify(serviceOrderData)));
    }

    function getHumanSchedule(schedule) {
        let result = '';
        if (schedule == null) {
            return t('schedule.schedule_not_available');
        }
        if (schedule.am_closed == '1' && schedule.pm_closed == '1') {
            return t('schedule.closed')
        }

        if (schedule.am_closed == '1') {
            result += t('schedule.closed_am');
        } else {
            result += schedule.am_start + ' ' + t('schedule.to') + ' ' + schedule.am_end;
        }
        result += ' - '

        if (schedule.pm_closed == '1') {
            result += t('schedule.closed_pm');
        } else {
            result += schedule.pm_start + ' ' + t('schedule.to') + ' ' + schedule.pm_end;
        }
        return result;
    }

    function getUserPosition() {
        if (window.navigator.geolocation) {
            window.navigator.geolocation
                .getCurrentPosition((geoLocationPosition) => {
                    // console.log(geoLocationPosition);
                    setUserPosition({
                        lat: geoLocationPosition.coords.latitude,
                        lng: geoLocationPosition.coords.longitude
                    })
                }, () => {
                    setUserPosition(defaultUserPosition);
                });
        } else {
            setUserPosition(defaultUserPosition);
        }
    }

    function getCurrentServiceTypesHuman() {
        let result = [];
        serviceOrderData.service_types.forEach(serviceTypeId => {
            let service = serviceTypeList.find(serviceType => {
                return serviceType.id == serviceTypeId
            });
            result.push(service.label);
        });
        return result.join(', ');
    }

    function vehicleSelectedHandler(vehicle) {
        // setLocalVehicle(vehicle);
        // console.log('ServiceStep2.vehicleSelectedHandler');
        validateVehicle(vehicle, false);
    }

    function isPreview() {
        return accountType == AccountType.PROVIDER;
    }

    // TODO Répétition de celui de BrandedPreviewBAnner, peut-etre le centralisé dans le BrandedContainer ?
    function showPreviewWarning() {
        Swal.fire({
            text: t('branded.warning_vehicle'),
            icon: 'warning',
            target: document.getElementById('swal_container')
        });
    }

    return (
        <Container
            maxWidth={false}
            disableGutters={isMobile}
            style={{
                // backgroundColor: '#afbff0'
            }}
        >
            {loaded &&
                <>
                    {isBranded &&
                        <>
                            <VehicleSelector
                                vehicle={vehicle}
                                vehicleSelectedHandler={vehicleSelectedHandler}
                                isPreview={isPreview()}
                                previewActionHandler={() => {
                                    showPreviewWarning();
                                }}
                            />
                            <Grid container justifyContent="center">
                                <Typography>{t('service.step2.title')}</Typography>
                            </Grid>

                        </>
                    }
                    <Grid container>
                        <Grid item xs={isMobile ? 12 : 6} sx={{
                            p: isMobile ? '8px' : '16px',
                            paddingBottom: isMobile ? '0px' : null
                        }}>
                            <Grid container display="flex" style={{
                                flexWrap: 'nowrap'
                            }}>
                                {
                                    serviceTypeList.map((serviceType, i) => {
                                        return (
                                            <Grid item m={isMobile ? '5px' : 1} key={i} style={{
                                                flexShrink: 0   // Plus prio que le champs recherche
                                            }}>
                                                <ImageButton
                                                    disabled={serviceTypeList.length === 1}
                                                    text={t('service_types.' + serviceType.id)}
                                                    textSize={isMobile ? '10px' : null}
                                                    selectWithBackground={false}
                                                    height={isMobile ? '20px' : '60px'}
                                                    src={serviceType.icon}
                                                    srcOver={serviceType.iconSelected}
                                                    selected={serviceOrderData.service_types.includes(serviceType.id)}
                                                    onClickHandler={
                                                        (e) => {
                                                            serviceTypeSelectedHandler(serviceType)
                                                        }
                                                    }
                                                />
                                            </Grid>);
                                    })
                                }
                                {
                                    // Mobile => Search
                                    // TODO Redondant
                                    isMobile && <Grid
                                        style={{
                                            // flexGrow: 1
                                            // backgroundColor: '#afbff0'
                                        }}
                                    ><TextField
                                        // style={{width: '100%'}}
                                        size="small"
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.currentTarget.value)}
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                searchClickedHandler();
                                            }
                                        }}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                <SearchIcon
                                                    style={{
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={searchClickedHandler}
                                                />
                                            </InputAdornment>
                                        }}
                                    /></Grid>}
                            </Grid>
                            {
                                <>
                                    <Grid sx={{
                                        borderBottom: 1,
                                        borderColor: 'divider',
                                        marginTop: "10px",
                                        marginBottom: '10px'
                                    }}/>

                                    <Container disableGutters={true} sx={{
                                        // backgroundColor: "#FF0000"
                                    }}>
                                        {!isMobile &&   // Search non mobile
                                            <>
                                                <Grid container justifyContent="space-between" alignItems="center">
                                                    <Typography><b>{t('service.step2.provider_label')}</b></Typography>
                                                    <TextField
                                                        size="small"
                                                        value={searchText}
                                                        onChange={(e) => setSearchText(e.currentTarget.value)}
                                                        onKeyPress={(e) => {
                                                            if (e.key === 'Enter') {
                                                                searchClickedHandler();
                                                            }
                                                        }}
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="end">
                                                                <SearchIcon
                                                                    style={{
                                                                        cursor: 'pointer'
                                                                    }}
                                                                    onClick={searchClickedHandler}
                                                                />
                                                            </InputAdornment>
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid sx={{
                                                    borderBottom: 1,
                                                    borderColor: 'divider',
                                                    marginTop: "20px",
                                                    marginBottom: '10px'
                                                }}/>
                                            </>
                                        }

                                        <Box style={{maxHeight: isMobile ? "" : "40vh", overflow: 'auto'}}
                                        >
                                            {
                                                locationList != null && locationList.map((location, i) => {
                                                    return (<ServiceLocationItem
                                                            key={i}
                                                            locationData={location}
                                                            selected={FormatUtils.locationIsSame(localSelectedLocation, location)}
                                                            selectedDay={localSelectedDay}
                                                            selectedPeriod={localSelectedTimeslot}
                                                            clickedHandler={locationClickedHandler}
                                                            periodClicked={periodClickedHandler}
                                                        />

                                                    );
                                                })
                                            }
                                        </Box>
                                    </Container>
                                </>
                            }
                        </Grid>
                        {!isMobile && <Grid item xs={isMobile ? 12 : 6} sx={{
                            p: isMobile ? '8px' : '16px',
                            paddingTop: isMobile ? '10px' : null
                        }}>
                            <ServiceMap locationList={locationList}
                                        selectedLocation={localSelectedLocation}
                                        locationClickedHandler={locationClickedHandler}
                                        userPosition={serviceOrderData.origin}
                                        locationCloseClickedHandler={locationCloseClickedHandler}
                            />
                        </Grid>
                        }

                        {/*{*/}
                        {/*    // TODO Redondant*/}
                        {/*    isMobile && <Box*/}
                        {/*        margin={0}*/}
                        {/*        padding={0}*/}
                        {/*        style={{*/}
                        {/*            // height: '34vh',*/}
                        {/*            // maxHeight: "34vh",*/}
                        {/*            overflow: 'auto',*/}
                        {/*            width: '100%'*/}
                        {/*        }}>*/}
                        {/*        {*/}
                        {/*            localSelectedLocation != null ?*/}
                        {/*                <ServiceLocationItem*/}
                        {/*                    locationData={localSelectedLocation}*/}
                        {/*                    selected={true}*/}
                        {/*                    selectedDay={localSelectedDay}*/}
                        {/*                    selectedPeriod={localSelectedTimeslot}*/}
                        {/*                    clickedHandler={locationClickedHandler}*/}
                        {/*                    periodClicked={periodClickedHandler}*/}
                        {/*                />*/}
                        {/*                :*/}
                        {/*                <Grid*/}
                        {/*                    container*/}
                        {/*                    justifyContent="center"*/}
                        {/*                    alignContent="center"*/}
                        {/*                    style={{*/}
                        {/*                        height: '100%',*/}
                        {/*                    }}*/}
                        {/*                >*/}
                        {/*                    <Typography>*/}
                        {/*                        {t('service.step2.select_service_please')}*/}
                        {/*                    </Typography>*/}
                        {/*                </Grid>*/}
                        {/*        }*/}
                        {/*    </Box>}*/}
                    </Grid>
                </>
            }
        </Container>
    )
};

export default forwardRef(ServiceStep2);