import React from "react";
import {
    Box,
    Button
} from "@mui/material";
import {useTranslation} from "react-i18next";
import '../App.css';
import LocationTireStorageElement from "./LocationTireStorageElement";

// type = W / D
function LocationTireStorageType({
                                     type,
                                     tireCategories,
                                     tireWidths,
                                     tireStorageValues,
                                     newOptionHandler,
                                     deleteOptionHandler,
                                     refreshTireStorageDataHandler
                                 }) {

    const {t} = useTranslation('common');

    return (
        <Box sx={{width: '100%'}}>
            {tireStorageValues != null && tireStorageValues.map((tireStorageValue, index) =>
                <LocationTireStorageElement
                    key={index}
                    type={type}
                    tireStorageValue={tireStorageValue}
                    tireCategories={tireCategories}
                    tireWidths={tireWidths}
                    refreshTireStorageDataHandler={refreshTireStorageDataHandler}
                    canDelete={index > 0}
                    deleteOptionHandler={deleteOptionHandler}
                />)}
            {type != 'U' &&
            <Button
                fullWidth
                variant="contained"
                color="yellow"
                sx={{mt: '15px', mb: '20px'}}
                onClick={(e) => newOptionHandler()}
            >
                {t('location.services.tire_storage.add_button')}
            </Button>
            }
        </Box>
    )
};

export default LocationTireStorageType;