import React, {useEffect} from 'react';
import {useOutletContext} from "react-router-dom";
import {useTranslation} from "react-i18next";
import useFetch from "../hooks/useFetch";

function TireService({
                         setTireCategories,
                         setTireWidthsHandler = null
                     }) {
    const [apiUri] = useOutletContext();
    const {call} = useFetch();

    useEffect(() => {
        getTireCategories();
        if(setTireWidthsHandler != null)
        {
            getTireWidths();
        }
    }, [])

    function getTireCategories() {
        call(apiUri + 'vehicle_tire_categories', (data) => {
            setTireCategories(data.categories);
        });
    }

    function getTireWidths() {
        const minWidth = 125;
        const maxWidth = 335;

        let widths = [];
        for (let w = minWidth; w < maxWidth; w++) {
            widths.push(w);
        }
        setTireWidthsHandler(widths);
    }
}

export default TireService;