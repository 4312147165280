import {configureStore} from '@reduxjs/toolkit'
import locationGroupSlice from "../features/branded/locationGroupSlice";
import accountSlice from "../features/account/accountSlice";
import counterReducer from '../features/counter/counterSlice';
import {LocationGroupApi} from "../features/branded/LocationGroupApi";
import {setupListeners} from '@reduxjs/toolkit/query'

export default configureStore({
    reducer: {
        counter: counterReducer,
        account: accountSlice,
        locationGroup: locationGroupSlice,
        [LocationGroupApi.reducerPath]: LocationGroupApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(LocationGroupApi.middleware),
})

// setupListeners(configureStore.dispatch);