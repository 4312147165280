import {
    Box,
    Button, ButtonBase,
    Container,
    Grid,
    Link, Stack,
    Typography
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import React, {useContext, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@mui/styles";
import VehicleItemSectionInfo from "./VehicleItemSectionInfo";
import VehicleItemSectionServiceHistory from "./VehicleItemSectionServiceHistory";
import EditIcon from '@mui/icons-material/Edit';
import VehicleItemSectionDocuments from "./VehicleItemSectionDocuments";
import CarService from "../services/CarService";
import {VehicleSectionType} from "../constants/VehicleSectionType";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MobileContext from "../contexts/mobileContext";
import LeasingIcon from '../assets/leasing-icon-white.png';
import FormatUtils from "../utils/FormatUtils";

export default function VehicleItem({
                                        vehicleData,
                                        deleteVehicleHandler,
                                        reloadHandler,
                                        vehicleDocumentTypes,
                                        tireCategories,
                                        showOrderCardHandler,
                                        newOrderData,
                                        forceExpandWithVehicleId,
                                        forceExpandSection = null
                                    }) {
    const {t} = useTranslation('common');
    const classes = useStyles();
    const navigate = useNavigate();

    const [selected, setSelected] = useState(true);

    const scrollToRef = useRef(null);

    const mobileContext = useContext(MobileContext);
    const isMobile = mobileContext.isMobile;

    useEffect(() => {
        if (vehicleData.id == forceExpandWithVehicleId) {
            scrollToRef.current.scrollIntoView({behavior: "instant"});
        }
    }, [forceExpandWithVehicleId]);

    function getEditPath(vehicleId) {
        let path = '/vehicle/step2/' + vehicleId;
        return path;
    }

    function itemClickedHandler(e) {
        setSelected(!selected);
    }

    function orderServiceClickedHandler(e) {
        navigate('../../service_order', {state: {vehicle: vehicleData}});
    }

    function editVehicleClickedHandler(vehicleId) {
        navigate(getEditPath(vehicleId));
    }

    function getVehiclePicture(vehicleData) {
        if (vehicleData.picture != null) {
            return vehicleData.picture;
        }

        // On prend la categorie
        let carCateg = CarService.getCarCategoryById(vehicleData.car_category_id);
        if (carCateg == null) {
            return null;
        }
        return carCateg.icon;
    }

    return (
        <Container disableGutters={isMobile}>
            <div ref={scrollToRef}/>
            <Box
                onClick={itemClickedHandler}
                sx={{
                    border: 0,
                    borderRadius: '24px',
                    borderBottomLeftRadius: selected ? '0px' : '24px',
                    borderBottomRightRadius: selected ? '0px' : '24px',
                    p: isMobile ? '10px' : '16px',
                    m: isMobile ? '0px' : '10px',
                    mb: selected ? '0px' : '10px',
                    backgroundColor: 'black'
                }}

            >
                <Grid container>
                    <Grid item container xs={4} sm={3} justifyContent="center">
                        <Box sx={{
                            display: !isMobile ? 'grid' : '',
                            gridTemplateRows: !isMobile ? '1fr 50px' : '',
                        }}
                        >
                            {getVehiclePicture(vehicleData) &&
                            <Box display='flex' alignItems='center'
                                 style={{
                                     // marginLeft:'5px',
                                     height: '100%',
                                     // backgroundColor: 'red'
                                 }}
                            >
                                <img src={getVehiclePicture(vehicleData)}
                                     className={isMobile ? classes.pictureImg_mobile : classes.pictureImg}/>
                            </Box>
                            }
                            {!isMobile && vehicleData.licence_plate &&
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '10px',
                                    border: 2,
                                    borderRadius: '8px',
                                    borderColor: 'orange',
                                    backgroundColor: 'white',
                                    p: '8px',
                                    color: 'black'
                                }}>
                                <b>{vehicleData.licence_plate}</b>
                            </Box>
                            }
                        </Box>
                    </Grid>
                    <Grid item xs={7} container={isMobile} justifyContent={isMobile ? 'center' : ''}
                          style={{
                              // backgroundColor: 'grey'
                          }}
                    >
                        <Stack justifyContent={isMobile ? 'center' : ''} alignItems={isMobile ? 'center' : ''}>
                            {vehicleData.leasing_company != null &&
                            <Grid container>
                                <Grid item>
                                    <img src={LeasingIcon} style={{
                                        height: 'auto',
                                        width: '30px',
                                        marginRight: '10px'
                                    }}/>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        sx={{fontSize: '18px'}}>{FormatUtils.changeTextWithValues(t('vehicle.leasing_label'), {'leasing_company': vehicleData.leasing_company.label})}</Typography>
                                </Grid>
                            </Grid>
                            }
                            <Typography sx={{fontSize: '18px'}}>{vehicleData.brand}</Typography>
                            <Typography sx={{fontSize: '18px'}}>{vehicleData.model}</Typography>
                            {!isMobile &&
                            <Typography sx={{fontSize: '18px'}}>{vehicleData.color}</Typography>
                            }
                            {isMobile && vehicleData.licence_plate != null && vehicleData.licence_plate != '' &&
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    border: 2,
                                    borderRadius: '8px',
                                    borderColor: 'orange',
                                    backgroundColor: 'white',
                                    p: '4px',
                                    color: 'black'
                                }}>
                                <b>{vehicleData.licence_plate}</b>
                            </Box>
                            }
                        </Stack>
                    </Grid>
                    <Grid container item xs={1} sm={2} alignContent="space-around" justifyContent="right"
                          style={{
                              // backgroundColor: 'red'
                          }}
                    >
                        {!isMobile &&
                        <Button color="yellow" variant="contained" onClick={(e) => {
                            e.stopPropagation()
                            orderServiceClickedHandler();
                        }}>
                            {t('vehicle.button_order_service')}
                        </Button>
                        }

                        <ButtonBase color="yellow" onClick={
                            (e) => {
                                e.stopPropagation();
                                editVehicleClickedHandler(vehicleData.id);
                            }
                        }>
                            <EditIcon sx={
                                {
                                    color: 'orange',
                                    fontSize: isMobile ? 25 : 50,
                                }}/>
                        </ButtonBase>
                        <ExpandMoreIcon sx={
                            {
                                transform: selected ? 'rotate(180deg)' : '',
                                color: 'orange',
                                fontSize: isMobile ? 30 : 50,
                            }}/>
                    </Grid>
                </Grid>
            </Box>
            {selected &&
            <Box sx={{
                border: 0,
                borderBottomLeftRadius: '24px',
                borderBottomRightRadius: '24px',
                p: isMobile ? '5px' : '16px',
                m: isMobile ? '' : '10px',
                mb: '10px',
                mt: '0px',
                backgroundColor: '#666666'
            }}>
                <VehicleItemSectionServiceHistory vehicleData={vehicleData}
                                                  type="accepted"
                                                  showOrderCardHandler={showOrderCardHandler}
                                                  newOrderData={newOrderData}
                                                  forceExpandWithVehicleId={(forceExpandSection == null || forceExpandSection == VehicleSectionType.ORDER_ACCEPTED) ? forceExpandWithVehicleId : null}
                />
                <VehicleItemSectionServiceHistory vehicleData={vehicleData} type="done"
                                                  showOrderCardHandler={showOrderCardHandler}
                                                  forceExpandWithVehicleId={forceExpandSection == VehicleSectionType.ORDER_DONE ? forceExpandWithVehicleId : null}
                />
                <VehicleItemSectionInfo vehicleData={vehicleData} tireCategories={tireCategories}
                                        reloadHandler={reloadHandler}
                                        forceExpandWithVehicleId={forceExpandSection == VehicleSectionType.INFO ? forceExpandWithVehicleId : null}
                />
                <VehicleItemSectionDocuments vehicleData={vehicleData} reloadHandler={reloadHandler}
                                             vehicleDocumentTypes={vehicleDocumentTypes}
                                             forceExpandWithVehicleId={forceExpandSection == VehicleSectionType.DOCUMENTS ? forceExpandWithVehicleId : null}
                />
                <Link
                    href="#"
                    color="#AAAAAA"
                    onClick={(e) => deleteVehicleHandler(e, vehicleData.id)}
                >
                    {
                        t("vehicle.delete_vehicle_link")
                    }
                </Link>
            </Box>
            }
        </Container>
    )
}

const useStyles = makeStyles((theme) => (
    {
        pictureImg: {
            height: '100px',
            width: '200px',
            paddingLeft: '10px',
            paddingRight: '10px',
            objectFit: 'contain',
            borderRadius: '5px'
        },
        pictureImg_mobile: {
            // height: '100%',
            // width: '100%',
            // width: 'auto',
            // height: 'auto',
            maxWidth: '100%',
            maxHeight: '100%',
            // paddingLeft: '10px',
            // paddingRight: '10px',
            objectFit: 'contain',
            borderRadius: '5px'
        }
    }
));