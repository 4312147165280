import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState} from "react";
import {
    Box,
    Button,
    Checkbox,
    Container, FormControl, FormControlLabel, FormGroup,
    Grid, InputLabel,
    MenuItem,
    Select,
    Stack,
    Switch,
    TextField,
    Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import Swal from "sweetalert2";
import {useOutletContext, useParams} from "react-router-dom";
import '../App.css';
import PictureUpload from "./PictureUpload";

import defaultImage from "../assets/menu/location/emplacement-b-g.png";
import useFetch from "../hooks/useFetch";
import {PayByInvoiceType} from "../constants/PayByInvoiceType";
import FormatUtils from "../utils/FormatUtils";
import {ServiceType} from "../constants/ServiceType";
import {KucPhoneTextField} from "./common/KucPhoneTextField";
import useAlert from "../hooks/useAlert";

function LocationInfo({
                          previous_btn,
                          next_btn,
                          changeStepFromContentHandler,
                          contentLoadedHandler,
                          isEditMode,
                          saveNeededHandler,
                          canceledSaveHandler,
                          contentSavedHandler,
                      }, ref) {
    const [apiUri] = useOutletContext();
    const {t} = useTranslation('common');
    const formRef = React.useRef();

    const [locationId, setLocationId] = useState(null);
    const [contactId, setContactId] = useState(null);

    const [locationData, setLocationData] = useState(null);

    const [privateLocation, setPrivateLocation] = useState(false);
    const [mobileLocation, setMobileLocation] = useState(false);

    const [payByInvoiceType, setPayByInvoiceType] = useState(null);
    const [payByInvoiceEmail, setPayByInvoiceEmail] = useState(null);

    const [hasLeasing, setHasLeasing] = useState(false);
    const [leasingInvoiceEmail, setLeasingInvoiceEmail] = useState('');
    const [leasingCompanyList, setLeasingCompanyList] = useState(null);
    const [selectedLeasingCompanies, setSelectedLeasingCompanies] = useState([]);


    const [loadedPayByInvoiceType, setLoadedPayByInvoiceType] = useState(null)
    const [supercarSupport, setSupercarSupport] = useState(null);
    const [isPrivate, setIsPrivate] = useState(null);

    const defaultServiceTypeId = ServiceType.CARWASH;
    const [serviceTypeId, setServiceTypeId] = useState(defaultServiceTypeId);
    const defaultCurrency = 'CHF';
    const [currency, setCurrency] = useState(defaultCurrency);

    const [phone, setPhone] = useState('');
    const [validPhone, setValidPhone] = useState();

    const phoneRef = useRef(null);
    const validPhoneRef = useRef(null);

    const {showWarning} = useAlert();

    phoneRef.current = phone;
    validPhoneRef.current = validPhone;

    const {call, callPost} = useFetch();

    let params = useParams();

    useEffect(() => {
        if (params.locationId != null) {
            getFromServer(params.locationId);
        }

        getLeasingCompanies();

        previous_btn.current.style.display = 'none';
        // props.previous_btn.current.addEventListener('click', () => console.log('back'));
        next_btn.current.addEventListener('click', submitData);

        return () => {
            next_btn.current && next_btn.current.removeEventListener('click', submitData);
        }

    }, [])

    useEffect(() => {
        if (formRef.current == null) {
            return;
        }
        formRef.current.addEventListener('change', formChangedHandler);
        return () => {
            if (formRef.current == null) {
                return;
            }
            formRef.current.removeEventListener('change', formChangedHandler);
        }
    });

    function formChangedHandler() {
        saveNeededHandler(true);
    }

    function submitData() {
        if (!formRef.current.reportValidity()) {
            canceledSaveHandler();
            return;
        }
        formRef.current.dispatchEvent(
            new Event("submit", {cancelable: true, bubbles: true})
        );
    }

    useImperativeHandle(ref, () => ({
        save() {
            submitData();
        }
    }));

    // TODO redondant
    async function getFromServer(id) {
        call(apiUri + 'location_get/' + id, (data) => {
            setLocationId(data.location.id);
            setLocationData(data.location);
            setServiceTypeId(data.location.service_type_id);
            setPayByInvoiceType(data.location.pay_by_invoice_type);
            setLoadedPayByInvoiceType(data.location.pay_by_invoice_type);
            setPayByInvoiceEmail(data.location.pay_by_invoice_email);
            setPrivateLocation(getOptionExists(data.location, 1));
            setMobileLocation(getOptionExists(data.location, 2));

            setSupercarSupport(data.location.supercar_support == 1);
            setIsPrivate(data.location.is_private == 1);

            // console.log(data.location.leasing_companies);

            if (data.location.leasing_companies != null) {
                if (data.location.leasing_companies.length > 0) {
                    setHasLeasing(true);
                }

                setLeasingInvoiceEmail(data.location.leasing_invoice_email);

                let leasingCompaniesId = [];
                data.location.leasing_companies.forEach(leasingCompany => {
                    leasingCompaniesId.push(leasingCompany.id);
                });
                setSelectedLeasingCompanies(leasingCompaniesId);
            }

            if(data.location.contact)
            {
                setPhone(data.location.contact.phone_number);
            }

            contentLoadedHandler(data.location);
        });
    }

    async function getLeasingCompanies() {
        call(apiUri + 'leasing_companies', (resultData) => {
            setLeasingCompanyList(resultData.leasing_companies);
        });
    }

    //
    async function sendToServer(data) {
        callPost(apiUri + 'location_info', data, (resultData) => {
            Swal.fire({
                text: t('location.info.success'),
                icon: 'success',
                timer: 2000,
                target: document.getElementById('swal_container'),
            })
                .then(() => {
                        contentSavedHandler(1);
                    }
                );
        });
    }


    function submitHandler(event) {
        event.preventDefault();

        // Téléphone OK ?
        if (!validPhoneRef.current) {
            showWarning(t('location.info.warning_contact_phone'));
            return;
        }

        let data = {
            id: locationId,
            service_type_id: serviceTypeId,
            is_private: isPrivate,
            // private_location: formRef.current.elements['private_location'].checked,
            // private_location_share_code: formRef.current.elements['private_location_share_code'].value,
            // mobile_location: formRef.current.elements['mobile_location'].checked,
            // mobile_location_distance: formRef.current.elements['mobile_location_distance'].value,
            pay_by_invoice_type: payByInvoiceType,
            pay_by_invoice_email: payByInvoiceEmail,
            support_delay: formRef.current.elements['supportDelay'].value,
            supercar_support: supercarSupport,
            contact: {
                id: contactId,
                first_name: formRef.current.elements['contact_firstname'].value,
                last_name: formRef.current.elements['contact_lastname'].value,
                phone: phoneRef.current,
                email: formRef.current.elements['contact_email'].value
            },
        }

        if (hasLeasing && selectedLeasingCompanies.length > 0) {
            data.selected_leasing_companies = selectedLeasingCompanies;
        }
        data.leasing_invoice_email = leasingInvoiceEmail;

        sendToServer(data);
    }

    // At loading
    function getOptionExists(data, optionTypeId) {
        let option = data.options.find(option => option.location_option_type_id == optionTypeId);
        let exists = (option != null);
        return (exists);
    }

    // At render
    function getOptionValue(optionTypeId) {
        let option = locationData.options.find(option => option.location_option_type_id == optionTypeId);
        if (option != null) {
            return option.option_value;
        }
        return null;
    }

    return (
        <Container
            maxWidth={false}
        >
            {locationData != null &&
            <>
                <PictureUpload
                    picture_uri={locationData.picture}
                    default_picture={defaultImage}
                    api_uri="location_upload_picture"
                    model_id={locationData.id}
                />
                <form className="form-inline" onSubmit={submitHandler} ref={formRef}>
                    <Grid container justifyContent="center">
                        <Grid item xs={3} sx={{p: '16px'}}>
                            <Box display="flex" justifyContent="center"
                                 sx={{border: 2, borderRadius: '8px', borderColor: 'orange', p: '8px'}}>
                                {t('location.info.contact')}
                            </Box>
                            <TextField
                                margin="normal"
                                fullWidth
                                id="contact_firstname"
                                label={t('location.info.contact_firstname')}
                                defaultValue={locationData.contact ? locationData.contact.first_name : ''}
                                required
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                id="contact_lastname"
                                label={t('location.info.contact_lastname')}
                                defaultValue={locationData.contact ? locationData.contact.last_name : ''}
                                required
                            />
                            {/*<TextField*/}
                            {/*    margin="normal"*/}
                            {/*    fullWidth*/}
                            {/*    id="contact_phone"*/}
                            {/*    label={t('location.info.contact_phone')}*/}
                            {/*    defaultValue={locationData.contact ? locationData.contact.phone_number : ''}*/}
                            {/*    required*/}
                            {/*/>*/}
                            <KucPhoneTextField
                                variant={'outlined'}
                                margin="normal"
                                fullWidth
                                id="contact_phone"
                                label={t('location.info.contact_phone')}
                                value={phone}
                                onChange={(value, isValid) => {
                                    console.log("ONCHANGE", value, isValid);
                                    setPhone(value);
                                    setValidPhone(isValid);
                                }}
                                required
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                id="contact_email"
                                label={t('location.info.contact_email')}
                                defaultValue={locationData.contact ? locationData.contact.mail : ''}
                                required
                            />
                        </Grid>

                        {/*<Grid item xs={4} sx={{p: '16px'}}>*/}
                        {/*    <Box display="flex" justifyContent="center"*/}
                        {/*         sx={{border: 2, borderRadius: '8px', borderColor: 'orange', p: '8px'}}>*/}
                        {/*        {t('location.info.location_type')}*/}
                        {/*    </Box>*/}

                        {/*    <Switch*/}
                        {/*        id="private_location"*/}
                        {/*        checked={privateLocation}*/}
                        {/*        // defaultChecked={getOptionExists(1)}*/}
                        {/*        onChange={function (e) {*/}
                        {/*            setPrivateLocation(e.target.checked)*/}
                        {/*        }}/> {t('location.info.private_location')}*/}
                        {/*    <TextField*/}
                        {/*        margin="normal"*/}
                        {/*        fullWidth*/}
                        {/*        id="private_location_share_code"*/}
                        {/*        label={t('location.info.private_location_share_code')}*/}
                        {/*        defaultValue={getOptionValue(3)}*/}
                        {/*        disabled={!privateLocation}*/}
                        {/*    />*/}

                        {/*    <Switch*/}
                        {/*        id="mobile_location"*/}
                        {/*        checked={mobileLocation}*/}
                        {/*        onChange={function (e) {*/}
                        {/*            setMobileLocation(e.target.checked)*/}
                        {/*        }}/> {t('location.info.mobile_location')}*/}
                        {/*    <TextField*/}
                        {/*        margin="normal"*/}
                        {/*        fullWidth*/}
                        {/*        id="mobile_location_distance"*/}
                        {/*        label={t('location.info.mobile_location_distance')}*/}
                        {/*        disabled={!mobileLocation}*/}
                        {/*    />*/}
                        {/*</Grid>*/}

                        <Grid item xs={3} sx={{p: '16px'}}>
                            <Box display="flex" justifyContent="center"
                                 sx={{border: 2, borderRadius: '8px', borderColor: 'orange', p: '8px'}}>
                                {t('location.info.working_label')}
                            </Box>
                            <Stack>
                                <FormControl
                                    required
                                    sx={{
                                        mt: '20px'
                                    }}>
                                    <InputLabel>{t('location.services.service_type')}</InputLabel>
                                    <Select
                                        id="serviceTypeId"
                                        name="serviceTypeId"
                                        label={t('location.services.service_type')}
                                        value={serviceTypeId}
                                        disabled={isEditMode}
                                        onChange={
                                            e => {
                                                setServiceTypeId(e.target.value);
                                            }
                                        }
                                    >
                                        <MenuItem value="1">{t('location.services.service_carwash')}</MenuItem>
                                        <MenuItem value="2">{t('location.services.service_tires')}</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                sx={{'& .MuiSvgIcon-root': {fontSize: 60}}}
                                                checked={isPrivate}
                                                onChange={(e) => setIsPrivate(e.currentTarget.checked)}
                                            />
                                        }
                                        label={t('location.info.private_location')}
                                    />
                                </FormGroup>
                                <FormControl sx={{
                                    mt: '20px'
                                }}>
                                    <InputLabel>{t('location.services.currency')}</InputLabel>
                                    <Select
                                        label={t('location.services.currency')}
                                        id="currency"
                                        disabled={true}
                                        value={currency}
                                        onChange={
                                            e => {
                                                setCurrency(e.target.value);
                                            }
                                        }
                                    >
                                        <MenuItem value="CHF">{t('location.services.currency_chf')}</MenuItem>
                                        {/*<MenuItem value="EUR">{t('location.services.currency_eur')}</MenuItem>*/}
                                    </Select>
                                </FormControl>
                                <FormControl sx={{
                                    mt: '20px'
                                }}>
                                    <InputLabel>{t('location.info.support_delay_label')}</InputLabel>
                                    <Select
                                        id="supportDelay"
                                        name="supportDelay"
                                        label={t('location.info.support_delay_label')}
                                        defaultValue={locationData.support_delay == null ? 0 : locationData.support_delay}
                                    >
                                        <MenuItem value="0">{t('location.info.support_delay_now')}</MenuItem>
                                        <MenuItem value="24">24H</MenuItem>
                                        <MenuItem value="48">48H</MenuItem>
                                        <MenuItem value="72">72H</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                sx={{'& .MuiSvgIcon-root': {fontSize: 60}}}
                                                checked={supercarSupport}
                                                onChange={(e) => setSupercarSupport(e.currentTarget.checked)}
                                            />
                                        }
                                        label={t('location.info.supercar_support')}
                                    />
                                </FormGroup>
                            </Stack>

                        </Grid>

                        <Grid item xs={3} sx={{p: '16px'}}>
                            <Box display="flex" justifyContent="center"
                                 sx={{border: 2, borderRadius: '8px', borderColor: 'orange', p: '8px'}}>
                                {t('location.info.payment_methods_label')}
                            </Box>

                            <Stack>
                                <Grid container>
                                    <Grid container item xs={2} alignItems="center" justifyContent="center">
                                        <Checkbox
                                            disabled={isEditMode && loadedPayByInvoiceType != null}
                                            checked={payByInvoiceType != null}
                                            onChange={(e) => {
                                                if (payByInvoiceType == null) {
                                                    setPayByInvoiceType(PayByInvoiceType.KEEP_UP_CAR)
                                                } else {
                                                    setPayByInvoiceType(null);
                                                }
                                            }
                                            }
                                            sx={{'& .MuiSvgIcon-root': {fontSize: 60}}}
                                        />
                                    </Grid>
                                    <Grid item
                                          xs={10}><Typography>{t('location.info.payment_invoice_choice')}</Typography></Grid>
                                </Grid>
                                {payByInvoiceType != null &&
                                <Stack>
                                    <Grid container>
                                        <Grid container item xs={4}
                                              justifyContent="center"><Typography>{t('location.info.payment_invoice_kuc')}</Typography></Grid>
                                        <Grid container item xs={4} justifyContent="center">
                                            <Switch
                                                disabled={isEditMode && loadedPayByInvoiceType != null}
                                                checked={payByInvoiceType == PayByInvoiceType.PROVIDER}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setPayByInvoiceType(PayByInvoiceType.PROVIDER);
                                                    } else {
                                                        setPayByInvoiceType(PayByInvoiceType.KEEP_UP_CAR);
                                                    }
                                                }}
                                                sx={{'&.MuiSvgIcon-root': {fontSize: 60}}}
                                            />
                                        </Grid>
                                        <Grid container item xs={4}
                                              justifyContent="center"><Typography>{t('location.info.payment_invoice_provider')}</Typography></Grid>
                                    </Grid>
                                    {payByInvoiceType == PayByInvoiceType.PROVIDER &&
                                    <Grid container justifyContent="center">
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            label={t('location.info.payment_invoice_provider_email')}
                                            value={payByInvoiceEmail}
                                            onChange={(e) => setPayByInvoiceEmail(e.target.value)}
                                        />
                                    </Grid>
                                    }
                                </Stack>
                                }
                            </Stack>
                        </Grid>
                        <Grid item xs={3} sx={{p: '16px'}}>
                            <Box display="flex" justifyContent="center"
                                 sx={{border: 2, borderRadius: '8px', borderColor: 'orange', p: '8px'}}>
                                {t('location.info.leasing_label')}
                            </Box>

                            <Stack>
                                <Grid container>
                                    <Grid container item xs={2} alignItems="center" justifyContent="center">
                                        <Checkbox
                                            checked={hasLeasing}
                                            onChange={(e) => {
                                                setHasLeasing(e.currentTarget.checked)
                                            }
                                            }
                                            sx={{'& .MuiSvgIcon-root': {fontSize: 60}}}
                                        />
                                    </Grid>
                                    <Grid
                                        style={{
                                            // 'backgroundColor': '#FF0000',
                                            'display': 'flex'
                                        }}
                                        item
                                        alignItems="center">
                                        <Typography>{t('location.info.leasing_question')}</Typography></Grid>
                                </Grid>
                                {hasLeasing &&
                                <>
                                    <TextField
                                        // type="email"
                                        margin="normal"
                                        fullWidth
                                        label={t('location.info.leasing_invoice_email')}
                                        value={leasingInvoiceEmail}
                                        onChange={(e) => {
                                            setLeasingInvoiceEmail(e.currentTarget.value);
                                        }
                                        }
                                        required
                                    />
                                    <Typography>{t('location.info.leasing_companies_label')}</Typography>
                                    <FormGroup sx={{
                                        pl: '20px'
                                    }}>
                                        {
                                            leasingCompanyList != null && leasingCompanyList.map((leasingCompany, i) => {
                                                let selected = selectedLeasingCompanies.indexOf(leasingCompany.id) != -1;
                                                return <FormControlLabel
                                                    key={leasingCompany.id}
                                                    value={leasingCompany.id}
                                                    control={<Checkbox
                                                        checked={selected}
                                                        onChange={(e) => {
                                                            if (e.currentTarget.checked) {
                                                                selectedLeasingCompanies.push(leasingCompany.id);
                                                            } else {
                                                                selectedLeasingCompanies.splice(selectedLeasingCompanies.indexOf(leasingCompany.id), 1);
                                                            }
                                                            setSelectedLeasingCompanies(FormatUtils.cloneData(selectedLeasingCompanies));
                                                        }}
                                                    />}
                                                    label={leasingCompany.label}
                                                />
                                            })
                                        }
                                    </FormGroup>
                                </>
                                }
                            </Stack>
                        </Grid>
                    </Grid>
                </form>
            </>}
        </Container>
    )
};

export default forwardRef(LocationInfo);