import React from "react";
import {Grid, Typography} from "@mui/material";

export default function TextWithBr({
                                       text,
                                       textAlign,
                                       useTypographyTag = false,
                                       fontSize = null
                                   }) {
    return (<Grid textAlign={textAlign} style={{
        // backgroundColor: '#FF0000'
    }}>
        {/*<Typography>{text}</Typography>*/}
        {text.split('\n').map((item, key) => {
            if (useTypographyTag) {
                return <Typography fontSize={fontSize} key={key}>{item}</Typography>
            } else {
                return <React.Fragment key={key}>{item}<br/></React.Fragment>
            }
        })}
    </Grid>)
}