import {
    Button,
    Container,
    Grid,
    TextField,
    Typography
} from "@mui/material";

import React, {useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import {Link, useOutletContext} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Swal from 'sweetalert2'
import logo from "../assets/logo-header.png";
import useFetch from "../hooks/useFetch";

function ResetPassword(props) {
    const classes = useStyles();

    const [apiUri] = useOutletContext();
    const {t} = useTranslation('common');

    const formRef = React.useRef();

    const {callWithParams, isSending} = useFetch();

    const sendEmailSubmitHandler = async e => {
        e.preventDefault();

        if (!formRef.current.reportValidity()) {
            return;
        }
        let email = formRef.current.elements['email'].value;

        let data = {
            email: email
        }
        sendEmail(data);
    }

    async function sendEmail(data) {
        callWithParams({
            url: apiUri + 'password_reset',
            method: 'POST',
            body: data,
            jsonBody: true,
            withAuth: false,
            callback: (resultData) => {
                Swal.fire('',
                    t('reset_password.' + resultData.message),
                    'success', {timer: 2000});
            },
            failCallback: (failResultData) => {
                Swal.fire(t('common.error_title'),
                    t('reset_password.' + failResultData.message),
                    "error");
            }
        })
    }

    return <Grid container
                 alignItems="center"
                 justifyContent="center">
        <Container maxWidth='sm' className={classes.loginContainer} sx={{borderRadius: '16px'}} disableGutters>
            <Container>
                <img src={logo} className={classes.logo}/>
            </Container>
            <Container>
                <Typography align='center' variant='subtitle1'>
                    {t('reset_password.title')}
                </Typography>
                <div>
                    <form className={classes.form} noValidate onSubmit={sendEmailSubmitHandler}
                          ref={formRef}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            name="email"
                            label={t('reset_password.email')}
                        />
                        <Button
                            onClick={sendEmailSubmitHandler}
                            fullWidth
                            variant="contained"
                            color="yellow"
                            className={classes.loginBtn}
                            sx={{mt: '15px', mb: '20px'}}
                            disabled={isSending}
                        >
                            {t('reset_password.submit_btn')}
                        </Button>
                        <Typography>
                            {t('register.login_remind')}
                            <Link to="/login" style={{
                                color: 'white'
                            }}>{t('register.login_remind_link')}</Link>
                            {" " + t('common.or') + " "}
                            <Link to="/register" style={{
                                color: 'white'
                            }}>{t('register.remind_register')}</Link>

                        </Typography>
                    </form>
                </div>
            </Container>
        </Container>
    </Grid>
}

export default ResetPassword;

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        // backgroundColor: '#49545d',  // Gris clair 1
        background: 'linear-gradient(#49545D, #1C2326)'
    },
    input: {
        color: '#49545d'  // gris clair 2
    },
    loginContainer: {
        backgroundColor: '#292e32'
    },
    registerContainer: {
        backgroundColor: '#1d2124', // Gris foncé 1
        borderTop: 'solid #373B3E',
        paddingTop: '20px',
        paddingBottom: '20px'
    },
    logo: {
        width: '90%',
        margin: '20px'
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    loginBtn: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: '#fbc70f',
        color: 'black',
    },
    white: {
        color: 'white'
    },

    centerText: {
        alignItems: "center",
        display: "flex"
    }
}));