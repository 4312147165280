import BrandedHeaderFooter from "../features/branded/BrandedHeaderFooter";
import logo from "../assets/logo-header.png";
import {Button, Container, Grid, Switch, TextField, Typography} from "@mui/material";
import {AccountType} from "../constants/AccountType";
import {KucPhoneTextField} from "./common/KucPhoneTextField";
import {Link, useOutletContext} from "react-router-dom";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import useFetch from "../hooks/useFetch";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export default function EditProfile({
                                        userData,
                                        successHandler,
                                    }) {

    const [firstName, setFirstName] = useState(userData.first_name);
    const [lastName, setLastName] = useState(userData.last_name);
    const [company, setCompany] = useState(userData.provider?.company_name); // TODO
    const [phone, setPhone] = useState(userData.account_type_id == AccountType.CUSTOMER ? userData.phone : userData.provider.phone_number);
    const [validPhone, setValidPhone] = useState();

    const formRef = React.useRef();

    const {t, i18n} = useTranslation('common');
    const [apiUri] = useOutletContext();
    const {callPostForm, isSending} = useFetch();
    const MySwal = withReactContent(Swal);

    function submitHandler(e) {
        e.preventDefault();
        if (!formRef.current.reportValidity()) {
            return;
        }

        let data = new FormData();
        data.append('first_name', firstName);
        data.append('last_name', lastName);
        data.append('company', company);
        data.append('phone', phone);

        callPostForm(apiUri + 'edit_profile', data, (resultData) => {
            MySwal.fire({
                text: t('profile.success_edit_profile'),
                icon: 'success',
                timer: 2000,
                target: document.getElementById('swal_container'),
            })
                .then(() => {
                        successHandler();
                    }
                );
        });
    }

    return <Container maxWidth="sm">
        <form noValidate onSubmit={submitHandler} ref={formRef}>
            <Grid container alignItems="center"
                  justifyContent="center">
                {userData.account_type_id == AccountType.CUSTOMER ?
                    <>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="firstname"
                            name="firstname"
                            label={t('register.firstname')}
                            value={firstName}
                            onChange={e => setFirstName(e.target.value)}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="lastname"
                            name="lastname"
                            label={t('register.lastname')}
                            value={lastName}
                            onChange={e => setLastName(e.target.value)}
                        />
                    </>
                    : <>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="company"
                            name="company"
                            label={t('register.company')}
                            value={company}
                            onChange={e => setCompany(e.target.value)}
                        />
                    </>
                }

                <KucPhoneTextField
                    variant={'outlined'}
                    margin="normal"
                    required={userData.account_type_id == AccountType.PROVIDER}
                    fullWidth
                    id="phone"
                    name="phone"
                    label={t('register.phone')}
                    value={phone}
                    onChange={(value, isValid) => {
                        setPhone(value);
                        setValidPhone(isValid);
                    }}
                />

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="yellow"
                    sx={{mt: '15px', mb: '20px'}}
                    disabled={isSending}
                >
                    {t('profile.edit_profile')}
                </Button>
            </Grid>
        </form>
    </Container>
}
