import {useTranslation} from "react-i18next";
import React, {useContext} from "react";
import {Box, Grid, Typography} from "@mui/material";
import MobileContext from "../contexts/mobileContext";

function ServiceLocationCalendarPeriod({calendarDayData, type, selected, clicked}) {
    const {t} = useTranslation('common');

    let value = getValue(calendarDayData, type);

    const mobileContext = useContext(MobileContext);
    const isMobile = mobileContext.isMobile;

    function isEnabled() {

        if (calendarDayData.is_full) {
            return false;
        }

        if (type == 'am' && (calendarDayData.am_closed == '1' || calendarDayData.amFull == '1' || calendarDayData.amAvailable == '0')) {
            return false;
        }
        if (type == 'pm' && (calendarDayData.pm_closed == '1' || calendarDayData.pmFull == '1' || calendarDayData.pmAvailable == '0')) {
            return false;
        }
        return true;
    }

    function getValue(calendarDayData, type) {
        let result;
        if (calendarDayData.is_full) {
            return t('service.step2.schedule_period_full');
        }
        if (type == 'am') {
            if (calendarDayData.am_closed == '1') {
                return t('service.step2.schedule_period_closed')
            }
            if (calendarDayData.amFull == '1') {
                return t('service.step2.schedule_period_full')
            }
            result = calendarDayData.am_start + ' - ' + calendarDayData.am_end;
        } else {
            if (calendarDayData.pm_closed == '1') {
                return t('service.step2.schedule_period_closed')
            }
            if (calendarDayData.pmFull == '1') {
                return t('service.step2.schedule_period_full')
            }
            result = calendarDayData.pm_start + ' - ' + calendarDayData.pm_end;
        }
        return result;
    }

    let backgroundColor = selected ? '#fbc70f' : (isEnabled() ? 'white' : 'grey');
    let color = selected ? 'black' : 'black';

    return (
        <Box
            container
            height={isMobile ? '6vh' : "50px"}
            onClick={(e) => {
                if (!isEnabled()) {
                    return;
                }
                clicked(calendarDayData, type)
            }}
            sx={{
                textAlign: 'center',
                color: color,
                border: 0,
                // borderColor: {borderColor},
                borderRadius: '16px',
                backgroundColor: {backgroundColor},
                cursor: isEnabled() ? 'pointer' : ''
            }}>
            <Grid
                container
                height="100%"
                alignContent="center"
                justifyContent="center">
                <Typography fontSize={isMobile ? '0.8em' : null}>{value}</Typography>
            </Grid>
        </Box>

    );
}

export default ServiceLocationCalendarPeriod;