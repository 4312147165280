import {Typography} from "@mui/material";
import {Grid} from "@mui/material";
import React from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function BrandedKucFooter({}) {

    const {t} = useTranslation('common');

    return <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{
            height: '50px',
            backgroundColor: 'black',
            marginTop: 'auto',
        }}>
        <Typography><Link
            style={{
                outline: 'none',
                textDecoration: 'none',
                color: 'white'
            }}
            to="/lgs">{t('branded.kuc_footer')}
        </Link></Typography>
    </Grid>
}