import React, {useContext, useEffect, useState} from "react";
import {GoogleMap, InfoBox, LoadScript, Marker} from '@react-google-maps/api';
import {Box, Button, Grid, Stack, Typography} from "@mui/material";
import defaultImage from "../assets/menu/location/emplacement-b-g.png";
import carwashGrey from "../assets/googlemap/Car-Wash-B-G.svg";
import carwashYellow from "../assets/googlemap/Car-Wash-B-j.svg";
import tireGrey from "../assets/googlemap/Pneu-B-G.svg";
import tireYellow from "../assets/googlemap/Pneu-B-J.png.svg";

import CloseIcon from '@mui/icons-material/Close';
import MobileContext from "../contexts/mobileContext";
import {ServiceType} from "../constants/ServiceType";
import FormatUtils from "../utils/FormatUtils";

// Choix d'un service => carte
function ServiceMap({
                        locationList,
                        selectedLocation,
                        locationClickedHandler,
                        userPosition,
                        locationCloseClickedHandler
                    }) {
    // TODO A mettre à part
    const apiKey = "AIzaSyD4oC9kowowAPljAxy6zVoyPA199sPrYSk";

    const [center, setCenter] = useState(null);
    const [googleMap, setGoogleMap] = useState();

    const mobileContext = useContext(MobileContext);
    const isMobile = mobileContext.isMobile;

    const showWindow = true;   // Config : affichage de la mini fenetre inscrustée à la carte

    const containerStyle = {
        width: '100%',
        height: isMobile ? '25vh' : '66vh'
    };

    useEffect(() => {
        console.log('selectedLocation : ', selectedLocation);
        if (selectedLocation != null) {
            setCenter(getGooglePoint(selectedLocation));
        }
    }, [selectedLocation])

    useEffect(() => {
        console.log('center : ', center);
    }, [center]);

    useEffect(() => {
        console.log('userPosition : ', userPosition);
        if (selectedLocation == null) {
            setCenter(userPosition);
        }
    }, [userPosition]);

    // Ajust zoom to markers
    // https://codesandbox.io/s/google-maps-zoom-map-to-fit-markers-9tg4n?file=/src/index.js:504-510
    useEffect(() => {
        if (selectedLocation == null && locationList != null && locationList.length > 0 && googleMap != null) {
            // console.log("SS", googleMap);
            let bounds = new window.google.maps.LatLngBounds();

            locationList.map((location, i) => {
                const loc = getGooglePoint(location);
                bounds.extend(loc);
            });
            googleMap.fitBounds(bounds);
        }
    }, [locationList, googleMap])


    // let gMap = null;

    function getGooglePoint(location) {
        return {
            lat: parseFloat(location.latitude),
            lng: parseFloat(location.longitude)
        }
    }

    function getIconByLocation(location) {
        switch (location.service_type_id) {
            case ServiceType.TIRES:
                if (FormatUtils.locationIsSame(location, selectedLocation)) {
                    return tireYellow;
                } else {
                    return tireGrey;
                }
                break;
            case ServiceType.CARWASH:
                if (FormatUtils.locationIsSame(location, selectedLocation)) {
                    return carwashYellow;
                } else {
                    return carwashGrey;
                }
                break;
        }
        return null;
    }


    // TODO Ne marche plus si je fais un zoom / dezoom puis un drag
    function boundsChangedHandler() {
        // console.log("boundsChangedHandler");

        return;

        // let bounds = gMap.getBounds();
        // let ne = bounds.getNorthEast();
        // let sw = bounds.getSouthWest();

        // console.log(ne.toString(), sw.toString());

        /*
        {
            const bounds = new window.google.maps.LatLngBounds();
            map.fitBounds(bounds);
        }*/
    }

    return (
        <LoadScript
            googleMapsApiKey={apiKey}
        >
            {center != null &&
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={10}
                onLoad={map => {
                    // console.log("map loaded !");
                    setGoogleMap(map);
                }}
                onUnmount={map => {
                    // do your stuff before map is unmounted
                }}
                onDragEnd={() => {
                    boundsChangedHandler();
                }}
                options={{
                    maxZoom: 18,
                    fullscreenControl: false,
                    streetViewControl: false,
                }}
            >

                {googleMap &&   // Si googlemap est dispo, chargé etc..
                <>
                    <Marker
                        position={userPosition}
                        icon={{
                            url: 'https://mt.google.com/vt/icon?psize=30&font=fonts/arialuni_t.ttf&color=ff304C13&name=icons/spotlight/spotlight-waypoint-a.png'
                        }}
                    />

                    {
                        locationList != null && locationList.map((location, i) => {

                            // console.log(location.latitude, location.longitude)

                            let position = getGooglePoint(location);
                            return (
                                <Marker
                                    key={i}
                                    position={position}
                                    zIndex={FormatUtils.locationIsSame(location, selectedLocation) ? 100 : 1}
                                    icon={{
                                        // url: 'https://maps.google.com/mapfiles/kml/paddle/red-stars.png'
                                        url: getIconByLocation(location),
                                        scaledSize: new window.google.maps.Size(50, 50)
                                        // Ma position : https://mt.google.com/vt/icon/name=icons/spotlight/directions_destination_measle_8x.png&scale=1
                                        // Cliqué : https://mt.googleapis.com/vt/icon/name=icons/spotlight/spotlight-poi.png&scale=1
                                    }}
                                    onClick={
                                        (e) => locationClickedHandler(location)
                                    }
                                />
                            );
                        })
                    }

                    {
                        selectedLocation && !isMobile && showWindow &&
                        <InfoBox

                            position={getGooglePoint(selectedLocation)}
                            options={{
                                closeBoxURL: '',
                                alignBottom: true,
                                pixelOffset: new window.google.maps.Size(-100, -50)
                            }}
                        >
                            <Box
                                width="200px"
                                style={{
                                    background: "#000000",
                                    border: "solid 1px orange",
                                    borderRadius: "5px"
                                }}>
                                <>
                                    <Grid m={0} p={0} container
                                          justifyContent="right">
                                        <Button size="small"
                                                style={{
                                                    maxWidth: '25px',
                                                    minWidth: '25px'
                                                }}
                                                onClick={
                                                    () => {
                                                        locationCloseClickedHandler(selectedLocation);
                                                    }
                                                }>
                                            <CloseIcon fontSize="small"/>
                                        </Button>
                                    </Grid>
                                    <Stack p={1}>
                                        <img style={{
                                            height: '60px',
                                            width: 'auto',
                                            objectFit: 'contain',
                                        }} src={
                                            selectedLocation.picture ? selectedLocation.picture : defaultImage
                                        }/>
                                        <Typography
                                            style={{
                                                color: "#fbc70f",
                                                fontSize: "18px",
                                                fontWeight: "bold"
                                            }}>{selectedLocation.name.toUpperCase()}</Typography>
                                        <Typography style={{
                                            color: "white",
                                            fontSize: "15px",
                                        }}>
                                            {selectedLocation.dayScheduleHuman}<br/>
                                            {selectedLocation.address} {selectedLocation.city}
                                        </Typography>
                                    </Stack>
                                </>
                            </Box>
                        </InfoBox>
                    }
                </>
                }
            </GoogleMap>
            }
        </LoadScript>
    )
};

export default ServiceMap;