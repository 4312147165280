import {useOutletContext} from "react-router-dom";
import {useTranslation} from "react-i18next";
import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {Button, Container, FormControlLabel, FormGroup, Grid, Switch, Typography} from "@mui/material";
import Swal from "sweetalert2";
import LocationScheduleRow from "./LocationScheduleRow";
import LocationCalendarDay from "./LocationCalendarDay";

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import useFetch from "../hooks/useFetch";
import FormatUtils from "../utils/FormatUtils";
import moment from "moment";

function LocationCalendar({
                              locationId,
                              schedulesData,
                              saveSuccessHandler,
                              needSaveHandler
                          }, ref) {
    const [apiUri] = useOutletContext();
    const {t} = useTranslation('common');
    const [from, setFrom] = useState(null);
    const [onlyExceptions, setOnlyExceptions] = useState(false);

    const [calendarData, setCalendarData] = useState(null);
    const [temporaryClosed, setTemporaryClosed] = useState(null);

    const [loaded, setLoaded] = useState(false);

    const {call, callPost} = useFetch();

    useImperativeHandle(ref, () => ({
        async save() {
            return saveHandler();
        }
    }));

    useEffect(() => {
        getFromServer(locationId);
    }, [])

    function dataChangedHandler() {
        setCalendarData(JSON.parse(JSON.stringify(calendarData)));
        needSaveHandler();
    }

    function previousWeekClickedHandler(e) {
        saveHandler(true)
            .then((saveSuccess) => {
                // saveSuccess est soit false ( erreur de cohérence ) , soit undefined car parti dans de l'async
                if (saveSuccess !== false) {
                    getFromServer(locationId, FormatUtils.getSqlDateWithDayDiff(from, -7));
                }
            });
    }

    function nextWeekClickedHandler(e) {
        saveHandler(true)
            .then((saveSuccess) => {
                if (saveSuccess !== false) {
                    getFromServer(locationId, FormatUtils.getSqlDateWithDayDiff(from, 7));
                }
            });
    }

    function exceptionOnlyClickedHandler(e) {
        let checked = e.target.checked;
        saveHandler(true)
            .then(() => {
                getFromServer(locationId, null, null, checked);
            })
    }

    function getScheduleDataForDay(calendarDayData) {

        let date = moment(calendarDayData.date);
        let dayOfWeek = (date.day() + 6) % 7;  // 0 => Monday , 6=> Sunday
        return (JSON.parse(JSON.stringify(schedulesData[dayOfWeek])));
    }

    async function getFromServer(locationId, from = null, to = null, onlyExceptions = false) {
        let url = apiUri + 'location_calendar/' + locationId;

        // From / To must be set
        if (from == null) {
            from = moment().format('YYYY-MM-DD');
        }

        if (to == null) {
            to = FormatUtils.getSqlDateWithDayDiff(from, 7);
        }

        if (from != null) {
            url += '/' + from;
            if (to != null) {
                url += '/' + to;
            }
        }

        if (onlyExceptions) {
            url += "?onlyExceptions=1";
        }

        call(url, (data) => {
            if (data.onlyExceptions == '1') {
                setOnlyExceptions(true);
                setFrom(null);
            } else {
                setOnlyExceptions(false);
                setFrom(data.calendar[0].date);
            }
            setTemporaryClosed(data.temporary_closed == '1');
            setCalendarData(data.calendar);
            setLoaded(true);
        });
    }

    async function saveHandler(quiet = false) {
        let ok = true;
        calendarData.forEach(dayData => {
            if (
                'schedulesExceptionId' in dayData &&
                dayData.schedulesExceptionId.toString().charAt(0) !== 'D' &&
                ((dayData.am_closed == 0 && FormatUtils.isNotSet(dayData.am_capacity)) || (dayData.pm_closed == 0 && FormatUtils.isNotSet(dayData.pm_capacity)))) {
                ok = false;
            }
        });

        if (!ok) {
            Swal.fire({
                text: t('location.calendar.error_null_capacity'),
                icon: 'warning',
                target: document.getElementById('swal_container'),
            });
            return false;
        }

        let data = {
            "location_id": locationId,
            "temporary_closed": temporaryClosed ? '1' : '0',
            "calendar": calendarData
        };

        return sendToServer(data, quiet).then((result) => result);
    }

    async function sendToServer(data, quiet = false) {
        callPost(apiUri + 'location_calendar', data, (resultData) => {
            if (!quiet) {
                Swal.fire({
                    text: t('location.calendar.save_success'),
                    icon: 'success',
                    timer: 2000,
                    target: document.getElementById('swal_container'),
                })
                    .then(() => saveSuccessHandler())
            }
            // return true;
        });
    }

    let isCurrentWeek = false;
    if (calendarData) {
        calendarData.forEach(calendarDayData => {
            if ('isToday' in calendarDayData && calendarDayData.isToday == '1') {
                isCurrentWeek = true;
            }
        });
    }

    return (<Container maxWidth={false}>
        <Grid container justifyContent="center" sx={{"mt": 2}}>
            <Typography>{t('location.calendar.label').toUpperCase()}</Typography>
        </Grid>
        {loaded &&
        <>
            <Grid container justifyContent="center">
                <FormGroup><FormControlLabel control={<Switch
                    checked={onlyExceptions}
                    onChange={(e) => exceptionOnlyClickedHandler(e)}/>}
                                             label={t('location.calendar.show_only_exceptions_label')}/></FormGroup>
            </Grid>

            {calendarData &&
            (
                <>
                    <Grid container>
                        <Grid item xs={1 / 2} container alignContent="center">
                            {!onlyExceptions && !isCurrentWeek &&
                            <Button color="yellow" onClick={previousWeekClickedHandler}>
                                <NavigateBeforeIcon sx={
                                    {
                                        color: 'orange',
                                        fontSize: 60,
                                    }}/>
                            </Button>
                            }
                        </Grid>
                        {calendarData.map((calendarDayData, index) => {
                            return <Grid item xs={11 / 7} key={index}>
                                <LocationCalendarDay calendarDayData={calendarDayData}
                                                     dataChangedHandler={dataChangedHandler}
                                                     scheduleData={getScheduleDataForDay(calendarDayData)}/>
                            </Grid>
                        })}
                        <Grid item xs={1 / 2} container alignContent="center">
                            {!onlyExceptions &&
                            <Button color="yellow" onClick={nextWeekClickedHandler}>
                                <NavigateNextIcon sx={
                                    {
                                        color: 'orange',
                                        fontSize: 60,
                                    }}/>
                            </Button>}
                        </Grid>
                    </Grid>
                </>)
            }

            <Grid container justifyContent="center" sx={
                {
                    backgroundColor: temporaryClosed ? 'orange' : ''
                }
            }>
                <FormGroup><FormControlLabel control={<Switch
                    checked={temporaryClosed}
                    onChange={function (e) {
                        setTemporaryClosed(e.target.checked);
                        needSaveHandler();
                    }}/>} label={t('location.calendar.temporary_close_label')}/></FormGroup>
            </Grid>
        </>}
    </Container>);
}

export default forwardRef(LocationCalendar);