import React, {useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import {useOutletContext} from "react-router-dom";
import Swal from 'sweetalert2'
import {useTranslation} from "react-i18next";

import btnEdit from "../assets/buttons/edit/write-white-yellow.svg";
import useFetch from "../hooks/useFetch";


export default function PictureUpload({
                                          height = '100px',
                                          width = '100px',
                                          picture_uri = null,  // Current picture
                                          model_id = null, // For post
                                          api_uri = null,  // Api endpoint for post
                                          default_picture,   // Default if none current picture url
                                          uploadDocumentVersion = false,
                                          uploadDocumentDoneHandler = null
                                      }) {
    const classes = useStyles();
    const [imageUrl, setImageUrl] = useState(null);
    const [apiUri] = useOutletContext();

    const {call} = useFetch();

    useEffect(() => {
        setImageUrl(picture_uri);
    }, [])

    const handleUploadClick = event => {
        var file = event.target.files[0];

        let data = new FormData();
        data.append('id', model_id);
        data.append("file", file);

        call(apiUri + api_uri, (resultData) => {
            if (uploadDocumentVersion) {
                uploadDocumentDoneHandler(resultData.message);
            } else {
                setImageUrl(resultData.picture);
            }
        }, 'POST', data);
    };

    return <>
        <input
            accept="image/*,.pdf"
            className={classes.uploadInput}
            id="contained-button-file"
            multiple
            type="file"
            onChange={handleUploadClick}
        />
        <label htmlFor="contained-button-file">
            {uploadDocumentVersion ?
                (<span style={{
                    paddingTop: '5',
                    cursor: 'pointer',
                }}>
                    {default_picture}
                </span>)
                :
                <>
                    <img src={imageUrl == null ? default_picture : imageUrl} style={{
                        height: height,
                        width: width
                    }} className={classes.profilePictureImg}/>
                    <img src={btnEdit} className={classes.editBtn}/>
                </>
            }
        </label>
    </>
}

const useStyles = makeStyles((theme) => ({
    profilePictureImg: {
        // height: '100px',
        // width: '100px',
        objectFit: 'contain',
        borderRadius: '5px',
        cursor: 'pointer'
    },
    editBtn: {
        height: '32px',
        cursor: 'pointer',
        marginLeft: '5px'
    },
    uploadInput: {
        display: "none"
    }
}));