import React from 'react';

import IconTire from "../assets/services/tires/choice_tire.png";
import IconWheel from "../assets/services/tires/choice_wheel.png";

export default class TireServiceStatic extends React.Component {
    static tireCategories = [
        {
            "id": 1,
            "label": '13"',
        },
        {
            "id": 2,
            "label": '14"',
        },
        {
            "id": 3,
            "label": '15"',
        },
        {
            "id": 4,
            "label": '16"',
        },
        {
            "id": 5,
            "label": '17"',
        },
        {
            "id": 6,
            "label": '18"',
        },
        {
            "id": 7,
            "label": '19"',
        },
        {
            "id": 8,
            "label": '20"',
        },
        {
            "id": 9,
            "label": '21"',
        },
        {
            "id": 10,
            "label": '22"',
        },
        {
            "id": 11,
            "label": '23"',
        }
    ];

    static tireOrWheel = [
        {
            "id": 1,
            "icon": IconTire
        },
        {
            "id": 2,
            "icon": IconWheel
        }
    ]


    static getCarCategoryById(id) {
        return TireServiceStatic.tireCategories.find(category => category.id == id);
    }

    static getTireOrWheelById(id) {
        return TireServiceStatic.tireOrWheel.find(category => category.id == id);
    }
}