import React, {useContext, useEffect, useState} from 'react';
import {
    Box, Button, Container, Grid
} from "@mui/material";
import {
    Link,
    Outlet,
    useNavigate,
    useOutletContext,
    useParams
} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Swal from 'sweetalert2'
import VehicleItem from "./VehicleItem";
import VehicleDocumentTypesService from "../services/VehicleDocumentTypesService";
import TireService from "../services/TireService";
import ServiceCardModal from "./ServiceCardModal";
import useFetch from "../hooks/useFetch";
import {VehicleSectionType} from "../constants/VehicleSectionType";
import MobileContext from "../contexts/mobileContext";

export default function VehicleIndex(props) {
    const {t} = useTranslation('common');
    const [apiUri] = useOutletContext();
    const [loaded, setLoaded] = useState(false);
    const [vehicleList, setVehicleList] = useState([]);
    const navigate = useNavigate();

    const [vehicleDocumentTypes, setVehicleDocumentTypes] = useState(null);
    const [tireCategories, setTireCategories] = useState(null);

    // Order
    const [cardOrder, setCardOrder] = useState(null);

    const [newOrderData, setNewOrderData] = useState(null);
    const [forceExpandVehicleId, setForceExpandVehicleId] = useState(null);
    const [forceExpandSection, setForceExpandSection] = useState(null);

    const mobileContext = useContext(MobileContext);
    const isMobile = mobileContext.isMobile;

    const {call} = useFetch();
    const params = useParams();

    useEffect(() => {
        props.setLabel('vehicle.title');
        vehicleIndexGet();
    }, [])

    useEffect(() => {
        if (vehicleList != null && params.vehicleId != null) {
            let vehicleId = parseInt(params.vehicleId);
            setForceExpandVehicleId(vehicleId);
            setForceExpandSection(VehicleSectionType.ORDER_DONE);
        }
    }, [vehicleList]);

    async function vehicleIndexGet() {
        call(apiUri + 'vehicle_index', (data) => {
            setLoaded(true);
            setVehicleList(data.vehicles);
        });
    }

    function vehicleUpdatedHandler() {
        navigate("/vehicle");
        vehicleIndexGet();
    }

    // orderData est la nouvelle commande
    function orderUpdatedHandler(newOrderData) {
        // console.log("ORDER UPDATED : " + newOrderData);
        setNewOrderData(newOrderData);
    }

    function stripeDoneHandler(vehicleId) {
        // console.log("stripeDoneHandler : " + vehicleId);
        // On va ouvrir le bon onglet de vehicle
        setForceExpandVehicleId(vehicleId);
    }

    function deleteVehicleHandler(e, id) {
        e.stopPropagation();
        Swal.fire({
            title: t('vehicle.delete_confirmation'),
            showCancelButton: true,
            confirmButtonColor: '#dd6b55',
            confirmButtonText: t('common.yes'),
            cancelButtonText: t('common.no'),
        }).then((result) => {
            if (result.isConfirmed) {
                deleteVehicle(id);
            }
        })
    }

    function showOrderCardHandler(order) {
        // Version sans router
        setCardOrder(order);

        // Version avec reactrouter
        // let path = '/vehicle/order_card/' + orderId;
        // navigate(path);
    }

    function closeOrderCardHandler() {
        setCardOrder(null);
    }

    function orderChangedHandler(changedOrder) {
        // To reload orders
        setNewOrderData(changedOrder);
    }

    function reloadHandler() {
        vehicleIndexGet();
    }

    async function deleteVehicle(id) {

        call(apiUri + 'vehicle', (resultData => {
            Swal.fire(t('vehicle.delete_success'), '', 'success', {timer: 2000})
                .then(() => {
                    vehicleUpdatedHandler();
                });
        }), 'DELETE', {id: id}, true);
    }

    return (
        <>
            <VehicleDocumentTypesService setDocumentTypes={setVehicleDocumentTypes}/>
            <TireService setTireCategories={setTireCategories}/>
            <Grid
                justifyContent="center"
                container
            >
                {!isMobile &&
                <Link to="/vehicle/add" style={{textDecoration: 'none'}}>
                    <Button
                        variant="contained"
                        color="yellow"
                        sx={{mt: '15px', mb: '20px', mr: '10px'}}
                    >
                        {t('vehicle.button_create_vehicle')}
                    </Button>
                </Link>
                }
                {
                    !isMobile && vehicleList.length > 0 &&
                    <Link to="/service_order" style={{textDecoration: 'none'}}>
                        <Button
                            variant="contained"
                            color="yellow"
                            sx={{mt: '15px', mb: '20px', ml: '10px'}}
                        >
                            {t('vehicle.button_order_service')}
                        </Button>
                    </Link>
                }
            </Grid>
            <Box width="100%" sx={{mt: 1}}/>
            {loaded &&
            <>
                {vehicleList.length == 0 ?
                    <Container style={{
                        display: 'flex',
                        textAlign: 'center'
                    }}>
                        <h3>
                            {t("vehicle.empty_list_message")}
                        </h3>
                    </Container>
                    :
                    <>
                        {
                            vehicleList.map((vehicle, i) => {
                                return (<VehicleItem
                                    key={i}
                                    vehicleData={vehicle}
                                    deleteVehicleHandler={deleteVehicleHandler}
                                    reloadHandler={reloadHandler}
                                    vehicleDocumentTypes={vehicleDocumentTypes}
                                    tireCategories={tireCategories}
                                    showOrderCardHandler={showOrderCardHandler}
                                    newOrderData={newOrderData}
                                    forceExpandWithVehicleId={forceExpandVehicleId}
                                    forceExpandSection={forceExpandSection}
                                />);
                            })
                        }
                    </>
                }


                {!isMobile &&
                <Grid justifyContent="center" container>
                    <Grid item xs={isMobile ? 6 : 12} container justifyContent="center">
                        <Link to="/vehicle/add" style={{textDecoration: 'none'}}>
                            <Button
                                variant="contained"
                                color="yellow"
                                sx={{
                                    mt: '15px',
                                    mb: '20px',
                                    mr: isMobile ? '5px' : null,
                                    height: isMobile ? '60px' : null
                                }}
                            >
                                {t('vehicle.button_create_vehicle')}
                            </Button>
                        </Link>
                    </Grid>
                    {
                        isMobile && vehicleList.length > 0 &&
                        <Grid item xs={6}>
                            <Link to="/service_order" style={{textDecoration: 'none'}}>
                                <Button
                                    variant="contained"
                                    color="yellow"
                                    sx={{
                                        mt: '15px',
                                        mb: '20px',
                                        ml: '5px',
                                        height: '60px'
                                    }}
                                >
                                    {t('vehicle.button_order_service')}
                                </Button>
                            </Link>
                        </Grid>
                    }
                </Grid>
                }
                {
                    cardOrder &&
                    <ServiceCardModal order={cardOrder}
                                      closeHandler={closeOrderCardHandler}
                                      cardType="customer"
                                      orderChangedHandler={orderChangedHandler}/>
                }

                <Outlet context={[apiUri, vehicleUpdatedHandler, orderUpdatedHandler, stripeDoneHandler]}/>
            </>
            }
        </>
    );
}