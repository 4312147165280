import {InputAdornment, TextField} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";

export default function PriceField({
                                       value,
                                       valueChangedHandler,
                                       currency = 'CHF'
                                   }) {
    const {t} = useTranslation('common');

    return (<TextField
        type="number"
        margin="normal"
        size="small"
        fullWidth
        id="label"
        label={t('common.price')}
        sx={{
            m: 0
        }}
        onChange={(e) => {
            let newPrice = parseFloat(e.target.value);
            if (newPrice < 0) {
                newPrice = 0;
            }
            if (isNaN(newPrice)) {
                newPrice = null;    // So it is compatible with isNotSet
            }
            valueChangedHandler(newPrice);
            // setPrice(newPrice);

        }}
        value={value || ""}

        InputProps={
            {
                endAdornment: currency != null ? <InputAdornment position="end">{currency}</InputAdornment> : null
            }
        }
        // error={FormatUtils.isNotSet(price)}
    />);
}