import {createSelector, createSlice} from '@reduxjs/toolkit'

var initialState = {};
try {
    // TODO Utilise le loadFromSessionStorage ?
    initialState = sessionStorage.getItem('session') ? JSON.parse(sessionStorage.getItem('session')) : {};
} catch (error) {
    console.log('getError', error)
}

export const locationGroupSlice = createSlice({
    name: 'locationGroup',
    initialState: initialState,
    reducers: {
        loadFromSessionStorage: (state) => {
            let newState = sessionStorage.getItem('session') ? JSON.parse(sessionStorage.getItem('session')) : {};
            return newState;
        },
        setLocationGroup: (state, action) => {
            let newState = {...state};
            newState.locationGroupData = action.payload;
            sessionStorage.setItem('session', JSON.stringify(newState));
            return newState;
        },
        exitBranded: (state) => {
            sessionStorage.removeItem('session');
            return loadFromSessionStorage();
        }
    }
})

const selfState = state => state;
export const getIsBranded = createSelector(
    [selfState],
    (state) => {
        return state.locationGroup.locationGroupData != null
    });

// Action creators are generated for each case reducer function
export const {setLocationGroup, exitBranded, loadFromSessionStorage} = locationGroupSlice.actions

export default locationGroupSlice.reducer