import React, {forwardRef, useEffect, useRef, useState} from "react";
import {
    Container, FormControl,
    Grid, InputLabel,
    MenuItem,
    Select
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Navigate, useOutletContext} from "react-router-dom";
import '../App.css';
import useFetch from "../hooks/useFetch";


// TODO Utiliser VehicleSelector ?
function ServiceStep1({
                          previousBtn,
                          nextBtn,
                          contentLoadedHandler,
                          setLabel,
                          validateVehicle,
                          vehicle
                      }, ref) {
    const [apiUri] = useOutletContext();
    const {t} = useTranslation('common');

    const [loaded, setLoaded] = useState(false);
    const [vehicleList, setVehicleList] = useState([]);
    const [localVehicle, setLocalVehicle] = useState(vehicle);

    const vehicleRef = useRef();
    vehicleRef.current = localVehicle;

    const {call} = useFetch();

    useEffect(() => {
        setLabel('service.step1.title');
        previousBtn.current.style.display = 'none';
        nextBtn.current.addEventListener('click', nextClickedHandler);
        vehicleIndexGet();
        return () => {
            if (nextBtn.current) {
                nextBtn.current.removeEventListener('click', nextClickedHandler);
            }
        }
    }, [])

    // TODO Meme que VehicleIndex
    async function vehicleIndexGet() {
        call(apiUri + 'vehicle_index', (resultData => {
            setLoaded(true);
            contentLoadedHandler();
            setVehicleList(resultData.vehicles);
        }));
    }

    function nextClickedHandler() {
        if (vehicleRef.current != null) {
            validateVehicle(vehicleRef.current);
        }
    }

    return (
        <Container>
            {loaded && <Grid container justifyContent="center" alignItems="center" minHeight="20vh">
                {vehicleList.length == 0 ?
                    <>
                        <h3>
                            {t("service.step1.no_vehicle")}
                        </h3>
                    </>
                    :
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">{t('service.step1.vehicle_label')}</InputLabel>
                        <Select
                            id="vehicle"
                            label={t('service.step1.vehicle_label')}
                            value={localVehicle ? localVehicle.id : ''}
                            onChange={
                                e => {
                                    // console.log(e.target.value);
                                    let vehicleId = e.target.value;
                                    let vehicle = vehicleList.find((elem => elem.id == vehicleId));
                                    setLocalVehicle(vehicle);
                                }
                            }
                        >
                            {
                                vehicleList.map((vehicle, i) => {
                                    return (<MenuItem key={i}
                                                      value={vehicle.id}>{vehicle.label} {vehicle.licence_plate}</MenuItem>);
                                })
                            }
                        </Select>
                    </FormControl>
                }
            </Grid>
            }
        </Container>
    )
};

export default forwardRef(ServiceStep1);