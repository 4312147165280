import {AccountType} from "../constants/AccountType";
import {Navigate} from "react-router-dom";
import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {getIsBranded} from "../features/branded/locationGroupSlice";

export default function RedirectToEntryPoint() {
    const accountType = parseInt(localStorage.getItem('accountType'));
    const isBranded = useSelector(getIsBranded);

    let redirect;

    useEffect(() => {
        console.log('RedirectToEntryPoint isBranded:', isBranded, accountType);
    }, []);

    if (accountType === AccountType.CUSTOMER) {
        if (isBranded) {
            redirect = <Navigate to='/service_order_b'/>;
        } else {
            redirect = <Navigate to='/vehicle'/>;
        }
    } else if (accountType === AccountType.PROVIDER) {
        redirect = <Navigate to='/dashboard'/>;
    } else {
        // On a meme pas le compte, le mec n'est pas loggé
        redirect = <Navigate to='/login'/>;
    }

    return (redirect)
}