import React, {forwardRef, useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import CheckoutForm from './CheckoutForm';
import Swal from "sweetalert2";
import {useTranslation} from "react-i18next";
import {useLocation, useOutletContext} from "react-router-dom";
import {Box, Container, Typography} from "@mui/material";
import CheckoutForm2 from "./CheckoutForm2";
import SubscriptionCheckoutForm from "./SubscriptionCheckoutForm";
import useFetch from "../hooks/useFetch";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK_TEST);

let stripePromise;
if (process.env.REACT_APP_STRIPE_TEST_MODE == "true") {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK_TEST);
} else {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);
}

export default function StripeSubscription({}) {
    const {t} = useTranslation('common');
    const [clientSecret, setClientSecret] = useState(null);
    const {callPost, isSending} = useFetch();
    const [apiUri] = useOutletContext();

    useEffect(() => {
        requestCreateSubscription();
    }, []);

    async function requestCreateSubscription() {
        callPost(apiUri + 'subscribe', null, (resultData) => {
            setClientSecret(resultData.clientSecret);
        });
    }

    const appearance = {
        theme: 'night',
    };

    function formatStripAmount(stripeAmount) {
        return Math.round(stripeAmount / 100).toFixed(2);
    }

    return (
        <Container sx={{
            maxWidth: '500px !important'
        }}><Box
            mt={2}
            mb={2}
            p={2}
            sx={{
                backgroundColor: '#fbc70f',
                color: 'black'
            }}>
        </Box>
            {clientSecret &&
            <Elements stripe={stripePromise} options={{
                clientSecret: clientSecret,
                appearance
            }}>
                <SubscriptionCheckoutForm
                    clientSecret={clientSecret}
                />
            </Elements>
            }
        </Container>
    );
};