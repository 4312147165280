import React, {forwardRef, useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import CheckoutForm from './CheckoutForm';
import Swal from "sweetalert2";
import {useTranslation} from "react-i18next";
import {useLocation, useOutletContext} from "react-router-dom";
import {Box, Container, Typography} from "@mui/material";
import CheckoutForm2 from "./CheckoutForm2";
import FormatUtils from "../utils/FormatUtils";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK_TEST);

function ServiceStepStripe({
                               previousBtn,
                               nextBtn,
                               orderData,
                               stripeIntentData
                           }) {

    const {t} = useTranslation('common');
    const [clientSecret, setClientSecret] = useState(null);

    // TODO Sortir le StripePromise ( voir composant StripeSubscription )
    let stripePromise;
    if (process.env.REACT_APP_STRIPE_TEST_MODE == "true") {
        stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK_TEST);
    } else {
        stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);
    }

    useEffect(() => {
        previousBtn.current.style.display = '';
        nextBtn.current.style.display = 'none';

    }, [])

    useEffect(() => {
        if (stripeIntentData != null) {
            // console.log("stripeIntentData :", stripeIntentData);
            setClientSecret(stripeIntentData.client_secret)
        }
    }, [stripeIntentData]);

    const appearance = {
        theme: 'night',
    };

    return (
        <Container sx={{
            maxWidth: '500px !important'
        }}>
            {stripeIntentData ?

                <> <Box
                    mt={2}
                    mb={2}
                    p={2}
                    sx={{
                        backgroundColor: '#fbc70f',
                        color: 'black'
                    }}>
                    <Typography>{t('service.step_stripe.payment_label')} {FormatUtils.formatStripeAmount(stripeIntentData.amount, true)}</Typography>
                </Box>
                    {
                        clientSecret != null &&
                        <Elements key={clientSecret} stripe={stripePromise} options={{
                            clientSecret: clientSecret,
                            appearance
                        }}>
                            <CheckoutForm2
                                clientSecret={clientSecret}
                                orderData={orderData}
                            />
                        </Elements>
                    }
                </>
                :
                <Typography>{t('common.loading')}</Typography>
            }
        </Container>
    );
};

export default ServiceStepStripe;