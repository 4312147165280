import ReactHtmlParser from "react-html-parser";
import React from "react";
import {useTranslation} from "react-i18next";
import {Container, Grid, Typography} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import Swal from "sweetalert2";
import {AccountType} from "../../constants/AccountType";

export default function BrandedPreviewBanner({}) {
    const {t} = useTranslation('common');

    function showPreviewWarning() {
        Swal.fire({
            text: t('branded.warning_vehicle'),
            icon: 'warning',
            target: document.getElementById('swal_container')
        });
    }

    return <Grid
        container
        justifyContent="center"
        p={1}
        style={{
            backgroundColor: 'orange'
        }}
    >
        <Typography>{t('branded.preview_mode')}</Typography>
        <InfoIcon
            cursor="pointer"
            onClick={(e) => {
                showPreviewWarning();
            }}
            sx={
                {
                    marginLeft: '5px',
                    marginTop: '-1px',
                    color: 'white',
                    fontSize: 28,
                }}/>
    </Grid>
}