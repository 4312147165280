import React from 'react';

import BerlineCar from "../assets/cars/Berline.png";
import BerlineXLCar from "../assets/cars/BerlineXL.png";
import CitadineCar from "../assets/cars/Citadine.png";
import CoupeCar from "../assets/cars/Coupe.png";
import FamilialeCar from "../assets/cars/Familiale.png";
import FamilialeBigCar from "../assets/cars/GrosseFamiliale.png";
import SuvBigCar from "../assets/cars/GrosSuv.png";
import SuvSmallCar from "../assets/cars/petitSuv.png";
import LuxeSupercarCar from "../assets/cars/LuxeSupercar.png";
import MicroCar from "../assets/cars/Micro.png";

export default class CarService extends React.Component {
    static carCategories = [
        {
            "id": "1",
            "label": 'cars.1',
            "icon": MicroCar
        },
        {
            "id": "2",
            "label": 'cars.2',
            "icon": CitadineCar
        },
        {
            "id": "3",
            "label": 'cars.3',
            "icon": CoupeCar
        },
        {
            "id": "4",
            "label": 'cars.4',
            "icon": CoupeCar
        },
        {
            "id": "5",
            "label": 'cars.5',
            "icon": BerlineCar
        },
        {
            "id": "6",
            "label": 'cars.6',
            "icon": BerlineXLCar
        },
        {
            "id": "7",
            "label": 'cars.7',
            "icon": SuvSmallCar
        },
        {
            "id": "8",
            "label": 'cars.8',
            "icon": SuvBigCar
        },
        {
            "id": "9",
            "label": 'cars.9',
            "icon": FamilialeCar
        },
        {
            "id": "10",
            "label": 'cars.10',
            "icon": FamilialeBigCar
        },
        {
            "id": "11",
            "label": 'cars.11',
            "icon": LuxeSupercarCar
        }
    ];

    static getCarCategoryById(id) {
        return CarService.carCategories.find(category => category.id == id);
    }
}