import {useEffect, useState} from "react";
import Swal from "sweetalert2";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import useLocalStorageState from "use-local-storage-state";

export default function useFetch() {
    const [accessToken, setAccessToken] = useLocalStorageState('accessToken');
    const [isSending, setIsSending] = useState(false);
    const {t} = useTranslation('common');
    const navigate = useNavigate();

    // const [data, setData] = useState(null);

    // useEffect(() => {
    // });

    // useEffect(() => {
    //     console.log("FETCH TOKEN : ", accessToken);
    // }, [accessToken]);

    const call = (
        url,
        callBack,
        method = 'GET',
        body = null,
        jsonBody = false,
        failCallback = null,
        withAuth = true) => {
        setIsSending(true);
        let headers = {
            'Accept': 'application/json'
        };

        if (withAuth) {
            headers['Authorization'] = 'Bearer ' + accessToken;
        }

        if (body == null) {
            headers['Content-Type'] = 'application/json';
        } else {
            if (jsonBody) {
                headers['Content-Type'] = 'application/json';
                body = JSON.stringify(body);
            }
        }


        fetch(url, {
            method: method,
            headers: headers,
            body: body
        })
            .then(response => {
                setIsSending(false);
                if (response.status == 401) {
                    Swal.fire({
                        text: t('common.error.not_connected'),
                        icon: 'error',
                        target: document.getElementById('swal_container'),
                    });
                    setAccessToken(null);
                    navigate('/login');
                    return null;
                }
                return response.json()
            })
            .then(data => {
                if (data != null) {
                    if (data.state === 'OK') {
                        callBack.call(this, data);
                    } else {
                        if (failCallback != null) {
                            failCallback.call(this, data);
                        } else {
                            Swal.fire({
                                text: t(data.message),
                                icon: 'error',
                                target: document.getElementById('swal_container')
                            });
                        }
                    }
                }
            })
            .catch(rejected => {
                console.log('REJECTED : ', url, rejected);
                Swal.fire({
                    text: t('common.error.connexion_error'),
                    icon: 'error',
                    target: document.getElementById('swal_container')
                });
            });
    }

    const callPost = (url, json = null, callBack, failCallBack = null) => {
        call(url, callBack, 'POST', json, true, failCallBack);
    }

    const callPostForm = (url, data = null, callBack, failCallBack = null) => {
        call(url, callBack, 'POST', data, false, failCallBack);
    }

    const callWithParams = (params) => {
        call(params.url,
            params.callback,
            'method' in params ? params.method : 'GET',
            'body' in params ? params.body : null,
            'jsonBody' in params ? params.jsonBody : false,
            'failCallback' in params ? params.failCallback : null,
            'withAuth' in params ? params.withAuth : true
        )
    }

    return {call, callPost, callPostForm, callWithParams, isSending};
}