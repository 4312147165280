import React, {forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState} from "react";
import {
    Box, Button, Checkbox,
    Container, Divider, FormControl, FormControlLabel, Grid, Link, Modal, TextField, Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Navigate, useOutletContext} from "react-router-dom";
import '../../App.css';
import useFetch from "../../hooks/useFetch";
import {Editor} from "@tinymce/tinymce-react";
import Swal from "sweetalert2";
import ConfigurationContext from "../../contexts/configurationContext";
import ShowcaseEditor from "./ShowcaseEditor";
import FormatUtils from "../../utils/FormatUtils";
import StripeSubscription from "./StripeSubscription";
import StripePaymentSetup from "./StripePaymentSetup";
import QrcodePopin from "./QrcodePopin";
import {useDispatch} from "react-redux";
import {fetchAccountNeeded} from "../account/accountSlice";


function ShowcaseStep4({
                           previousBtn,
                           nextBtn,
                           contentLoadedHandler,
                           setLabel,
                           validateStep4,
                           locationGroupData,
                           subscriptionData,
                           currentStripeProduct,
                           userData,
                           editMode,
                           subscriptionDataChangedHandler,
                       }, ref) {
    const {t, i18n} = useTranslation('common');
    const [loaded, setLoaded] = useState(false);
    const applicationSettingsContext = useContext(ConfigurationContext);

    const [gtcuAccepted, setGtcuAccepted] = useState(false);    // TODO A Virer ?
    const [showStripePay, setShowStripePay] = useState(false);

    const [apiUri] = useOutletContext();
    const {callPost, isSending} = useFetch();

    const [showQrcode, setShowQrcode] = useState(false);

    const formRef = React.useRef();

    useEffect(() => {
        setLabel('showcase.step4.step_title');
        previousBtn.current.style.display = editMode ? 'none' : '';
        nextBtn.current.style.display = 'none'; // Pas de next sur cette étape

        contentLoadedHandler();
        setLoaded(true);
    }, [])

    function getShowcasePath() {
        return applicationSettingsContext == null ? '' : (applicationSettingsContext.client_path + '/lg/' + locationGroupData.slug);
    }

    function getSubscriptionStatus() {
        switch (subscriptionData.stripe_status) {
            case 'active':
                return <>
                    {getOnTrial()}
                    <Typography>{t('showcase.step4.active_label')}</Typography>
                    {getEndsAt()}
                </>;
            case 'trialing':
                return <>
                    {getOnTrial()}
                    {getEndsAt()}
                </>;
                break;
            case 'past_due':
                return <>
                    {getOnTrial()}
                    <Typography color="#FF0000"
                                style={{
                                    fontWeight: 'bold'
                                }}>{t('showcase.step4.past_due')}</Typography>

                    {getEndsAt()}
                </>;
            case 'canceled':
                return <>
                    {getOnTrial()}
                    <Typography color="#FF0000"
                                style={{
                                    fontWeight: 'bold'
                                }}>{t('showcase.step4.canceled_label') + FormatUtils.formatSqlDateWithFullMonth(subscriptionData.ends_at, i18n.language)}</Typography>
                </>;
            default :
                return subscriptionData.stripe_status + ' ???';
        }
    }

    function getEndsAt() {
        if (subscriptionData.ends_at != null) {
            return <><Typography
                color="#FF0000"
                style={{
                    fontWeight: 'bold'
                }}
            >{t('showcase.step4.ends_at')}{FormatUtils.formatSqlDateWithFullMonth(subscriptionData.ends_at, i18n.language)}</Typography></>;
        } else {
            return <></>;
        }
    }

    function getOnTrial() {
        if (subscriptionData.on_trial == '1') {
            return <>
                <Typography>{t('showcase.step4.trial_label') + FormatUtils.formatSqlDateWithFullMonth(subscriptionData.trial_ends_at, i18n.language)}</Typography>
            </>;
        }
        return <></>;
    }

    function getPaymentMethod() {
        if (subscriptionData.pm_last_four != null) {
            return <>
                <Typography>&bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull; {subscriptionData.pm_last_four}</Typography></>
        } else {
            return <><Typography>{t('showcase.step4.no_payment_method')}</Typography></>
        }
    }

    // Depuis l'extérieur ( stepper )
    useImperativeHandle(ref, () => ({
        saveFromExternal() {
            save();
        },
    }));

    function save() {
        validateStep4({
            // 'slug': slugRef.current,
        });
    }

    function subscribeHandler() {
        if (!formRef.current.reportValidity()) {
            return;
        }
        setShowStripePay(true);
    }

    function cancelSubscriptionHandler() {
        Swal.fire({
            html: t('showcase.step4.cancel_confirmation'),
            showCancelButton: true,
            confirmButtonColor: '#dd6b55',
            confirmButtonText: t('common.yes'),
            cancelButtonText: t('common.no'),
        }).then((result) => {
            if (result.isConfirmed) {
                postCancelSubscription();
            }
        })
    }

    function subscribeBackHandler() {
        // Si l'utilisateur a déjà un moyen de paiement
        postSubscribeBack();
    }

    function modifyPaymentMethodHandler() {
        // NB : même méthode que le nouvel abo, c'est au webhook de retour d'ajout de moyen de paiement qu'on fera la diff.
        setShowStripePay(true);
    }

    function addPaymentMethodHandler() {
        // NB : même méthode que le nouvel abo, c'est au webhook de retour d'ajout de moyen de paiement qu'on fera la diff.
        setShowStripePay(true);
    }

    function gotoPortalHandler() {
        callPost(apiUri + 'customer_portal', {}, (resultData => {
            if (resultData.state == 'OK') {
                window.open(resultData.message.url);
            }
        }));
    }

    async function postCancelSubscription() {
        callPost(apiUri + 'subscription_cancel', {}, (resultData => {
            if (resultData.state == 'OK') {
                subscriptionDataChangedHandler(resultData.subscription);
            }
        }));
    }

    async function postSubscribeBack() {
        callPost(apiUri + 'subscribe_back', {}, (resultData => {
            if (resultData.state == 'OK') {
                subscriptionDataChangedHandler(resultData.subscription);
            }
        }));
    }

    function copyLinkToClipboardHandler() {
        let text = document.getElementById('link').innerHTML;
        try {
            navigator.clipboard.writeText(text);
            console.log('Content copied to clipboard');
        } catch (err) {
            console.error('Failed to copy: ', err);
        }

    }

    return (
        <>
            <Container>
                {loaded && <Typography component="div" sx={{
                    mt: 5
                }}>
                    <Grid container justifyContent="center">
                        <Box sx={{fontWeight: 'bold', m: 1, mb: 4}}>{t('showcase.step4.title')}</Box>
                        <Box
                            width='100%'
                            mt={2}
                            p={2}
                            sx={{
                                backgroundColor: '#fbc70f',
                                color: 'black'
                            }}>
                            {!editMode &&
                            <>
                                {t('showcase.step4.line1')}
                                <Typography>&nbsp;</Typography>
                                <Typography fontWeight="bold">{t('showcase.step4.line2')}</Typography>
                                <Typography>{FormatUtils.formatDayDate(locationGroupData.created_at, i18n.language)}</Typography>
                                <Typography>&nbsp;</Typography>
                                <Typography fontWeight="bold">{t('showcase.step4.line3')}</Typography>
                                <Typography>{userData.provider.company_name}</Typography>
                                <Typography>&nbsp;</Typography>
                                <Typography fontWeight="bold">{t('showcase.step4.line4')}</Typography>
                                <Typography>{currentStripeProduct.name} - {FormatUtils.formatStripeAmount(currentStripeProduct.price, true)}</Typography>

                            </>
                            }
                            {editMode &&
                            <>
                                <Typography fontWeight="bold">{t('showcase.step4.subscription_info')}</Typography>
                                <Typography>&nbsp;</Typography>
                                {/* TODO : produit de l'abonnement, pas celui par défaut */}
                                <Typography fontWeight="bold">{t('showcase.step4.line4')}</Typography>
                                <Typography>{currentStripeProduct.name} - {FormatUtils.formatStripeAmount(currentStripeProduct.price, true)}</Typography>
                                <Typography>&nbsp;</Typography>
                                {getSubscriptionStatus()}
                                <Typography>&nbsp;</Typography>
                                <Typography fontWeight="bold">{t('showcase.step4.payment_method')}</Typography>
                                {getPaymentMethod()}
                                <Typography>&nbsp;</Typography>
                                <Typography fontWeight="bold">{t('showcase.step4.next_invoice')}</Typography>
                                <Typography>{FormatUtils.formatSqlDateWithFullMonth(subscriptionData.current_period_end, i18n.language)}</Typography>
                                <Typography>&nbsp;</Typography>
                            </>}
                            <Divider color="black" sx={{mt: 1, mb: 1}}/>
                            <Typography fontWeight="bold">{t('showcase.step4.line5')}</Typography>
                            <Typography><span id="link">{getShowcasePath()}</span>
                                {editMode &&
                                <>
                                    <Button
                                        style={{
                                            marginLeft: '5px'
                                        }}
                                        color="grey"
                                        variant="contained"
                                        onClick={copyLinkToClipboardHandler}>
                                        {t('showcase.copy_link')}
                                    </Button>

                                    <Button
                                        style={{
                                            marginLeft: '5px'
                                        }}
                                        color="grey"
                                        variant="contained"
                                        onClick={() => {
                                            setShowQrcode(true)
                                        }}>
                                        {t('showcase.show_qrcode')}
                                    </Button>
                                </>
                                }
                            </Typography>

                        </Box>
                        {subscriptionData.can_subscribe == 1 &&
                        <>
                            <form ref={formRef}>
                                <FormControl margin="dense" fullWidth={true}>
                                    <FormControlLabel value="gtcu"
                                                      control={<Checkbox required/>}
                                                      label={
                                                          <>
                                                              <Typography>
                                                                  {t('showcase.step4.gtcu_label')}
                                                                  <Link
                                                                      style=
                                                                          {{
                                                                              textDecoration: 'none',
                                                                              color: '#fbc70f'
                                                                          }}
                                                                      // https://keepupcar.com/wp-content/uploads/2018/03/CGV-Keep-Up-Car-1.pdf
                                                                      href={applicationSettingsContext == null ? '' : applicationSettingsContext.URL_CGV}
                                                                      target="_blank"
                                                                  >
                                                                      {t('showcase.step4.gtcu_clickable_label')}
                                                                  </Link>
                                                              </Typography>
                                                          </>
                                                      }
                                                      checked={gtcuAccepted}
                                                      onChange={(e) => setGtcuAccepted(e.target.checked)}/>
                                </FormControl>
                            </form>
                        </>
                        }
                        <Grid container justifyContent="center" mt={1} spacing={2} style={{
                            // backgroundColor: 'red'
                        }}>
                            {subscriptionData.can_subscribe == 1 &&
                            <Grid item>
                                <Button
                                    disabled={isSending}
                                    color="yellow"
                                    variant="contained"
                                    onClick={subscribeHandler}>
                                    {t('showcase.step4.subscribe')}
                                </Button>
                            </Grid>
                            }
                            {subscriptionData.can_cancel == 1 &&
                            <Grid item>
                                <Button
                                    disabled={isSending}
                                    color="yellow"
                                    variant="contained"
                                    onClick={cancelSubscriptionHandler}>
                                    {t('showcase.step4.cancel_subscription')}
                                </Button>
                            </Grid>
                            }
                            {subscriptionData.can_subscribe_back_with_pm == 1 &&
                            <Grid item>
                                <Button
                                    disabled={isSending}
                                    color="yellow"
                                    variant="contained"
                                    onClick={addPaymentMethodHandler}>
                                    {t('showcase.step4.subscribe_back_with_pm')}
                                </Button>
                            </Grid>
                            }
                            {subscriptionData.can_subscribe_back_without_pm == 1 &&
                            <Grid item>
                                <Button
                                    disabled={isSending}
                                    color="yellow"
                                    variant="contained"
                                    onClick={subscribeBackHandler}>
                                    {t('showcase.step4.subscribe_back')}
                                </Button>
                            </Grid>
                            }
                            {subscriptionData.can_modify_pm == 1 &&
                            <Grid item>
                                <Button
                                    disabled={isSending}
                                    color="yellow"
                                    variant="contained"
                                    onClick={modifyPaymentMethodHandler}>
                                    {t('showcase.step4.modify_pm')}
                                </Button>
                            </Grid>
                            }
                            {subscriptionData.can_go_customer_portal == 1 &&
                            <Grid item>
                                <Button
                                    disabled={isSending}
                                    color="yellow"
                                    variant="contained"
                                    onClick={gotoPortalHandler}>
                                    {t('showcase.step4.customer_portal')}
                                </Button>
                            </Grid>
                            }

                            {/*<Grid item>*/}
                            {/*    <Button*/}
                            {/*        color="yellow"*/}
                            {/*        variant="contained"*/}
                            {/*        onClick={() => {*/}
                            {/*            dispatch(fetchAccountNeeded())*/}
                            {/*        }}>*/}
                            {/*        TEST!*/}
                            {/*    </Button>*/}
                            {/*</Grid>*/}
                        </Grid>
                    </Grid>
                </Typography>
                }
            </Container>
            <Modal open={showStripePay}
                   onClose={(e) => setShowStripePay(false)}
                   style={{
                       display: 'flex',
                       alignItems: 'center',
                       justifyContent: 'center'
                   }}
            >
                <Grid
                    style={{
                        background: '#292e32'
                    }}
                >
                    <StripePaymentSetup
                        locationGroupId={locationGroupData.id}
                    />
                </Grid>
            </Modal>
            <Modal open={showQrcode}
                   onClose={() => {
                       setShowQrcode(false)
                   }}
                   style={{
                       display: 'flex',
                       alignItems: 'center',
                       justifyContent: 'center'
                   }}
            >
                <QrcodePopin url={getShowcasePath(locationGroupData)}/>
            </Modal>
        </>
    )
};

export default forwardRef(ShowcaseStep4);