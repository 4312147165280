import {useTranslation} from "react-i18next";
import {Badge, Container} from "@mui/material";
import Tab from "@mui/material/Tab";

// ... props => https://github.com/mui/material-ui/issues/13893

export default function ProviderOrderBookTabLabel({label, badgeValue = 0, ...props}) {
    const {t, i18n} = useTranslation('common');

    return <Tab label={
        <Badge
            badgeContent={badgeValue}
            color="primary"
            max={999}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <Container>
                {label}
            </Container>
        </Badge>}
                {...props}
    />
}
