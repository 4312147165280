import React, {forwardRef, useEffect, useRef, useState} from 'react';
import PaymentMethods from "./PaymentMethods";
import Swal from "sweetalert2";
import {useTranslation} from "react-i18next";

function ServiceStepPaymentMethods({
                                       previousBtn,
                                       nextBtn,
                                       newMethodClickedHandler,
                                       validatePaymentFromPaymentMethodHandler,
                                   }, ref) {


    const selectedMethodRef = useRef(null);
    const {t} = useTranslation('common');

    const [selectedMethod, setSelectedMethod] = useState(null);

    selectedMethodRef.current = selectedMethod;

    useEffect(() => {
        previousBtn.current.style.display = '';
        nextBtn.current.style.display = '';

        nextBtn.current.addEventListener('click', nextClickedHandler);

        return () => {
            if (nextBtn.current) {
                nextBtn.current.removeEventListener('click', nextClickedHandler);
            }
        }
    }, [])

    function methodSelectedHandler(methodData) {
        setSelectedMethod(methodData);
    }

    function nextClickedHandler() {
        if (selectedMethodRef.current == null) {
            Swal.fire({
                text: t('service.step_payment_method.warning_choose_method'),
                icon: 'warning',
                target: document.getElementById('swal_container')
            });
            return;
        }
        validatePaymentFromPaymentMethodHandler(selectedMethodRef.current);
    }

    return (
        <PaymentMethods selectMode={true}
                        selectedMethod={selectedMethod}
                        methodSelectedHandler={methodSelectedHandler}
                        newMethodClickedHandler={newMethodClickedHandler}
        />
    );
};

export default forwardRef(ServiceStepPaymentMethods);