export class OrderStatus {
    static ACCEPTED = 1;
    static DONE = 2;
    static REFUNDED = 3;
    static CANCELED_BY_CUSTOMER = 4;
    static DISPUTE_REFUSED = 5;
    static PAYMENT_PENDING = 6;
    static PROVIDER_ISSUE_WITH_VEHICLE = 7;
    static DISPUTE_BY_CUSTOMER = 8;
    static QUOTATION_NEEDED = 9;
    static QUOTATION_SENT = 10;
    static QUOTATION_REFUSED = 11;
}

Object.freeze(OrderStatus);