import {useOutletContext} from "react-router-dom";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {Box, Button, Container, FormControlLabel, FormGroup, Grid, Switch} from "@mui/material";
import Swal from "sweetalert2";
import LocationScheduleRow from "./LocationScheduleRow";
import FormatUtils from "../utils/FormatUtils";

function LocationCalendarDay({
                                 calendarDayData,   // Jour exceptionnel
                                 dataChangedHandler,
                                 scheduleData   // Jour habituel
                             }) {

    const {t, i18n} = useTranslation('common');
    const [isException, setIsException] = React.useState(false)

    useEffect(() => {
        setIsException(isAnException());
    }, [calendarDayData])

    function isAnException() {
        return FormatUtils.calendarDayDataIsException(calendarDayData);
    }

    function rowDataChangedHandler(dispatchDataChanged = true) {
        // setIsException(true);

        // Nouvelle exception ?
        if (!isAnException()) {
            calendarDayData.schedulesExceptionId = 'N';
            calendarDayData.am_closed = scheduleData.am_closed;
            calendarDayData.am_start = scheduleData.am_start;
            calendarDayData.am_end = scheduleData.am_end;
            calendarDayData.am_capacity = scheduleData.am_capacity;
            calendarDayData.pm_closed = scheduleData.pm_closed;
            calendarDayData.pm_start = scheduleData.pm_start;
            calendarDayData.pm_end = scheduleData.pm_end;
            calendarDayData.pm_capacity = scheduleData.pm_capacity;
            calendarDayData.is_full = '0';
        }

        // console.log(scheduleData);

        if (dispatchDataChanged) {
            dataChangedHandler();
        }
    }

    function cancelExceptionClickedHandler(e) {

        if (calendarDayData.schedulesExceptionId == 'N') {
            // Nouvelle exception => on la supprime
            delete calendarDayData.schedulesExceptionId;
        } else {
            calendarDayData.schedulesExceptionId = 'D' + calendarDayData.schedulesExceptionId;
        }
        dataChangedHandler();
    }

    let borderColor = 'divider';
    if (isException) {
        borderColor = 'orange';
    }

    let isFull = isAnException() && calendarDayData.is_full == '1';
    let isToday = ('isToday' in calendarDayData) && calendarDayData.isToday == '1';

    return (
        <>
            <Box sx={{
                m: 1,
                borderRadius: '8px',
                height: '24px',
                backgroundColor: isToday ? 'orange' : ''
            }}>
                <Grid container justifyContent="center">
                    {
                        isToday ? t('location.calendar.today_label') : ''
                    }
                </Grid>
            </Box>
            <Box sx={
                {
                    m: 1,
                    border: 3,
                    borderColor: {borderColor},
                    borderRadius: '10px',
                    backgroundColor: '#292e32'
                }
            }>
                <Grid container justifyContent="center" sx={
                    {
                        p: 1,
                        backgroundColor: 'black'
                    }
                }>{FormatUtils.formatDayDate(calendarDayData.date, i18n.language)}
                    {isAnException() &&
                    <Button
                        variant="contained"
                        color="yellow"
                        onClick={cancelExceptionClickedHandler}
                    >
                        {t('location.calendar.button_cancel_exception')}
                    </Button>
                    }
                </Grid>
                <LocationScheduleRow shortVersion={true} scheduleData={isException ? calendarDayData : scheduleData}
                                     type="am"
                                     dataChangedHandler={rowDataChangedHandler}/>
                <Grid sx={{borderBottom: 1, borderColor: 'divider', marginTop: "10px", marginBottom: '10px'}}/>
                <LocationScheduleRow shortVersion={true} scheduleData={isException ? calendarDayData : scheduleData}
                                     type="pm"
                                     dataChangedHandler={rowDataChangedHandler}/>

                <Grid container justifyContent="center" sx={
                    {
                        backgroundColor: isFull ? 'orange' : ''
                    }
                }>
                    <FormGroup><FormControlLabel control={<Switch
                        checked={isFull}
                        onChange={function (e) {
                            rowDataChangedHandler(false);
                            calendarDayData.is_full = e.target.checked ? '1' : '0';
                            rowDataChangedHandler();
                        }}/>} label={t('location.calendar.full_label')}/></FormGroup>
                </Grid>
            </Box>
        </>
    );
}

export default LocationCalendarDay;