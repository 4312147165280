import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {
    Autocomplete,
    Box,
    Button, Checkbox,
    Container, FormControl, FormControlLabel, FormGroup,
    Grid, InputLabel, Link,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import Swal from "sweetalert2";
import {useOutletContext, useParams} from "react-router-dom";
import '../App.css';
import ReactCountryFlag from "react-country-flag"
import CarService from "../services/CarService";
import VehicleTypeSelector from "./VehicleTypeSelector";
import FormatUtils from "../utils/FormatUtils";
import useFetch from "../hooks/useFetch";

function VehicleStep1(props, ref) {
    const [apiUri] = useOutletContext();
    const {t} = useTranslation('common');
    const formRef = React.useRef();

    const [vehicleId, setVehicleId] = useState(null);

    const [vehicleData, setVehicleData] = useState(null);

    const [vinError, setVinError] = useState(null);
    const [country, setCountry] = useState('ch');

    const [brand, setBrand] = useState(null);   // Object
    const [model, setModel] = useState(null);   // Object
    const [carCategory, setCarCategory] = useState(null);   // Object

    const [showBrand, setShowBrand] = useState(false);
    const [showModel, setShowModel] = useState(false);
    const [showCarCategory, setShowCarCategory] = useState(false);

    // Does vin / brand / model locked
    const [locked, setLocked] = useState(false);
    // Does carCategory locked

    const [brandList, setBrandList] = useState(null);
    const [modelList, setModelList] = useState(null);

    const [showCustomBrand, setShowCustomBrand] = useState(false);
    const [showCustomModel, setShowCustomModel] = useState(false);
    const [carCategoryLocked, setCarCategoryLocked] = useState(false);

    // Leasing
    const [isLeasingCar, setIsLeasingCar] = useState(false);
    const [leasingCompanyList, setLeasingCompanyList] = useState(null);
    const [leasingCompanyId, setLeasingCompanyId] = useState('');
    const [leasingContractNumber, setLeasingContractNumber] = useState('');

    const {call, callPost} = useFetch();

    // TODO Ajouter editmode, en editmode, on ne peut pas changer la carCategorie par exemple ( pas le bouton dispo )

    let params = useParams();

    useEffect(() => {
        if (params.vehicleId != null) {
            getFromServer(params.vehicleId);
        } else {
            props.contentLoadedHandler(null);   // Trigger stepper vue
        }

        // TODO Pourrait être appelé qu'au besoin ( mais ici ca évite les chargements en cours de route )
        getBrands();
        getLeasingCompanies();

        props.previous_btn.current.style.display = 'none';
        props.next_btn.current.addEventListener('click', submitData);

        return () => {
            props.next_btn.current && props.next_btn.current.removeEventListener('click', submitData);
        }
    }, [])

    function isEditMode() {
        return params.vehicleId != null;
    }

    function submitData() {
        if (!formRef.current.reportValidity()) {
            return;
        }
        formRef.current.dispatchEvent(
            new Event("submit", {cancelable: true, bubbles: true})
        );
    }

    function brandChangedHandler(e, value) {
        setBrand(value);
        setModel(null);

        setShowCarCategory(false);
        if (value == null) {
            setShowModel(false);
            return;
        }

        setShowModel(true);
        getModels(value.id);
    }

    function modelChangedHandler(e, value) {
        setModel(value);
        if (value == null) {
            setShowCarCategory(false);
            return;
        }

        setShowCarCategory(true);
        let carCategory = CarService.getCarCategoryById(value.car_category_id);
        setCarCategory(carCategory);
        setCarCategoryLocked(carCategory != null);
    }

    function vehicleNotFoundClickedHandler(e) {
        if (brand == null) {
            setShowBrand(false)
            setShowCustomBrand(true);
        }
        setShowModel(false);
        setShowCustomModel(true);
        setCarCategoryLocked(false);
        setShowCarCategory(true);
    }

    useImperativeHandle(ref, () => ({
        wantChangeTab(stepValue) {
            // TODO Save or warn..
            props.changeStepFromContentHandler(stepValue);
        },
    }));

    // TODO redondant
    async function getFromServer(id) {
        call(apiUri + 'vehicle_get/' + id, (resultData) => {
            setVehicleId(resultData.vehicle.id);
            setVehicleData(resultData.vehicle);

            formRef.current.elements['vin'].value = resultData.vehicle.vin;

            if (resultData.vehicle.country != null) {
                setCountry(resultData.vehicle.country);
            }

            setShowBrand(false);
            setShowModel(false)

            setShowCustomBrand(true);
            setShowCustomModel(true);

            setCarCategory(CarService.getCarCategoryById(resultData.vehicle.car_category_id));
            setCarCategoryLocked(true);
            setShowCarCategory(true);

            setLocked(true);

            if (resultData.vehicle.leasing_company_id != null) {
                setIsLeasingCar(true);
                setLeasingCompanyId(resultData.vehicle.leasing_company_id);
                setLeasingContractNumber(resultData.vehicle.leasing_contract_number);
            }

            props.contentLoadedHandler(resultData.vehicle);
        });
    }

    async function getLeasingCompanies() {
        call(apiUri + 'leasing_companies', (resultData) => {
            setLeasingCompanyList(resultData.leasing_companies);
        });
    }

    //
    async function sendToServer(data) {
        callPost(apiUri + 'vehicle_register_step1', data, (resultData) => {
            let newVehicleId = resultData.vehicleId;
            Swal.fire({
                text: t('vehicle.form.success'),
                icon: 'success',
                timer: 2000,
                target: document.getElementById('swal_container'),
            })
                .then(() => {
                        if (!props.isEditMode) {
                            props.changeStepFromContentHandler(1, newVehicleId);
                        }
                    }
                );
        });
    }

    function getBrands() {
        call(apiUri + 'vehicle_brand_index', (resultData) => {
            setBrandList(resultData.brands);
        });
    }

    function getModels(brandId) {
        call(apiUri + 'vehicle_model/' + brandId, (resultData) => {
            setModelList(resultData.models);
        });
    }

    function submitHandler(event) {
        if (!formRef.current.reportValidity()) {
            return;
        }

        if (carCategory == null) {
            Swal.fire({
                text: t('vehicle.form.category_mandatory_error'),
                icon: 'warning',
                target: document.getElementById('swal_container'),
            });
            return;
        }

        let data = {
            id: vehicleId,
            country: country,
            vin: formRef.current.elements['vin'].value,
            brand_id: brand != null ? brand.id : null,
            model_id: model != null ? model.id : null,
            custom_brand: formRef.current.elements['custom_brand'] != null ? formRef.current.elements['custom_brand'].value : null,
            custom_model: formRef.current.elements['custom_model'] != null ? formRef.current.elements['custom_model'].value : null,
            car_category_id: carCategory.id,
            car_category_locked: carCategoryLocked ? '1' : '0'
        }

        if (isLeasingCar) {
            data.leasing_company_id = leasingCompanyId;
            data.leasing_contract_number = leasingContractNumber;
        }

        sendToServer(data);
    }

    function submitVinHandler(event) {
        if (formRef.current.elements['vin'].value.length < 17) {
            setVinError('vehicle.form.vin_error_incorrect');
            return;
        }

        setVinError(null);

        let data = {
            vin: formRef.current.elements['vin'].value
        }

        if (vehicleId != null) {
            data.id = vehicleId;
        }

        callPost(apiUri + 'vin_check', data, (resultData) => {
            setVehicleId(resultData.vehicle.id);
            setVehicleData(resultData.vehicle);

            formRef.current.elements['vin'].value = resultData.vehicle.vin;

            setShowBrand(false);
            setShowModel(false);
            setShowCustomBrand(true);
            setShowCustomModel(true);
            setShowCarCategory(true);
            setLocked(true);

            let carCategory = CarService.getCarCategoryById(resultData.vehicle.car_category_id);

            setCarCategory(carCategory);
            setCarCategoryLocked(carCategory != null);
        }, (resultFailData) => {
            // Error
            if (resultFailData.message == 'vehicle.form.error.vin_already_registered') {
                Swal.fire({
                    text: FormatUtils.changeTextWithValues(t(resultFailData.message), resultFailData.message_values),
                    icon: 'error',
                    target: document.getElementById('swal_container'),
                });
                return;
            }
            // TODO Better error check
            setVinError('vehicle.form.vin_error');
            setShowBrand(true);
            setShowCustomBrand(false);
            setShowCustomModel(false);
        });
    }

    function wrongCarCategoryClickedHandler() {
        setCarCategoryLocked(false);
    }

    function vinInputHandler(event) {
        // console.log(event.target.value);
        // On autorise uniquement les alphanumériques
        let value = event.target.value;
        const ALPHA_NUMERIC_REGEX = /[^a-zA-Z0-9]/gi;
        let remplaced = value.replace(ALPHA_NUMERIC_REGEX, '');
        remplaced = remplaced.toUpperCase();
        event.target.value = remplaced;
    }

    return (
        <Container>
            <>
                <form className="form-inline" onSubmit={submitHandler} ref={formRef}>
                    <Grid container justifyContent="center" sx={{marginTop: '10px'}}>
                        <Container>
                            <Grid container justifyContent="center">
                                <Select
                                    id="country"
                                    value={country}
                                    onChange={
                                        e => {
                                            setCountry(e.target.value);
                                        }
                                    }
                                >
                                    <MenuItem value="fr"><ReactCountryFlag countryCode="FR" svg style={{
                                        width: '2em',
                                        height: '2em',
                                        marginRight: '5px'
                                    }}/> {t('countries.france')}</MenuItem>
                                    <MenuItem value="ch"><ReactCountryFlag countryCode="CH" svg style={{
                                        width: '2em',
                                        height: '2em',
                                        marginRight: '5px'
                                    }}/> {t('countries.switzerland')}</MenuItem>
                                </Select>
                            </Grid>
                            {/* VIN  */}
                            <Box sx={{
                                width: '100%',
                                backgroundColor: '#515151',
                                borderRadius: '16px',
                                padding: '5px',
                                marginTop: '10px'
                            }}>
                                <Grid container justifyContent="center" alignContent="center"
                                      sx={{marginTop: '10px'}}>
                                    <Grid item sm={3} xs={12} container alignItems="center" justifyContent="center"
                                          direction="column">
                                        <Typography p={0} m={0}>{t('vehicle.form.vin_label')}</Typography>
                                        <Typography p={0} m={0}><i>{t('vehicle.form.vin_hint')}</i></Typography>
                                    </Grid>
                                    <Grid item sm={7} xs={9} container
                                    >
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            id="vin"
                                            disabled={locked}
                                            onInput={vinInputHandler}
                                        />
                                    </Grid>
                                    <Grid item sm={2} xs={3} container alignItems="center" justifyContent="center">
                                        <Button
                                            disabled={locked}
                                            variant="contained"
                                            color="yellow"
                                            onClick={submitVinHandler}
                                        >
                                            {t('vehicle.form.vin_submit')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box>
                                <Grid container justifyContent="center" alignContent="center"
                                      sx={{marginTop: '10px'}}>
                                    {vinError &&
                                    <Typography color="red"
                                                visibility="none">{t(vinError)}</Typography>
                                    }
                                </Grid>
                            </Box>

                            {showBrand && !locked && brandList != null &&
                            <>
                                <Autocomplete
                                    sx={{marginTop: '10px'}}
                                    id="brand"
                                    disabled={locked}
                                    value={brand}
                                    options={brandList}
                                    renderInput={(params) =>
                                        <TextField {...params} label={t('vehicle.form.brand')}
                                                   required
                                        />
                                    }
                                    onChange={brandChangedHandler}
                                />
                            </>
                            }

                            {showModel && !locked && modelList != null &&
                            <Autocomplete
                                sx={{marginTop: '10px'}}
                                id="model"
                                required
                                disabled={locked}
                                value={model}
                                options={modelList}
                                renderInput={(params) =>
                                    <TextField {...params} label={t('vehicle.form.model')}
                                               required
                                    />
                                }
                                onChange={modelChangedHandler}
                            />
                            }

                            {(showModel || showBrand) && (brand == null || model == null) && !showCustomModel &&
                            <a href="#"
                               onClick={vehicleNotFoundClickedHandler}>{t('vehicle.form.brand_not_found')}</a>
                            }

                            {showCustomBrand &&
                            <TextField
                                required
                                disabled={locked}
                                margin="normal"
                                fullWidth
                                id="custom_brand"
                                label={t('vehicle.form.brand')}
                                defaultValue={locked ? vehicleData.brand : null}
                            />
                            }
                            {showCustomModel &&
                            <TextField
                                required
                                disabled={locked}
                                margin="normal"
                                fullWidth
                                id="custom_model"
                                label={t('vehicle.form.model')}
                                defaultValue={locked ? vehicleData.model : null}
                            />
                            }
                            {showCarCategory &&
                            <>
                                <br/>
                                {t('vehicle.form.category_reminder')} {carCategory && t(carCategory.label)}
                                <Grid container
                                      sx={{marginTop: '10px'}}
                                >
                                    {
                                        CarService.carCategories.map((car, i) => {
                                            return (<VehicleTypeSelector
                                                key={i}
                                                carData={car}
                                                selected={
                                                    car == carCategory
                                                }
                                                onClickHandler={
                                                    (e) => {
                                                        if (carCategoryLocked) {
                                                            return;
                                                        }
                                                        setCarCategory(car);
                                                    }
                                                }
                                            />);
                                        })
                                    }
                                </Grid>
                                {
                                    !isEditMode() && carCategoryLocked &&
                                    <Grid container justifyContent="center" sx={{
                                        mt: 1
                                    }}>
                                        <Link onClick={wrongCarCategoryClickedHandler}
                                              href="#"
                                              color="#fbc70f">
                                            {t('vehicle.form.wrong_car_category_link')}
                                        </Link>&nbsp;
                                        <Typography>{t('vehicle.form.wrong_car_category')}</Typography>

                                        {/*<Button*/}
                                        {/*    // variant="contained"*/}
                                        {/*    color="yellow"*/}
                                        {/*    onClick={wrongCarCategoryClickedHandler}*/}
                                        {/*>*/}
                                        {/*    {t('vehicle.form.wrong_car_category')}*/}
                                        {/*</Button>*/}
                                    </Grid>
                                }
                            </>
                            }

                            {leasingCompanyList != null &&
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={isLeasingCar}
                                        onChange={(e) => {
                                            setIsLeasingCar(e.target.checked)
                                        }}
                                    />}
                                    label={t('vehicle.form.leasing_label')}/>
                                {isLeasingCar &&
                                <FormControl sx={{marginTop: '10px'}}>
                                    <InputLabel
                                        id="leasing_company_label">{t('vehicle.form.leasing_company_label')}</InputLabel>
                                    <Select
                                        labelId="leasing_company_label"
                                        label={t('vehicle.form.leasing_company_label')}
                                        // sx={{marginTop: '10px'}}
                                        id="leasing_company_id"
                                        // required
                                        // disabled={locked}
                                        value={leasingCompanyId}
                                        // options={leasingCompanyList}
                                        onChange={(e) => {
                                            setLeasingCompanyId(e.target.value);
                                        }}
                                    >
                                        {leasingCompanyList.map((company) => {
                                            return <MenuItem key={company.id}
                                                             value={company.id}>{company.label}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                                }
                                {isLeasingCar && leasingCompanyId != '' &&
                                <FormControl sx={{marginTop: '10px'}}>
                                    <TextField
                                        label={t('vehicle.form.leasing_contact_number_label')}
                                        value={leasingContractNumber}
                                        onChange={(e) => {
                                            if (leasingCompanyId == 1) {    // ARVAL
                                                setLeasingContractNumber(e.target.value.replace(/[^\d|\.]/g, ""));
                                            } else {
                                                setLeasingContractNumber(e.target.value);
                                            }
                                        }}
                                        required
                                    />
                                </FormControl>
                                }
                            </FormGroup>
                            }
                        </Container>
                    </Grid>
                </form>
            </>
        </Container>
    )
};

export default forwardRef(VehicleStep1);