import {
    Box,
    Button, Checkbox,
    Container,
    Grid, IconButton, Link, Modal, Paper,
    Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Typography
} from "@mui/material";
import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import Swal from "sweetalert2";
import {useOutletContext} from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import TireInfoImage from '../assets/tires_info.png';
import useFetch from "../hooks/useFetch";
import FormatUtils from "../utils/FormatUtils";
import styles from '../app.module.css';
import {TireAvailabilityType} from "../constants/TireAvailabilityType";
import MobileContext from "../contexts/mobileContext";
import NewTireWheelComponent from "./NewTireWheelComponent";

export default function VehicleItemSubSectionTire({
                                                      buyVersion = false,
                                                      vehicleData,
                                                      tireCategories,
                                                      reloadHandler,
                                                      selectedTires = [],
                                                      selectedHandler = null
                                                  }) {
    const [apiUri] = useOutletContext();
    const {t} = useTranslation('common');
    const formRef = React.useRef();

    const [showInfo, setShowInfo] = useState(false);
    const {call, callPostForm} = useFetch();
    const mobileContext = useContext(MobileContext);
    const isMobile = mobileContext.isMobile;

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        maxWidth: '600px',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24
    };

    const [showMobileForm, setShowMobileForm] = useState(false);

    // Form selects
    const [width, setWidth] = useState('');
    const [height, setHeight] = useState('');
    const [tireCategory, setTireCategory] = useState('');
    const [isWheel, setIsWheel] = useState('');
    const [countPosition, setCountPosition] = useState('4');
    const [description, setDescription] = useState('');
    //

    const minWidth = 125;
    const maxWidth = 335;

    let widths = [];
    for (let w = minWidth; w < maxWidth; w++) {
        widths.push(w);
    }

    const minHeight = 25;
    const maxHeight = 80;
    let heights = [];
    for (let h = minHeight; h < maxHeight; h++) {
        heights.push(h);
    }

    // useEffect(() => {
    //     console.log("ST=", selectedTires);
    // }, [selectedTires]);

    function getTireCategoryById(id) {
        return tireCategories.find(tireCategory => tireCategory.id == id);
    }

    function getTireCountPositionByTire(tireData) {
        let value = tireData.count + tireData.position;
        return value;
    }

    // Attention ca modifie tireData
    function getTireCountPositionOrderByTire(tireData) {
        if (!('order' in tireData)) {
            tireData.order = Object.create(null);
            tireData.order.count = tireData.count;
            tireData.order.position = tireData.position;
        }
        let value = tireData.order.count + tireData.order.position;
        return value;
    }

    function getTireCountAndPositionFromValue(value) {
        switch (value) {
            case '4':
                return {'count': 4, 'position': null};
            case '2F':
                return {'count': 2, 'position': 'F'};
            case '2B':
                return {'count': 2, 'position': 'B'};
            default:
                return null;
        }
    }

    function selectedTiresContainsTire(tire, returnIndex = false) {
        // console.log(selectedTires);
        for (let index = 0; index < selectedTires.length; index++) {
            if (selectedTires[index].id == tire.id) {
                if (returnIndex) {
                    return index;
                }
                return true;
            }
        }
        if (returnIndex) {
            return -1;
        }
        return false;
    }

    function selectedTiresAddOrRemoveTire(tire) {
        let localSelectedTires = FormatUtils.cloneData(selectedTires);

        let index = selectedTiresContainsTire(tire, true);
        if (index == -1) {
            localSelectedTires.push(tire);
        } else {
            localSelectedTires.splice(index, 1);
        }
        // console.log(localSelectedTires);
        return localSelectedTires;
    }

    // data : FormData
    function postInsertTire(data) {
        callPostForm(apiUri + 'vehicle_tire_insert', data, (resultData) => {
            let isMultiple = resultData.message.ids.length >= 2;
            Swal.fire({
                text:
                    isMultiple ? t('vehicle.item.info.add_success_multiple') : t('vehicle.item.info.add_success'),
                icon: 'success',
                timer: 2000,
                target: document.getElementById('swal_container'),
            })
                .then(() => {

                    setTireCategory('');
                    setWidth('');
                    setHeight('');
                    setDescription('');
                    setIsWheel('');

                    setShowMobileForm(false);

                    // TODO Pas ouf de recharger tous les vehicules, mais ca fonctionne
                    reloadHandler(resultData.message.ids);
                    // closeAddDocumentHandler();
                });
        });
    }

    function sendDeleteTire(id) {
        call(apiUri + 'vehicle_tire_delete?id=' + id, (resultData) => {
                Swal.fire({
                    timer: 2000,
                    title: t('vehicle.item.info.delete_success'),
                    text: '',
                    icon: 'success',
                    target: document.getElementById('swal_container')
                })
                    .then(() => {
                        reloadHandler();
                    })
            },
            'DELETE');
    }

    function deleteTireClickedHandler(id) {
        Swal.fire({
            title: t('vehicle.item.info.delete_confirmation'),
            showCancelButton: true,
            confirmButtonColor: '#dd6b55',
            confirmButtonText: t('common.yes'),
            cancelButtonText: t('common.no'),
            target: document.getElementById('swal_container')
        }).then((result) => {
            if (result.isConfirmed) {
                sendDeleteTire(id);
            }
        })
    }

    function cancelTireStoreHandler(tireId) {
        // console.log("TODO!", tireId);
        Swal.fire({
            title: t('vehicle.item.info.store_tire_cancel_confirmation'),
            showCancelButton: true,
            confirmButtonColor: '#dd6b55',
            confirmButtonText: t('common.yes'),
            cancelButtonText: t('common.no'),
            target: document.getElementById('swal_container')
        }).then((result) => {
            if (result.isConfirmed) {
                sendCancelStoreTire(tireId);
            }
        })
    }

    function sendCancelStoreTire(tireId) {
        let data = new FormData();

        data.append('vehicle_id', vehicleData.id);
        data.append('tire_id', tireId);

        callPostForm(apiUri + 'vehicle_tire_cancel_store', data, (resultData) => {
            Swal.fire({
                text: t('vehicle.item.info.store_tire_cancel_success'),
                icon: 'success',
                timer: 2000,
                target: document.getElementById('swal_container'),
            })
                .then(() => {
                    // TODO Pas ouf de recharger tous les vehicules, mais ca fonctionne
                    reloadHandler();
                });
        });
    }

    function addTireClickedHandler() {
        postInsertTire();
    }

    function showMobileFormClickedHandler() {
        setShowMobileForm(true);
    }

    return (
        <>
            {tireCategories != null &&
            <>
                <Grid container>
                    <Typography>{buyVersion ?
                        t("vehicle.item.info.tires_configuration_label")
                        : t("vehicle.item.info.tires_label")
                    }</Typography>
                    <InfoIcon
                        cursor="pointer"
                        onClick={(e) => {
                            setShowInfo(!showInfo)
                        }}
                        sx={
                            {
                                marginLeft: '5px',
                                marginTop: '-1px',
                                color: 'orange',
                                fontSize: 28,
                            }}/>
                </Grid>
                <Container disableGutters>
                    <form ref={formRef}>
                        {isMobile &&
                        <Button
                            onClick={showMobileFormClickedHandler}
                            variant="contained"
                            color="yellow"
                            sx={{mt: '15px', mb: '20px'}}
                            fullWidth
                            disabled={showMobileForm}
                        >
                            {t('vehicle.item.info.add_tire_btn')}
                        </Button>
                        }
                        {(!isMobile || showMobileForm || vehicleData.tires.length > 0) &&
                        <TableContainer component={Paper} style={{
                            padding: isMobile ? '5px' : '',
                            width: '100%'
                        }}>
                            <Table size={isMobile ? 'small' : ''} padding={isMobile ? 'none' : 'normal'}
                            >
                                {!isMobile &&
                                <TableHead>
                                    <TableRow>
                                        {
                                            // 5 / 10 / 10 / 10 / 10 / 15 / 25 / 10 / 5
                                        }
                                        <TableCell></TableCell>
                                        <TableCell
                                        >{t('vehicle.item.info.tire_width')}</TableCell>
                                        <TableCell
                                        >{t('vehicle.item.info.tire_height')}</TableCell>
                                        <TableCell
                                        >{t('vehicle.item.info.tire_category')}</TableCell>
                                        <TableCell
                                        >{t('vehicle.item.info.tire_wheel_or_tire')}</TableCell>
                                        <TableCell
                                        >{t('vehicle.item.info.tire_count_and_position')}</TableCell>
                                        {!buyVersion &&
                                        <>
                                            <TableCell
                                            >{t('vehicle.item.info.tire_description')}</TableCell>
                                            <TableCell
                                            >{t('vehicle.item.info.store_tire_label')}</TableCell>
                                            <TableCell></TableCell>
                                        </>
                                        }
                                    </TableRow>
                                </TableHead>
                                }
                                <TableBody>
                                    {isMobile && showMobileForm &&
                                    <NewTireWheelComponent
                                        widths={widths}
                                        heights={heights}
                                        tireCategories={tireCategories}
                                        buyVersion={buyVersion}
                                        vehicleData={vehicleData}
                                        formRef={formRef}
                                        showInfoHandler={() => {
                                            setShowInfo(!showInfo)
                                        }}
                                        submitHandler={(data) => {
                                            postInsertTire(data)
                                        }}
                                        cancelHandler={() => {
                                            // Uniquement mobile
                                            setShowMobileForm(false);
                                        }
                                        }
                                    />
                                    }
                                    {
                                        (vehicleData.tires).map((tire, i) => {
                                            let tireAvailable;
                                            if (buyVersion) {   // En achat on s'en fout de
                                                tireAvailable = (tire.available == TireAvailabilityType.AVAILABLE
                                                    || tire.available == TireAvailabilityType.NON_AVAILABLE_STORE);
                                            } else {
                                                tireAvailable = (tire.available == TireAvailabilityType.AVAILABLE);
                                            }

                                            let classes = [];
                                            if (selectedHandler != null && !tireAvailable) {
                                                classes.push(styles['color-disabled']);
                                            }
                                            let className = classes.join(' ');

                                            return (
                                                <React.Fragment key={i}>
                                                    <TableRow
                                                        sx={{
                                                            'td, th': {
                                                                borderTop: 1,
                                                                borderTopColor: 'inherit',
                                                                borderBottom: 0,
                                                            }
                                                        }}
                                                        // sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                                    >
                                                        <TableCell>
                                                            {selectedHandler != null && <Checkbox
                                                                sx={{
                                                                    '& .MuiSvgIcon-root': {
                                                                        fontSize: 40,
                                                                    },
                                                                }}
                                                                disabled={!tireAvailable}
                                                                checked={selectedTiresContainsTire(tire)}
                                                                // checked={selectedTire != null && selectedTire.id == tire.id}
                                                                onClick={(e) => {
                                                                    selectedHandler(selectedTiresAddOrRemoveTire(tire));
                                                                }}
                                                            />}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography
                                                                className={className}>{tire.width ? tire.width + 'mm' : '-'}</Typography>
                                                        </TableCell>
                                                        <TableCell><Typography
                                                            className={className}>{tire.height ? tire.height : '-'}</Typography></TableCell>
                                                        <TableCell><Typography
                                                            className={className}>{getTireCategoryById(tire.tire_category_id).label}</Typography></TableCell>
                                                        <TableCell>
                                                            <Typography
                                                                className={className}>{tire.is_wheel ? t('vehicle.item.info.tire_wheel' + (isMobile ? '_mobile' : '')) : t('vehicle.item.info.tire_tire')}</Typography></TableCell>
                                                        <TableCell>
                                                            <Typography
                                                                className={className}>{t('vehicle.item.info.tire_' + getTireCountPositionByTire(tire))}</Typography>
                                                        </TableCell>
                                                        {!buyVersion &&
                                                        <>
                                                            {!isMobile && (
                                                                <>
                                                                    <TableCell><Typography
                                                                        className={className}>{tire.description}</Typography></TableCell>
                                                                    <TableCell>
                                                                        {tire.store_location == null &&
                                                                        <>
                                                                            {t('common.no')}
                                                                        </>
                                                                        }
                                                                        {
                                                                            tire.store_location != null &&
                                                                            <Stack>
                                                                                <>{tire.store_location.name} - {tire.store_location.city}</>
                                                                                <Link
                                                                                    underline="none"
                                                                                    href="#"
                                                                                    color="#FFA500"
                                                                                    onClick={(e) => cancelTireStoreHandler(tire.id)}
                                                                                >
                                                                                    {
                                                                                        t('vehicle.item.info.store_tire_cancel_btn')
                                                                                    }
                                                                                </Link>
                                                                            </Stack>
                                                                        }
                                                                    </TableCell>
                                                                </>
                                                            )
                                                                // End !isMobile
                                                            }
                                                            <TableCell>
                                                                <Button sx={
                                                                    {
                                                                        m: isMobile ? 0 : '',
                                                                        p: isMobile ? 0 : '',
                                                                        minWidth: isMobile ? '16px' : ''
                                                                    }
                                                                }
                                                                        onClick={() => deleteTireClickedHandler(tire.id)}>
                                                                    <DeleteIcon sx={
                                                                        {
                                                                            color: 'orange',
                                                                            fontSize: 16,
                                                                        }}/>
                                                                </Button></TableCell>
                                                        </>
                                                            //    End !buyVersion
                                                        }
                                                    </TableRow>

                                                    {isMobile && !buyVersion &&
                                                    <>
                                                        <TableRow sx={{
                                                            'td, th': {
                                                                borderBottom: 0,
                                                            }
                                                        }}>
                                                            <TableCell colSpan={7}><Typography
                                                                className={className}>{tire.description}
                                                            </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                        {tire.store_location != null &&
                                                        <TableRow>
                                                            <TableCell colSpan={7}>
                                                                {/*<Stack>*/}
                                                                <Typography>{t('vehicle.item.info.store_at')} {tire.store_location.name} - {tire.store_location.city}</Typography>
                                                                <Link
                                                                    underline="none"
                                                                    href="#"
                                                                    color="#FFA500"
                                                                    onClick={(e) => cancelTireStoreHandler(tire.id)}
                                                                >
                                                                    {
                                                                        t('vehicle.item.info.store_tire_cancel_btn')
                                                                    }
                                                                </Link>
                                                                {/*</Stack>*/}
                                                            </TableCell>
                                                        </TableRow>
                                                        }
                                                    </>
                                                    }

                                                    {
                                                        selectedHandler != null && !tireAvailable &&
                                                        <TableRow
                                                            sx={{

                                                                'td, th': {
                                                                    border: 0,
                                                                    paddingTop: '0px'
                                                                }
                                                            }}
                                                        >
                                                            <TableCell colSpan="7" align="center">
                                                                <Typography
                                                                    color={"#8b0000"}>{
                                                                    tire.available == TireAvailabilityType.NON_AVAILABLE_TYPE &&
                                                                    <>
                                                                        {t('service.step_choose_tires.tire_category_unavailable')}
                                                                    </>
                                                                }
                                                                    {
                                                                        tire.available == TireAvailabilityType.NON_AVAILABLE_STORE &&
                                                                        <>
                                                                            {t('service.step_choose_tires.tire_store_warning')}
                                                                        </>
                                                                    }
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                </React.Fragment>)
                                        })
                                    }

                                    {(!isMobile) &&
                                    <NewTireWheelComponent
                                        widths={widths}
                                        heights={heights}
                                        tireCategories={tireCategories}
                                        buyVersion={buyVersion}
                                        vehicleData={vehicleData}
                                        formRef={formRef}
                                        showInfoHandler={() => {
                                            setShowInfo(!showInfo)
                                        }}
                                        submitHandler={(data) => {
                                            postInsertTire(data)
                                        }}
                                    />

                                        // <>
                                        // <TableRow
                                        //         sx={{
                                        //             'td, th': {
                                        //                 borderTop: 1,
                                        //                 borderTopColor: 'inherit',
                                        //                 borderBottom: 0,
                                        //             }
                                        //         }}
                                        //     >
                                        //         <TableCell align={"center"}>
                                        //             <InfoIcon
                                        //                 cursor="pointer"
                                        //                 onClick={(e) => {
                                        //                     setShowInfo(!showInfo)
                                        //                 }}
                                        //                 sx={
                                        //                     {
                                        //                         marginLeft: '5px',
                                        //                         marginTop: '-1px',
                                        //                         color: 'orange',
                                        //                         fontSize: 28,
                                        //                     }}/>
                                        //         </TableCell>
                                        //         <TableCell style={{
                                        //             // backgroundColor : 'yellow'
                                        //         }}>
                                        //             <FormControl fullWidth variant="outlined">
                                        //                 <InputLabel shrink>{t('vehicle.item.info.tire_width')}</InputLabel>
                                        //                 {/*https://github.com/mui/material-ui/issues/21394*/}
                                        //                 <Select
                                        //                     required={true}
                                        //                     input={<OutlinedInput notched
                                        //                                           label={t('vehicle.item.info.tire_width')}/>}
                                        //                     size="small"
                                        //                     // defaultValue={i18n.language}
                                        //                     label={t('vehicle.item.info.tire_width')}
                                        //                     value={width}
                                        //                     onChange={(e) => {
                                        //                         setWidth(e.target.value);
                                        //                     }}
                                        //                 >
                                        //                     {widths.map((value) => {
                                        //                         return <MenuItem key={value}
                                        //                                          value={value}>{value}mm</MenuItem>
                                        //                     })}
                                        //                 </Select>
                                        //             </FormControl>
                                        //         </TableCell>
                                        //         <TableCell>
                                        //             <FormControl fullWidth>
                                        //                 <InputLabel shrink>{t('vehicle.item.info.tire_height')}</InputLabel>
                                        //                 <Select
                                        //                     input={<OutlinedInput notched
                                        //                                           label={t('vehicle.item.info.tire_height')}/>}
                                        //                     size="small"
                                        //                     value={height}
                                        //                     onChange={(e) => {
                                        //                         setHeight(e.target.value);
                                        //                     }}
                                        //                 >
                                        //                     {heights.map((value) => {
                                        //                         return <MenuItem key={value}
                                        //                                          value={value}>{value}mm</MenuItem>
                                        //                     })}
                                        //                 </Select>
                                        //             </FormControl>
                                        //         </TableCell>
                                        //         <TableCell>
                                        //             <FormControl fullWidth>
                                        //                 <InputLabel
                                        //                     shrink>{t('vehicle.item.info.tire_category')}</InputLabel>
                                        //                 <Select
                                        //                     required={true}
                                        //                     input={<OutlinedInput notched
                                        //                                           label={t('vehicle.item.info.tire_category')}/>}
                                        //                     size="small"
                                        //                     value={tireCategory}
                                        //                     onChange={(e) => {
                                        //                         setTireCategory(e.target.value)
                                        //                     }}
                                        //                 >
                                        //                     {tireCategories.map((category, i) => {
                                        //                         return <MenuItem key={i}
                                        //                                          value={category.id}>{category.label}</MenuItem>
                                        //                     })}
                                        //                 </Select>
                                        //             </FormControl>
                                        //         </TableCell>
                                        //         <TableCell>
                                        //             <FormControl fullWidth>
                                        //                 <InputLabel
                                        //                     shrink>{t('vehicle.item.info.tire_wheel_or_tire')}</InputLabel>
                                        //                 <Select
                                        //                     required={true}
                                        //                     input={<OutlinedInput notched
                                        //                                           label={t('vehicle.item.info.tire_wheel_or_tire')}/>}
                                        //                     size="small"
                                        //                     value={isWheel}
                                        //                     onChange={(e) => {
                                        //                         setIsWheel(e.target.value);
                                        //                     }}
                                        //                 >
                                        //                     <MenuItem
                                        //                         value="0">{t('vehicle.item.info.tire_tire')}</MenuItem>
                                        //                     <MenuItem
                                        //                         value="1">{t('vehicle.item.info.tire_wheel')}</MenuItem>
                                        //                 </Select>
                                        //             </FormControl>
                                        //         </TableCell>
                                        //         <TableCell>
                                        //             <FormControl fullWidth>
                                        //                 <InputLabel
                                        //                     shrink>{t('vehicle.item.info.tire_count_and_position')}</InputLabel>
                                        //                 <Select
                                        //                     required={true}
                                        //                     input={<OutlinedInput notched
                                        //                                           label={t('vehicle.item.info.tire_count_and_position')}/>}
                                        //                     size="small"
                                        //                     value={countPosition}
                                        //                     onChange={(e) => {
                                        //                         setCountPosition(e.target.value);
                                        //                     }}
                                        //                 >
                                        //                     <MenuItem value="2F">{t('vehicle.item.info.tire_2F')}</MenuItem>
                                        //                     <MenuItem value="2B">{t('vehicle.item.info.tire_2B')}</MenuItem>
                                        //                     <MenuItem value="4">{t('vehicle.item.info.tire_4')}</MenuItem>
                                        //                 </Select>
                                        //             </FormControl>
                                        //         </TableCell>
                                        //         {!buyVersion &&
                                        //         <TableCell colSpan={3}><TextField
                                        //             InputLabelProps={{shrink: true}}
                                        //             size="small"
                                        //             value={description}
                                        //             onChange={(e) => {
                                        //                 setDescription(e.target.value);
                                        //             }}
                                        //             fullWidth
                                        //             label={t('vehicle.item.info.tire_description')}
                                        //         /></TableCell>
                                        //         }
                                        //         <TableCell></TableCell>
                                        //     </TableRow>
                                        //     <TableRow
                                        //         sx={{
                                        //
                                        //             'td, th': {
                                        //                 padding: 0,
                                        //                 paddingLeft: '16px',
                                        //                 border: 0,
                                        //                 // backgroundColor: '#afbff0'
                                        //             },
                                        //
                                        //         }}>
                                        //         <TableCell colSpan="5">
                                        //             <Button
                                        //                 onClick={addTireClickedHandler}
                                        //                 variant="contained"
                                        //                 color="yellow"
                                        //                 sx={{mt: '15px', mb: '20px'}}
                                        //             >
                                        //                 {t('vehicle.item.info.add_tire_btn')}
                                        //             </Button>
                                        //         </TableCell>
                                        //     </TableRow>
                                        // </>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        }
                    </form>
                </Container>
                <Modal
                    open={showInfo}
                    onClose={() => {
                        setShowInfo(!showInfo)
                    }}
                >
                    <>
                        <Box sx={modalStyle}>
                            <Box display="flex" alignItems="center" style={{
                                margin: '0px',
                                // backgroundColor : "#afbff0"
                            }}>
                                <Box flexGrow={1}></Box>
                                <Box>
                                    <IconButton onClick={() => {
                                        setShowInfo(!showInfo)
                                    }}>
                                        <CloseIcon/>
                                    </IconButton>
                                </Box>
                            </Box>
                            <img src={TireInfoImage} width="100%"></img>
                        </Box>
                    </>
                </Modal>
            </>}
        </>
    )
}