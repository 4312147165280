import React, {useEffect, useState} from 'react';
import {
    Box, Button, Grid, Modal, TextField
} from "@mui/material";
import {useOutletContext} from "react-router-dom";

import btnClose from "../assets/buttons/close/close-nobackground.svg";
import btnCloseOver from "../assets/buttons/close/close-nobackground.svg";
import ImageButton from "./ImageButton";

import {useTranslation} from "react-i18next";
import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content'
import ServiceActionItem from "./ServiceActionItem";
import useFetch from "../hooks/useFetch";
import {PaymentType} from "../constants/PaymentType";
import {OrderAction} from "../constants/OrderAction";

export default function ServiceActionModal({
                                               order,
                                               closeHandler,
                                               actionList = null
                                           }) {
    const [apiUri] = useOutletContext();
    const {t} = useTranslation('common');

    const [loaded, setLoaded] = useState(false);
    const [orderActionListData, setOrderActionListData] = useState(null);
    const [orderActionResultData, setOrderActionResultData] = useState(null);

    const [selectedAction, setSelectedAction] = useState(null);
    const [message, setMessage] = useState('');

    const [isSending, setIsSending] = useState(false);

    const MySwal = withReactContent(Swal);
    const {call, callPost} = useFetch();

    useEffect(() => {
        if (order != null) {
            if (actionList == null) {
                getOrderActionList();
            } else {
                setLoaded(true);
                setOrderActionListData(
                    {
                        "action_list": actionList,
                        "days_remaining": -1
                    }
                );
            }
        }

    }, [order])

    async function getOrderActionList() {
        call(apiUri + 'order/' + order.id + '/action_list', (resultData => {
            setLoaded(true);
            setOrderActionListData(resultData);
        }));
    }

    async function postOrderAction() {
        let data = {
            order_id: order.id,
            action: selectedAction
        }

        if (message) {
            data.message = message;
        }

        callPost(apiUri + 'order/action', data, (resultData => {
            setOrderActionResultData(resultData); // TODO ?
            MySwal.fire({
                text: t('service_action.success'),
                icon: 'success',
                timer: 2000,
                target: document.getElementById('swal_container'),
            })
                .then((result) => {
                    closeHandler(true);
                })
            setIsSending(false);
        }));
    }

    function actionClickedHandler(action) {
        setSelectedAction(action);
    }

    function validateClickedHandler() {
        let message = '';
        let daysRemaining = orderActionListData.days_remaining;


        switch (selectedAction) {
            case OrderAction.CUSTOMER_CANCEL:
                if (order.payment_type_id == PaymentType.CASH) {
                    if (daysRemaining >= 2) {
                        message = t('service_action.action_customer_cancel.warning_validate');
                    } else {
                        message = t('service_action.action_customer_cancel.warning_cash');
                    }
                } else {
                    if (daysRemaining >= 2) {
                        message = t('service_action.action_customer_cancel.warning_total_refund');
                    } else if (daysRemaining == 1) {
                        message = t('service_action.action_customer_cancel.warning_partial_refund');
                    } else {
                        message = t('service_action.action_customer_cancel.warning_no_refund');
                    }
                }
                break;
            case OrderAction.PROVIDER_CANCEL:
                message = t('service_action.action_provider_cancel.warning');
                break;
            case OrderAction.PROVIDER_ISSUE:
                message = t('service_action.action_provider_issue.warning');
                break;
            case OrderAction.CUSTOMER_DISPUTE:
                message = t('service_action.action_customer_dispute.warning');
                break;
        }


        MySwal.fire({
            html: message,
            showCancelButton: true,
            confirmButtonColor: '#dd6b55',
            confirmButtonText: t('service_action.warning_ok'),
            cancelButtonText: t('service_action.warning_cancel'),
            target: document.getElementById('swal_container')
        }).then((result) => {
            if (result.isConfirmed) {
                warningConfirmedHandler();
            }
        })
    }

    function warningConfirmedHandler() {
        setIsSending(true);
        postOrderAction();
    }

    let popupTitle = t('service_action.title');
    if (actionList != null)  // TODO Un peu crade
    {
        popupTitle = t('service_action.title_dispute');
    }

    return (
        <Modal
            id="service_action_modal"
            open={true}
            style={{
                overflowY: 'scroll',
            }}
            disableEnforceFocus
        >
            <div style={style}>
                {loaded &&
                <Box sx={styleInner}>
                    <Grid container justifyContent="flex-end">
                        <Grid item sx={{flexGrow: 1}}>
                            <Grid container justifyContent="center">
                                <h3>{
                                    popupTitle.toUpperCase()
                                }</h3>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <ImageButton src={btnClose} srcOver={btnCloseOver} onClickHandler={(e) => closeHandler()}/>
                        </Grid>
                    </Grid>

                    <Grid container justifyContent="center">
                        {
                            orderActionListData.action_list.map((action, i) => {
                                return <ServiceActionItem key={action} action={action}
                                                          selected={action == selectedAction}
                                                          clickedHandler={actionClickedHandler}/>
                            })
                        }
                        <TextField
                            sx={{
                                mt: 2,
                                ml: 'auto',
                                borderRadius: '8px',
                                border: 2,
                                backgroundColor: 'white',
                                maxWidth: '500px'
                            }}
                            style={{
                                borderColor: 'orange'
                            }}
                            inputProps={{
                                style: {color: "black"}
                            }}
                            onChange={(e) => setMessage(e.currentTarget.value)}
                            fullWidth
                            placeholder={t('service_action.message_prompt')}
                            value={message}
                        />

                        <Button
                            disabled={selectedAction == null || isSending}
                            variant="contained"
                            color="yellow"
                            sx={{mt: '15px', mb: '20px'}}
                            onClick={validateClickedHandler}
                        >
                            {t('service_action.send_btn')}
                        </Button>
                    </Grid>
                </Box>
                }
            </div>
        </Modal>
    );
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '500px',
};

const styleInner = {
    bgcolor: 'background.paper',
    border: '2px solid #000',
    minHeight: '100%'
}