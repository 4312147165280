import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Box, Grid, Step, StepLabel, Stepper, Tabs} from "@mui/material";
import {withStyles} from "@mui/styles";
import Tab from "@mui/material/Tab";

function VehicleStepper(props) {
    const {t} = useTranslation('common');
    let params = useParams();

    const {
        classes,
        editMode,
        changeStepHandler
    } = props;

    const steps = [
        t('vehicle.steps.step1'),
        t('vehicle.steps.step2')
    ];

    return (
        <>
            {
                editMode ?
                    <Tabs
                        value={props.activeStep}
                        onChange={changeStepHandler}
                        textColor="inherit"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                    >
                        {steps.map((label, index) => (
                            <Tab key={index} label={label}/>
                        ))}

                    </Tabs>
                    :
                    <Stepper activeStep={props.activeStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel StepIconProps={{
                                    classes: {
                                        active: classes.icon,
                                        completed: classes.icon,
                                    }
                                }}>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
            }
        </>
    );
}

const styles = theme => ({
    icon: {
        color: "orange !important"
    }
});

export default withStyles(styles)(VehicleStepper);