import {useTranslation} from "react-i18next";
import React, {useContext} from "react";
import {Grid, Stack, Typography} from "@mui/material";
import ServiceLocationCalendarPeriod from "./ServiceLocationCalendarPeriod";
import MobileContext from "../contexts/mobileContext";
import moment from "moment";
import FormatUtils from "../utils/FormatUtils";

// scheduleData => jour habituel
// calendarDayData => jour exceptionnel

function ServiceLocationCalendarDay({calendarDayData, periodClicked, selectedDay, selectedPeriod}) {
    const {t, i18n} = useTranslation('common');

    const mobileContext = useContext(MobileContext);
    const isMobile = mobileContext.isMobile;

    function isPeriodSelected(day, period) {
        // console.log(day);
        if (day == null || selectedDay == null) {
            return false;
        }

        if (day.date == selectedDay.date && period == selectedPeriod) {
            return true;
        }
        return false;
    }

    return (
        <>
            <Grid justifyContent="center" sx={
                {
                    m: 1,
                }
            }>
                <Stack
                    // style={{backgroundColor : "#FF0000"}}
                >
                    <Typography style={{
                        textAlign: 'center',
                        color: "#fbc70f",
                        fontSize: isMobile ? '0.8em' : null
                    }}>{FormatUtils.formatDayDateShort(calendarDayData.date, i18n.language)}</Typography>
                    <Grid sx={{
                        borderBottom: 1,
                        borderColor: 'divider',
                        marginTop: isMobile ? '5px' : '10px',
                        marginBottom: isMobile ? '5px' : '10px'
                    }}/>
                    <ServiceLocationCalendarPeriod
                        calendarDayData={calendarDayData}
                        type="am" clicked={periodClicked}

                        selected={isPeriodSelected(calendarDayData, 'am')}/>
                    <Grid sx={{borderBottom: 1, borderColor: 'divider', marginTop: "10px", marginBottom: '10px'}}/>
                    <ServiceLocationCalendarPeriod
                        calendarDayData={calendarDayData}
                        type="pm" clicked={periodClicked}
                        selected={isPeriodSelected(calendarDayData, 'pm')}/>
                </Stack>
            </Grid>
        </>
    );
}

export default ServiceLocationCalendarDay;