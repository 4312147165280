import React, {useContext} from 'react';
import {
    Box, Grid, Radio, Stack, Typography
} from "@mui/material";

import {useTranslation} from "react-i18next";
import BtnServiceIssue from "../assets/buttons/service_issue/car-warning-red.png";
import BtnServiceCancel from "../assets/buttons/close/close-white-yellow.png";
import {OrderAction} from "../constants/OrderAction";
import MobileContext from "../contexts/mobileContext";

export default function ServiceActionItem({
                                              action,
                                              selected,
                                              clickedHandler
                                          }) {
    const {t} = useTranslation('common');

    const mobileContext = useContext(MobileContext);

    let title;
    let desc;
    let icon;

    switch (action) {
        case OrderAction.CUSTOMER_CANCEL:
            title = t('service_action.action_customer_cancel.title');
            desc = t('service_action.action_customer_cancel.description');
            icon = BtnServiceCancel;
            break;
        case OrderAction.PROVIDER_CANCEL:
            title = t('service_action.action_provider_cancel.title');
            desc = t('service_action.action_provider_cancel.description');
            icon = BtnServiceCancel;
            break;
        case OrderAction.PROVIDER_ISSUE:
            title = t('service_action.action_provider_issue.title');
            desc = t('service_action.action_provider_issue.description');
            icon = BtnServiceIssue;
            break;
        case OrderAction.CUSTOMER_DISPUTE:
            title = t('service_action.action_customer_dispute.title');
            desc = t('service_action.action_customer_dispute.description');
            icon = BtnServiceIssue;
            break;
        default:
            console.error('Action non prise en compte : ' + action);
    }

    return (
        <>
            <Box
                sx={{
                    border: 1,
                    p: '16px',
                    m: '0px',
                    // mt: '10px',
                    // mb: '10px',
                    width: '100%'
                }}
            >
                <Grid container>
                    <Grid item xs={2}
                          container
                          alignContent="center"
                          sx={{
                              p: 0,
                              m: 0
                          }}
                    >
                        <img width='90%' src={icon}/>
                    </Grid>
                    <Grid item xs={8} container>
                        <Stack width="100%">
                            <Typography
                                onClick={(e) => clickedHandler(action)}
                                style={{
                                    cursor: 'pointer',
                                    color: "#fbc70f",
                                    fontSize: "18px",
                                    fontWeight: "bold"
                                }}>{title}
                            </Typography>
                            <Typography>{desc}</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={2} container justifyContent="right" alignContent="center">
                        <Radio
                            sx={{
                                '& .MuiSvgIcon-root': {
                                    fontSize: 40,
                                },
                            }}
                            checked={selected}
                            onClick={(e) => clickedHandler(action)}
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}