import React, {useContext, useEffect, useState} from 'react';
import {Button, Container, Grid, RadioGroup, Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import Swal from "sweetalert2";
import {useOutletContext} from "react-router-dom";
import PaymentMethodItem from "./PaymentMethodItem";
import useFetch from "../hooks/useFetch";
import MobileContext from "../contexts/mobileContext";

export default function PaymentMethods({
                                           selectMode = false,
                                           selectedMethod,
                                           methodSelectedHandler,
                                           newMethodClickedHandler
                                       }) {
    const {t} = useTranslation('common');
    const [apiUri] = useOutletContext();
    const [paymentMethodsData, setPaymentMethodsData] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const {call} = useFetch();

    const mobileContext = useContext(MobileContext);
    const isMobile = mobileContext.isMobile;

    useEffect(() => {
        requestPaymentMethods();
    }, [])

    async function requestPaymentMethods() {
        call(apiUri + 'customer_payment_methods', (data) => {
            setPaymentMethodsData(data.payment_methods);
            setLoaded(true);
        });
    }

    async function requestDetachPaymentMethod(methodData) {
        let data = new FormData();
        data.append('id', methodData.id);

        call(apiUri + 'customer_payment_method_detach', (resultData) => {
            requestPaymentMethods();
        }, 'POST', data);
    }

    function detachCardHandler(methodData) {

        Swal.fire({
            html: t('payment_methods.detach_confirmation'),
            showCancelButton: true,
            confirmButtonColor: '#dd6b55',
            confirmButtonText: t('common.yes'),
            cancelButtonText: t('common.no'),
        }).then((result) => {
            if (result.isConfirmed) {
                requestDetachPaymentMethod(methodData);
            }
        })
    }

    return (<Container disableGutters={isMobile}>
        <Grid container justifyContent="center">
            <Typography>
                {selectMode ? t('payment_methods.title_choose') : t('payment_methods.title')}
            </Typography>
        </Grid>

        {loaded &&
        <Container
            disableGutters={isMobile}
            sx={{
                maxWidth: '600px !important'
            }}
        >
            <RadioGroup>
                <Stack>
                    {
                        paymentMethodsData.data && paymentMethodsData.data.map((methodData, index) => {
                            return <PaymentMethodItem
                                key={index}
                                methodData={methodData}
                                selectMode={selectMode}
                                selected={selectedMethod != null && methodData.id == selectedMethod.id}
                                detachHandler={detachCardHandler}
                                selectHandler={methodSelectedHandler}
                            />
                        })
                    }
                </Stack>
            </RadioGroup>
            {selectMode &&
            <Grid container justifyContent="center">
                <Button
                    variant="contained"
                    color="yellow"
                    sx={{mt: '15px', mb: '20px'}}
                    onClick={() => newMethodClickedHandler()}
                >
                    {t('payment_methods.new_payment_method')}
                </Button>
            </Grid>
            }
        </Container>
        }
    </Container>);

}