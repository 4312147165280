import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {
    Container,
    Grid, Link,
    Tabs
} from "@mui/material";
import {useTranslation} from "react-i18next";
import Swal from "sweetalert2";
import {useOutletContext, useParams} from "react-router-dom";
import '../App.css';
import Tab from "@mui/material/Tab";

import LocationScheduleTab from "./LocationScheduleTab";
import LocationCalendar from "./LocationCalendar";
import useFetch from "../hooks/useFetch";
import FormatUtils from "../utils/FormatUtils";

function LocationSchedules({
                               isEditMode,
                               previous_btn,
                               next_btn,
                               saveNeededHandler,
                               contentSavedHandler,
                               contentLoadedHandler,
                               changeStepFromContentHandler,
                               closeFromContentHandler
                           }, ref) {
    const [apiUri] = useOutletContext();
    const {t} = useTranslation('common');

    const [locationId, setLocationId] = useState(null);

    let params = useParams();

    const [locationData, setLocationData] = useState(null);
    const [schedulesData, setSchedulesData] = useState([]);

    // For tabs
    const [value, setValue] = React.useState(0);
    const tabChangedHandler = (event, newValue) => {
        setValue(newValue);
    };

    let newDataCount = 0;

    const schedulesDataRef = useRef(null);
    schedulesDataRef.current = schedulesData;

    const locationDataRef = useRef(null);
    locationDataRef.current = locationData;

    const locationIdRef = useRef(null);
    locationIdRef.current = locationId;

    const calendarRef = useRef();

    const editModeRef = useRef(null);
    editModeRef.current = isEditMode;

    const weekDays = [
        {
            'id': 'mon',
            'label': t('weekDays.condensed.1')
        },
        {
            'id': 'tue',
            'label': t('weekDays.condensed.2')
        },
        {
            'id': 'wed',
            'label': t('weekDays.condensed.3')
        },
        {
            'id': 'thu',
            'label': t('weekDays.condensed.4')
        },
        {
            'id': 'fri',
            'label': t('weekDays.condensed.5')
        },
        {
            'id': 'sat',
            'label': t('weekDays.condensed.6')
        },
        {
            'id': 'sun',
            'label': t('weekDays.condensed.7')
        },
    ];

    const {call} = useFetch();

    useEffect(() => {
        if (params.locationId != null) {
            getFromServer(params.locationId);
        }

        previous_btn.current.style.display = 'none';
        // props.previous_btn.current.addEventListener('click', () => console.log('back'));

        next_btn.current.addEventListener('click', nextClickedHandler);

        return () => {
            next_btn.current && next_btn.current && next_btn.current.removeEventListener('click', nextClickedHandler);
        }
    }, []);

    useImperativeHandle(ref, () => ({
        save() {
            nextClickedHandler();
        }
    }));

    // NB : Next or Save
    function nextClickedHandler() {
        if (!checkCapacities()) {
            return;
        }
        if (!checkSchedules()) {
            Swal.fire({
                text: t('location.schedules.warning_schedule_incoherence'),
                icon: 'warning',
                target: document.getElementById('swal_container'),
            });
            return;
        }
        let data = {
            id: locationIdRef.current,
            schedules: schedulesDataRef.current
        }
        sendToServer(data);
    }

    function calendarNeedSaveHandler() {
        saveNeededHandler(true);
    }

    // TODO redondant
    async function getFromServer(id) {
        call(apiUri + 'location_get/' + id, (data) => {
            setLocationId(data.location.id);
            setLocationData(data.location);
            locationDataRef.current = data.location;
            if (data.location.schedules.length == 0) {
                setSchedulesData(createSchedules());
            } else {
                setSchedulesData(data.location.schedules);
            }
            schedulesDataRef.current = data.location.schedules;

            contentLoadedHandler(data.location);
        });
    }

    //
    async function sendToServer(data) {

        call(apiUri + 'location_schedules', (data) => {
            // We have a calendar
            if (editModeRef.current) {
                calendarRef.current.save();
            } else {
                // Cas ou on est en création
                Swal.fire({
                    text: t('location.info.success'),
                    icon: 'success',
                    timer: 2000,
                    target: document.getElementById('swal_container'),
                })
                    .then(() => {
                        saveNeededHandler(false);
                        changeStepFromContentHandler(-1)
                    })
            }
        }, 'POST', data, true);
    }

    function calendarSaveSuccessHandler() {
        contentSavedHandler();
    }

    function createSchedules() {
        weekDays.forEach((weekDay, index) => {
            newDataCount++;
            let newScheduleData = {
                "id": "N" + newDataCount,
                "week_day": weekDay.id,
                "am_start": t('location.schedules.default_from_am'),
                "am_end": t('location.schedules.default_to_am'),
                "am_capacity": null,
                "am_closed": index == 6 ? 1 : 0,
                "pm_start": t('location.schedules.default_from_pm'),
                "pm_end": t('location.schedules.default_to_pm'),
                "pm_capacity": null,
                "pm_closed": index == 6 ? 1 : 0,
            }
            schedulesData.push(newScheduleData);
        });
        return schedulesData;
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    function refreshData() {
        setSchedulesData(JSON.parse(JSON.stringify(schedulesData)));
        saveNeededHandler(true);
    }

    function applyAllHandler(scheduleDataToCopy) {
        Swal.fire({
            title: t('location.schedules.apply_to_all_confirmation'),
            showDenyButton: true,
            confirmButtonText: t('common.yes'),
            denyButtonText: t('common.no'),
            target: document.getElementById('swal_container'),
        }).then((result) => {
            if (result.isConfirmed) {
                let newSchedulesData = schedulesData.map(scheduleData => {
                    // console.log(scheduleDataToCopy);
                    scheduleData.am_start = scheduleDataToCopy.am_start;
                    scheduleData.am_end = scheduleDataToCopy.am_end;
                    scheduleData.am_capacity = scheduleDataToCopy.am_capacity;
                    scheduleData.am_closed = scheduleDataToCopy.am_closed;
                    scheduleData.pm_start = scheduleDataToCopy.pm_start;
                    scheduleData.pm_end = scheduleDataToCopy.pm_end;
                    scheduleData.pm_capacity = scheduleDataToCopy.pm_capacity;
                    scheduleData.pm_closed = scheduleDataToCopy.pm_closed;
                    return scheduleData;
                });
                setSchedulesData(newSchedulesData);
                saveNeededHandler(true);
            } else if (result.isDenied) {
                return;
            }
        })
    }

    function deleteLocationHandler() {
        Swal.fire({
            title: t('location.delete.warning'),
            showDenyButton: true,
            confirmButtonText: t('common.yes'),
            denyButtonText: t('common.no'),
            target: document.getElementById('swal_container'),
        }).then((result) => {
            if (result.isConfirmed) {
                call(apiUri + 'location', (resultData => {
                    Swal.fire({
                        text: t('location.delete.success'),
                        icon: 'success',
                        target: document.getElementById('swal_container'),
                    })
                        .then(() => {
                            closeFromContentHandler();
                        })
                }), 'DELETE', {
                    id: locationId
                }, true);
            } else if (result.isDenied) {
                return;
            }
        })
    }

    function isOpen(weekDay) {
        let schedule = schedulesData.find(scheduleData => {
            return scheduleData.week_day == weekDay.id;
        });

        if (schedule == null) {
            return false;
        }
        return (schedule.am_closed != 1 || schedule.pm_closed != 1);
    }

    function checkSchedules() {
        let ok = true;
        // console.log(schedulesData);
        schedulesDataRef.current.forEach(scheduleData => {
            // am
            if (!(scheduleData.am_closed == 1)) {
                // console.log(scheduleData.am_start + "=>" + scheduleData.am_end);
                if (scheduleData.am_start > scheduleData.am_end) {
                    ok = false;
                }
            }
            // pm
            if (!scheduleData.pm_closed) {
                if (scheduleData.pm_start > scheduleData.pm_end) {
                    ok = false;
                }
            }
            // am + pm
            if (!scheduleData.am_closed && !scheduleData.pm_closed) {
                if (scheduleData.am_end > scheduleData.pm_start) {
                    ok = false;
                }
            }
        });
        return ok;
    }

    function checkCapacities() {
        // console.log(schedulesDataRef.current);
        let ok = true;
        let days = [];
        schedulesDataRef.current.forEach((scheduleData, index) => {
            if ((scheduleData.am_closed == 0 && FormatUtils.isNotSet(scheduleData.am_capacity)) || (scheduleData.pm_closed == 0 && FormatUtils.isNotSet(scheduleData.pm_capacity))) {
                ok = false;
                days[index] = true;
            }
        });
        if (!ok) {
            let text = t('location.schedules.error_null_capacity');
            days.forEach((w, index) => {
                text += weekDays[index].label;
            });
            Swal.fire({
                text: text,
                icon: 'warning',
                target: document.getElementById('swal_container'),
            });
        }
        return ok;
    }

    return (
        <Container maxWidth={false}>
            <Container maxWidth={false}>
                {schedulesData.length > 0 &&
                <>
                    {/*<BasicTabs/>*/}
                    <Grid container justifyContent="center">
                        <Grid container
                              sx={{maxWidth: 700, minWidth: 700, backgroundColor: '#292e32', marginTop: '20px'}}>
                            <Grid sx={{borderBottom: 1, borderColor: 'divider', width: '100vh'}}>
                                <Tabs
                                    variant="fullWidth"
                                    centered
                                    value={value} onChange={tabChangedHandler}>
                                    {weekDays.map((weekDay, index) => {
                                        return <Tab sx={{
                                            backgroundColor: isOpen(weekDay) && "#FFC70F",
                                            color: isOpen(weekDay) && "black"
                                        }} key={index}
                                                    label={weekDay.label} {...a11yProps(index)} />
                                    })
                                    }
                                </Tabs>
                            </Grid>
                            <Grid sx={{width: '100vh'}}>
                                {schedulesData.map((scheduleData, index) => {
                                    return <LocationScheduleTab
                                        key={index}
                                        currentTabIndex={value}
                                        index={index}
                                        scheduleData={scheduleData}
                                        applyAllHandler={applyAllHandler}
                                        refreshDataHandler={refreshData}
                                    />
                                })
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </>}
            </Container>
            {locationId != null && isEditMode &&
            <>
                <LocationCalendar ref={calendarRef}
                                  locationId={locationId}
                                  schedulesData={schedulesData}
                                  saveSuccessHandler={calendarSaveSuccessHandler}
                                  needSaveHandler={calendarNeedSaveHandler}
                />
                <Link
                    href="#"
                    color="#8b0000"
                    onClick={deleteLocationHandler}
                >
                    {t('location.delete.button_delete')}
                </Link>
            </>
            }
        </Container>
    )
};


export default forwardRef(LocationSchedules);