import React, {useEffect, useState} from "react";
import {TextField} from "@mui/material";
import FormatUtils from "../utils/FormatUtils";

export default function LocationOptionPrice({
                                                priceData,
                                                uniquePrice,
                                                uniquePriceValue,
                                                priceChangedHandler
                                            }) {
    const [price, setPrice] = useState(priceData.price);

    useEffect(() => {
        if (uniquePrice) {
            setPrice(uniquePriceValue);
        }
    }, [uniquePrice, uniquePriceValue]);

    useEffect(() => {
        // console.log(props.priceData);
        if (priceData) {
            setPrice(priceData.price);
        }
    }, [priceData]);

    useEffect(() => {
        priceData.price = price;
        priceChangedHandler(price);
    }, [price])

    return (<TextField
        type="number"
        margin="normal"
        size="small"
        fullWidth
        id="label"
        onChange={(e) => {
            let newPrice = parseFloat(e.target.value);
            if (newPrice < 0) {
                newPrice = 0;
            }
            if (isNaN(newPrice)) {
                newPrice = null;    // So it is compatible with isNotSet
            }
            setPrice(newPrice);
            // priceData.price = newPrice;
            // priceChangedHandler(e);
        }}
        value={price || ""}
        error={FormatUtils.isNotSet(price)}
    />)
}