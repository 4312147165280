import {Grid} from "@mui/material";
import React, {useContext} from "react";
import {useSelector} from "react-redux";
// import logo from "../../assets/logo-header.png";
import ReactHtmlParser from "react-html-parser"
import {convertNodeToElement} from 'react-html-parser';
import ConfigurationContext from "../../contexts/configurationContext";

export default function BrandedHeaderFooter({
                                                descriptionLogo,
                                                description
                                            }) {

    const applicationSettingsContext = useContext(ConfigurationContext);

    function getStoragePath() {
        return applicationSettingsContext == null ? '' : applicationSettingsContext.storage_path;
    }

    // const brandLogo = useSelector(state => state.locationGroup.locationGroupData?.logo_uri);
    // const descriptionTop = useSelector(state => state.locationGroup.locationGroupData?.description);
    // NB : max-width à mettre à la fin sinon ca marche pas en dev/prod => WTF
    // max-height:100px;height:auto;width:auto;max-width:90%;
    const imgStyle = 'max-height:100px;max-width:90%;height:auto;width:auto;';

    return <>
        {descriptionLogo &&
        <div
        >{ReactHtmlParser(descriptionLogo, {
            decodeEntities: true,
            transform: transformLogo
        })}</div>
        }
        {description &&
        <div>
            {ReactHtmlParser(description, {
                decodeEntities: true,
                transform: transformHeaderFooter
            })}
        </div>
        }
    </>

    function transformLogo(node, index) {
        if (node.type === 'tag' && node.name === 'img') {
            // NB : pour éviter de fusionner avec "undefined" grrr
            if (node.attribs.style) {
                node.attribs.style += imgStyle;
            } else {
                node.attribs.style = imgStyle;
            }

            // console.log('>>', node.attribs.src.substr(0, 4));

            if (node.attribs.src.substr(0, 4) != 'http' && node.attribs.src.substr(0, 4) != 'data' ) {
                node.attribs.src = getStoragePath() + node.attribs.src;
            } else {
                // console.log('COUCOU.');
            }


            return convertNodeToElement(node, index, transformLogo);
        }
    }

    function transformHeaderFooter(node, index) {
        if (node.type === 'tag' && node.name === 'img') {
            // console.log('>>', node.attribs.src.substr(0, 4));
            // console.log('headerfooter', node);
            if (node.attribs.style) {
                node.attribs.style += imgStyle;
            } else {
                node.attribs.style = imgStyle;
            }
            if (node.attribs.src.substr(0, 4) != 'http' && node.attribs.src.substr(0, 4) != 'data' ) {
                node.attribs.src = getStoragePath() + node.attribs.src;
            } else {
                // console.log('COUCOU.');
            }
            return convertNodeToElement(node, index, transformHeaderFooter);
        }
    }
}