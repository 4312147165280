import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {
    Box,
    Container,
    Grid,
    MenuItem,
    Select,
    Tabs,
} from "@mui/material";
import {useTranslation} from "react-i18next";
import Swal from "sweetalert2";
import {useOutletContext, useParams} from "react-router-dom";
import '../App.css';
import Tab from "@mui/material/Tab";

import AddCircleIcon from '@mui/icons-material/AddCircle';
import LocationServiceTab from "./LocationServiceTab";
import CarService from "../services/CarService";
import TireServiceStatic from "../services/TireServiceStatic";
import useFetch from "../hooks/useFetch";
import WarningIcon from '@mui/icons-material/Warning';
import FormatUtils from "../utils/FormatUtils";
import {ServiceType} from "../constants/ServiceType";
import LocationTireStorage from "./LocationTireStorage";

// TODO Get ServiceTypes from api
function LocationServices({
                              previous_btn,
                              next_btn,
                              changeStepFromContentHandler,
                              contentLoadedHandler,
                              isEditMode,
                              saveNeededHandler,
                              canceledSaveHandler,
                              contentSavedHandler,
                          }, ref) {
    const [apiUri] = useOutletContext();
    const {t} = useTranslation('common');

    // const defaultServiceTypeId = ServiceType.CARWASH;
    const [serviceTypeId, setServiceTypeId] = useState();
    const prevServiceTypeId = usePrevious(serviceTypeId);

    const defaultCurrency = 'CHF';
    const [currency, setCurrency] = useState(defaultCurrency);

    // const [tireStorage, setTireStorage] = useState();

    const [locationId, setLocationId] = useState(null);
    const [locationData, setLocationData] = useState(null);

    let params = useParams();

    // For tabs
    const [value, setValue] = React.useState(0);
    const tabChangedHandler = (event, newValue) => {
        setValue(newValue);
    };

    const [servicesData, setServicesData] = useState([]);
    const [tireStorageData, setTireStorageData] = useState({'type': null, 'list': []});

    let newDataCount = 0;

    const servicesDataRef = useRef(null);
    servicesDataRef.current = servicesData;

    const tireStorageDataRef = useRef(null);
    tireStorageDataRef.current = tireStorageData;

    const locationIdRef = useRef(null);
    locationIdRef.current = locationId;

    const serviceTypeIdRef = useRef(null);
    serviceTypeIdRef.current = serviceTypeId;

    const {call, callPost} = useFetch();

    useEffect(() => {
        if (params.locationId != null) {
            getFromServer(params.locationId);
        } else {

        }

        previous_btn.current.style.display = 'none';
        next_btn.current.addEventListener('click', nextClickedHandler);

        return () => {
            next_btn.current && next_btn.current.removeEventListener('click', nextClickedHandler);
        }
    }, [])


    // useEffect(() => {
    //     servicesDataRef.current.map(serviceData => {
    //         serviceData.currency = currency;
    //     });
    // }, [currency]);

    useEffect(() => {
        // console.log("TYPE : " + prevServiceType + '=>' + serviceType);
        // servicesDataRef.current.map(serviceData => {
        //     serviceData.service_type_id = serviceTypeId;
        // });

        // api chargée, mais pas encore de service
        if (prevServiceTypeId === undefined && serviceTypeId !== undefined && servicesData.length == 0) {
            addService();
        }

        // On passe de 1 à 2
        if (prevServiceTypeId == ServiceType.CARWASH && serviceTypeId == ServiceType.TIRES) {
            // On clean la liste et on rajoute des defaults
            servicesData.forEach(serviceData => {
                serviceData.prices = [];
                serviceData.options = [];
                addDefaultPricesForTires(serviceData);
            });
            refreshData();
        }
        // On passe de 2 à 1
        if (prevServiceTypeId == ServiceType.TIRES && serviceTypeId == ServiceType.CARWASH) {
            servicesData.forEach(serviceData => {
                serviceData.prices = [];
                serviceData.options = [];
                addDefaultPricesForCarwash(serviceData);
            });
            refreshData();
        }
    }, [serviceTypeId])

    useImperativeHandle(ref, () => ({
        save() {
            nextClickedHandler();
        }
    }));

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    function nextClickedHandler() {
        // console.log(servicesData);
        let data = {
            id: locationIdRef.current,
            services: servicesDataRef.current,
            tire_storage: tireStorageDataRef.current,
            // service_type_id: serviceTypeIdRef.current
        }

        // Check, tous les tarifs doivent être remplis
        // Check : pas de categories vides
        let errorNoCateg = false;
        let errorTireOrWheel = false;
        let errorQty = false;
        let errorServices = [];

        data.services.forEach(service => {
            if (service.service_type_id == ServiceType.TIRES) {
                if (!('tire_or_wheel' in service)) {
                    errorTireOrWheel = true;
                }
                if (!('tire_count' in service)) {
                    errorQty = true;
                }
            }

            if (service.prices.length == 0) {
                errorNoCateg = true;
            }

            if (!isServiceOk(service)) {
                if (errorServices.indexOf(service) == -1) {
                    errorServices.push(service);
                }
            }
        });

        if (errorTireOrWheel) {
            Swal.fire({
                text: t('location.services.tire.warning_tire_or_wheel'),
                icon: 'error',
                target: document.getElementById('swal_container'),
            });
            canceledSaveHandler();
            return
        }

        if (errorQty) {
            Swal.fire({
                text: t('location.services.tire.warning_tire_count'),
                icon: 'error',
                target: document.getElementById('swal_container'),
            });
            canceledSaveHandler();
            return
        }

        if (errorNoCateg) {
            Swal.fire({
                text: t('location.services.tire.warning_choose_category'),
                icon: 'error',
                target: document.getElementById('swal_container'),
            });
            canceledSaveHandler();
            return
        }

        if (errorServices.length > 0) {
            console.log(errorServices);
            let serviceStr = errorServices.map((service) => service.name).join(',');

            Swal.fire({
                text: t('location.services.error_null_price') + serviceStr,
                icon: 'error',
                target: document.getElementById('swal_container'),
            });
            canceledSaveHandler();
            return
        }

        // console.log('data', data);

        // Si roue / pneu : pneu / roue selectionné + quantité rempli
        sendToServer(data);
    }

    // TODO redondant
    async function getFromServer(id) {
        call(apiUri + 'location_get/' + id, (data) => {
            // TODO : si il y a un soucis dans ce callback, c'est une erreur au niveau du call qui va être levée. PAS TOP !
            setLocationData(data.location);
            setLocationId(data.location.id);

            // Pas encore de service, on en créé un par défaut
            // if (data.location.services.length === 0) {
            //     setServiceTypeId(defaultServiceTypeId);
            // } else {
            setServicesData(data.location.services);
            setServiceTypeId(data.location.service_type_id);
            servicesDataRef.current = data.location.services;

            setTireStorageData({
                type: data.location.tire_storage_type,
                list: data.location.tire_storage_ranges
            });

            // setCurrency(data.location.services[0].currency);
            // }

            contentLoadedHandler(data.location);
        });
    }

    //
    async function sendToServer(data) {
        callPost(apiUri + 'location_services', data, (resultData) => {
            Swal.fire({
                text: t('location.info.success'),
                icon: 'success',
                timer: 2000,
                target: document.getElementById('swal_container'),
            })
                .then(() => {
                        contentSavedHandler(2);
                    }
                );
        });
    }

    // Consolidate type car for all services
    function consolidateCarCategoryPrices(servicesData) {
        // With cars, 1 par type de voiture
        if (serviceTypeId == ServiceType.CARWASH) {
            servicesData.map(serviceData => {
                let keptPrices = [];
                CarService.carCategories.map(carData => {
                    let priceData = getServicePriceByLabel(serviceData, t(carData.label));
                    if (priceData == null) {
                        priceData = addCategoryPriceToModel(serviceData, t(carData.label), carData.icon, null, carData.id);
                    } else {
                        priceData.icon = carData.icon;
                    }

                    priceData.car_category_id = carData.id;
                    keptPrices.push(priceData);
                });
                serviceData.prices = keptPrices;
            });
        }
        refreshData();
    }

    function addDefaultPricesForCarwash(serviceData) {
        CarService.carCategories.forEach(carData => {
            addCategoryPriceToModel(serviceData, t(carData.label), carData.icon, null, carData.id);
        });
    }

    function addDefaultPricesForTires(serviceData) {
        for (let tireId = 1; tireId <= 3; tireId++) {
            let tireDef = TireServiceStatic.getCarCategoryById(tireId);
            addCategoryPriceToModel(serviceData, tireDef.label, null, null, null, tireId);
        }
    }

    function getServicePriceByLabel(serviceData, label) {
        const price = serviceData.prices.find(element => element.category_name == label);
        // console.log(label);
        // console.log(price);
        return price;
    }

    function getOptionPriceByLabel(optionData, label) {
        const price = optionData.prices.find(element => element.category_name == label);
        return price;
    }

    function getServicePriceByTireId(serviceData, tireId) {
        const price = serviceData.prices.find(element => element.tire_category_id == tireId);
        return price;
    }

    // From button
    function addServiceClickedHandler() {
        // Only for custom categories
        // console.log(serviceType);
        if (serviceTypeId != ServiceType.CARWASH) {
            Swal.fire({
                title: t('location.services.copy_last_service_proposition'),
                showDenyButton: true,
                // showCancelButton: true,
                confirmButtonText: t('common.yes'),
                denyButtonText: t('common.no'),
                target: document.getElementById('swal_container'),
            }).then((result) => {
                if (result.isConfirmed) {
                    addService(servicesData.slice(-1)[0]); // Copy last element
                } else if (result.isDenied) {
                    addService();
                }
            })
        } else {
            addService();
        }
    }

    function addService(copyFromService = null) {
        let newServiceData = createService(serviceTypeId, currency, copyFromService);
        servicesData.push(newServiceData);

        if (copyFromService == null) {
            if (serviceTypeId == ServiceType.CARWASH) {
                addDefaultPricesForCarwash(newServiceData);
            } else if (serviceTypeId == ServiceType.TIRES) {
                addDefaultPricesForTires(newServiceData);
            }
        }
        refreshData();
        // console.log("addService", newServiceData);
    }

    // New service
    function createService(serviceTypeId, currency, copyFromService = null) {
        let c = servicesData.length;
        newDataCount++;
        let newServiceData = {
            "id": "N" + newDataCount,
            "name": t('location.services.new_service_default_name') + " " + (c + 1),
            "description": "",
            // "service_type_id": serviceTypeId,
            "carwash_category_id": 1,
            "currency": currency,
            "prices": [],
            "options": []
        }

        // console.log("COPIED FROM", copyFromService);

        if (copyFromService != null) {
            if (locationData.service_type_id == ServiceType.TIRES) {
                newServiceData.tire_or_wheel = copyFromService.tire_or_wheel;
                newServiceData.tire_count = copyFromService.tire_count;
            }

            copyFromService.prices.forEach(servicePrice => {
                newDataCount++;
                newServiceData.prices.push({
                    category_name: servicePrice.category_name,
                    id: 'N' + newDataCount,
                    description: servicePrice.description,
                    car_category_id: servicePrice.car_category_id,
                    tire_category_id: servicePrice.tire_category_id
                })
            });
        } else {
            // Ajout d'une catégorie par défaut
            if (serviceTypeId != ServiceType.CARWASH && serviceTypeId != ServiceType.TIRES) {
                addCategoryHandler(newServiceData);
            }
        }

        return newServiceData;
    }

    function deleteServiceHandler(e, serviceData) {
        Swal.fire({
            title: t('location.services.delete_service_warning'),
            showDenyButton: true,
            // showCancelButton: true,
            confirmButtonText: t('common.yes'),
            denyButtonText: t('common.no'),
            target: document.getElementById('swal_container'),
        }).then((result) => {
            if (result.isConfirmed) {
                servicesData.splice(servicesData.indexOf(serviceData), 1);
                // First tab
                setValue(0);
                refreshData();
            } else if (result.isDenied) {
                return;
            }
        })
    }

    // Only Custom
    function addCategoryHandler(serviceData) {
        let len = serviceData.prices.length;
        let price = 0;

        // On reprend le prix du dernier
        if (len > 0) {
            let lastCategory = serviceData.prices[len - 1];
            price = lastCategory.price;
        }

        let label = t('location.services.new_category_default_name') + (len > 0 ? (" " + (len + 1)) : "");

        addCategoryPriceToModel(serviceData, label, null, price);
        refreshData();
    }

    // Only Custom
    function addCategoryPriceToModel(serviceData, label, icon, price, carCategoryId = null, tireCategoryId = null) {
        // To prices
        newDataCount++;

        let priceData = {
            "id": 'N' + newDataCount,
            "category_name": label,
            "icon": icon,
            "price": price,
            "car_category_id": carCategoryId,
            "tire_category_id": tireCategoryId,
            "tire_or_wheel": null
        }

        serviceData.prices.push(priceData);

        serviceData.options.map(option => {
            let optionPrice = 0;
            let len = option.prices.length;
            if (len > 0) {
                let lastCategory = option.prices[len - 1];
                optionPrice = lastCategory.price;
            }
            newDataCount++
            let newOptionPrice = {
                "id": 'N' + newDataCount,
                "category_name": label,
                "price": optionPrice,
                "car_category_id": carCategoryId,
                "tire_category_id": tireCategoryId
            };
            option.prices.push(newOptionPrice);
        });
        return priceData;
    }

    // Only Custom
    function deleteCategoryHandler(serviceData, priceData) {
        // To prices
        serviceData.prices.splice(serviceData.prices.indexOf(priceData), 1);

        // To optionPrices
        serviceData.options.map(option => {
            priceData = getOptionPriceByLabel(option, priceData.category_name);
            option.prices.splice(option.prices.indexOf(priceData), 1);
        });
        refreshData();
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    // From Button
    function addServiceOptionHandler(e, serviceData) {
        let c = serviceData.options.length;
        newDataCount++;

        let newOption = {
            "id": "N" + newDataCount,
            "name": t('location.options.default_option_label') + (c + 1),
            "description": t('location.options.default_option_desc'),
            "prices": []
        }

        // Prices
        serviceData.prices.map(priceData => {
            newDataCount++;
            newOption.prices.push({
                "id": "N" + newDataCount,
                "category_name": priceData.category_name,
                "price": 0,
                "car_category_id": priceData.car_category_id,
                "tire_category_id": priceData.tire_category_id,
            });
        });

        serviceData.options.push(newOption);

        refreshData();
        // let newServiceData = servicesData.slice(0);
        // setServicesData(newServiceData);
    }

    function deleteServiceOptionHandler(e, optionData, serviceOptionsData) {
        serviceOptionsData.splice(serviceOptionsData.indexOf(optionData), 1);
        refreshData();
    }

    function refreshData() {
        let newServiceData = servicesData.slice(0);
        setServicesData(newServiceData);
        saveNeededHandler(true);
    }

    function refreshTireStorageData() {
        // console.log(tireStorageData);
        let newTireStorageData = FormatUtils.cloneData(tireStorageData);
        setTireStorageData(newTireStorageData);
    }

    function tireSelectionChangedHandler(serviceData, values) {
        // console.log("tireSelectionChangedHandler", serviceData, values);

        if (values.length == 0) {
            Swal.fire({
                text: t('location.services.tire.warning_choose_category'),
                icon: 'warning',
                target: document.getElementById('swal_container'),
            });
        }

        // On synchronise le model
        // Valeurs en plus ?
        values.forEach(tireId => {
            let priceData = getServicePriceByTireId(serviceData, tireId);
            if (priceData == null) {
                addCategoryPriceToModel(
                    serviceData,
                    TireServiceStatic.getCarCategoryById(tireId).label,
                    null,
                    null,
                    null,
                    tireId
                );
            }
        })

        // Valeurs en moins ?
        let pricesToDelete = [];
        serviceData.prices.forEach(price => {
            if (values.indexOf(price.tire_category_id) == -1) {
                // Ce pneu n'est plus coché, on le supprime des prix
                pricesToDelete.push(price);
            }
        });

        pricesToDelete.forEach(price => {
            serviceData.prices.splice(serviceData.prices.indexOf(price), 1);
            serviceData.options.forEach(option => {
                option.prices.forEach(optionPrice => {
                    if (optionPrice.tire_category_id == price.tire_category_id) {
                        option.prices.splice(option.prices.indexOf(optionPrice), 1);
                    }
                })
            });
        });

        // Reorder by name
        serviceData.prices = serviceData.prices.sort((a, b) => {
            if (a.category_name < b.category_name) {
                return -1;
            }
            if (a.category_name > b.category_name) {
                return 1;
            }
            return 0;
        });
        refreshData();
    }

    // value = TireService.TireOrWheel
    function tireOrWheelChangedHandler(serviceData, value) {
        // console.log("tireOrWheelChangedHandler", serviceData, value);
        serviceData.tire_or_wheel = value.id;
        refreshData();
    }

    function isServiceOk(serviceData, returnArray = false) {
        // console.log('isServiceOk', serviceData);
        let errorPrice = false;

        // Prix du service
        serviceData.prices.forEach(priceData => {
            if (FormatUtils.isNotSet(priceData.price)) {
                errorPrice = true;
            }
        });

        // Prix des options
        serviceData.options.forEach(optionData => {
            optionData.prices.forEach(priceData => {
                if (FormatUtils.isNotSet(priceData.price)) {
                    errorPrice = true;
                }
            });
        });

        // console.log('errorServices=', errorServices, errorServices.length);
        return !errorPrice;
    }

    // console.log("tireStorageData.type", tireStorageData.type);

    return (
        <Container maxWidth={false}>
            {servicesData.length > 0 &&
            <>
                <Grid container>
                    {serviceTypeId == ServiceType.TIRES &&
                    //    Gardiennage
                    <Grid item sx={{
                        mt: 1,
                        ml: 2
                    }}>
                        {t('location.services.tire_storage.title') + ' '}
                        <Select
                            size="small"
                            value={tireStorageData.type == null ? 0 : 1}
                            onChange={
                                e => {
                                    if (e.target.value == '1') {
                                        tireStorageData.type = 'D';
                                        refreshTireStorageData();
                                    } else {
                                        tireStorageData.type = null;
                                        refreshTireStorageData();
                                    }
                                }
                            }
                        >
                            <MenuItem value="1">{t('common.yes')}</MenuItem>
                            <MenuItem value="0">{t('common.no')}</MenuItem>
                        </Select>
                    </Grid>
                    }
                </Grid>

                {tireStorageData.type != null &&
                <LocationTireStorage
                    tireStorageData={tireStorageData}
                    refreshTireStorageDataHandler={refreshTireStorageData}
                />
                }

                <Box sx={{width: '100%'}}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <Tabs
                            value={value}
                            onChange={tabChangedHandler}
                        >
                            {servicesData.map((serviceData, index) => {
                                let errorProps = {
                                    'icon': <></>,
                                    'iconPosition': 'end'
                                };

                                if (!isServiceOk(serviceData)) {
                                    errorProps.icon = <WarningIcon sx={{m: 0}} style={{'color': '#f44336'}}/>
                                }
                                return <Tab
                                    key={index}
                                    label={serviceData.name}
                                    {...a11yProps(index)}
                                    {...errorProps}
                                />
                            })
                            }
                            <Tab icon={<AddCircleIcon/>} iconPosition="start" wrapped
                                 onClick={addServiceClickedHandler}/>
                        </Tabs>
                    </Box>
                    {
                        servicesData.map((serviceData, index) => {
                            return <LocationServiceTab
                                key={index}
                                currentTabIndex={value}
                                index={index}
                                currency={currency}
                                serviceTypeId={serviceTypeId}
                                serviceData={serviceData}
                                canDelete={servicesData.length > 1}
                                deleteServiceHandler={deleteServiceHandler}
                                addCategoryHandler={addCategoryHandler}
                                deleteCategoryHandler={deleteCategoryHandler}
                                newOptionHandler={addServiceOptionHandler}
                                deleteOptionHandler={deleteServiceOptionHandler}
                                refreshDataHandler={refreshData}
                                tireSelectionChangedHandler={tireSelectionChangedHandler}
                                tireOrWheelChangedHandler={tireOrWheelChangedHandler}
                            />
                        })
                    }
                </Box>
            </>
            }
        </Container>
    )
};

export default forwardRef(LocationServices);