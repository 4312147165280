import React, {forwardRef, useEffect, useState} from 'react';
import {useOutletContext} from "react-router-dom";
import {useTranslation} from "react-i18next";
import useFetch from "../hooks/useFetch";

function ApplicationSettingsService({
                                        apiUri,
                                        setApplicationSettings,
                                    }) {
    const token = localStorage.getItem('accessToken');
    // const [apiUri] = useOutletContext();
    const {t} = useTranslation('common');

    const {call} = useFetch();

    useEffect(() => {
        getSettings();
    }, [])

    async function getSettings() {
        call(apiUri + 'application_settings', (resultData) => {
            setApplicationSettings(resultData.configuration_lines);
        });
    }
}

export default ApplicationSettingsService;