import {
    Button,
    Grid,
    Tooltip
} from "@mui/material";

import React, {useEffect} from "react";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";

// Selection du type de véhicule
function VehicleTypeSelector(props) {
    const classes = useStyles();
    const [hover, setHover] = React.useState(false);
    const [selected, setSelected] = React.useState(false);
    const {t} = useTranslation('common');
    useEffect(() => {
        setSelected(props.selected);
    }, [props.selected])

    function truncate(str, n) {
        return (str.length > n) ? str.slice(0, n - 1) + '...' : str;
    };

    return <Tooltip title={t(props.carData.label)} arrow>
        <Button
            className={classes.btn}
            sx={
                selected ? {
                    backgroundColor: 'orange', '&:hover': {
                        backgroundColor: 'orange'
                    }
                } : null
            }
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={() => {
                if (props.onClickHandler != null) {
                    props.onClickHandler();
                }
            }
            }
        >
            <>
                <Grid container direction="column" sx={{height: '100%'}}>
                    <Grid item xs={4} container justifyContent="center" alignItems="center"><img
                        src={props.carData.icon} className={classes.menuBtnImg}/></Grid>
                    <Grid item xs={8} container justifyContent="center" alignItems="center">
                        {/*<Typography>*/}
                        {truncate(t(props.carData.label), 16)}
                        {/*</Typography>*/}
                    </Grid>
                </Grid>
            </>

        </Button>
    </Tooltip>
}

export default VehicleTypeSelector;

const useStyles = makeStyles((theme) => ({
    btn: {
        height: '100px',
        width: '100px'
    },
    menuBtnImg: {
        height: '25px'
    }
}));