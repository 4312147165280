import {Button, Grid, Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import React from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

export default function BrandedDisabledContent({}) {
    const {t} = useTranslation('common');

    return <Grid container justifyContent="center" >
        <Stack justifyContent="center">
            <Typography>{t('branded.unavailable_showcase')}</Typography>
            <Grid container justifyContent="center" mt={4}>
                <Link to="/lgs"> <Button
                    variant="contained"
                    color="yellow">
                    {t('branded.back_to_kuc')}
                </Button>
                </Link>
            </Grid>
        </Stack>
    </Grid>
}