import React, {useEffect, useState} from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import {useTranslation} from "react-i18next";
import {useLocation, useOutletContext} from "react-router-dom";
import {Box, Container} from "@mui/material";
import SubscriptionCheckoutForm from "./SubscriptionCheckoutForm";
import useFetch from "../../hooks/useFetch";
import FormatUtils from "../../utils/FormatUtils";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK_TEST);

let stripePromise;
if (process.env.REACT_APP_STRIPE_TEST_MODE == "true") {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK_TEST);
} else {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);
}

export default function StripeSubscription({}) {
    const {t} = useTranslation('common');
    const [clientSecret, setClientSecret] = useState(null);
    const [subIntentData, setSubIntentData] = useState(null);
    const {callPost, isSending} = useFetch();
    const [apiUri] = useOutletContext();

    useEffect(() => {
        requestCreateSubscription();
    }, []);

    async function requestCreateSubscription() {
        callPost(apiUri + 'subscribe', null, (resultData) => {
            setClientSecret(resultData.clientSecret);
            setSubIntentData(resultData);
        });
    }

    const appearance = {
        theme: 'night',
    };

    return (
        <Container sx={{
            maxWidth: '500px !important'
        }}>
            {subIntentData &&
            <Box
                mt={2}
                mb={2}
                p={2}
                sx={{
                    backgroundColor: '#fbc70f',
                    color: 'black'
                }}>
                {t('showcase.step5.subscribe_amount')} {FormatUtils.formatStripeAmount(subIntentData.amount, true)}
            </Box>
            }
            {clientSecret &&
            <Elements stripe={stripePromise} options={{
                clientSecret: clientSecret,
                appearance
            }}>
                <SubscriptionCheckoutForm
                    clientSecret={clientSecret}
                />
            </Elements>
            }
        </Container>
    );
};