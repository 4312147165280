import React, {useContext, useEffect, useState} from 'react';
import logo from '../assets/logo-header.png';
import {Box, Button, Container, Grid, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {Link, useOutletContext, useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import useFetch from "../hooks/useFetch";
import MobileContext from "../contexts/mobileContext";
import {useSelector} from "react-redux";
import {getIsBranded} from "../features/branded/locationGroupSlice";
import BrandedKucFooter from "../features/branded/BrandedKucFooter";
import ReactHtmlParser from "react-html-parser";
import BrandedHeaderFooter from "../features/branded/BrandedHeaderFooter";

export default function EmailConfirmation() {
    const classes = useStyles();

    const [apiUri] = useOutletContext();

    const {t} = useTranslation('common');

    const [searchParams] = useSearchParams();
    const [firstRender, setFirstRender] = useState(false);

    // sending / success / failed
    const [confirmationState, setConfirmationState] = useState('sending');
    const {call} = useFetch();

    const mobileContext = useContext(MobileContext);
    const isMobile = mobileContext.isMobile;

    const isBranded = useSelector(getIsBranded);
    const brandDescriptionLogo = useSelector(state => state.locationGroup.locationGroupData?.description_logo);

    useEffect(() => {
        if (!firstRender) {
            let email = searchParams.get("email");
            let key = searchParams.get("key");
            sendConfirmation({email, key});
        }
        setFirstRender(true);
    }, [firstRender])

    async function sendConfirmation(data) {
        call(apiUri + 'confirm_email', (resultData) => {
            setConfirmationState('success');
        }, 'POST', data, true, (failData) => {
            setConfirmationState('failed');
        });
    }

    return (
        <Grid container
              alignItems="center"
              justifyContent="center"
              style={
                  {
                      height: isMobile ? '100%' : ''
                  }
              }
        >
            <Container maxWidth='sm' className={classes.loginContainer} sx={{borderRadius: '16px'}} disableGutters>
                {brandDescriptionLogo ?
                    <BrandedHeaderFooter descriptionLogo={brandDescriptionLogo}/>
                    :
                    <img src={logo} className="logo"/>
                }
                <Container>
                    <Typography align='center' variant='subtitle1'>
                        {t('email_confirmation.' + confirmationState)}

                        {confirmationState == 'success' &&
                            <>
                                <Link to="/login" style={{textDecoration: 'none'}}>
                                    <Button
                                        margin="normal"
                                        fullWidth
                                        color="yellow"
                                        variant="contained"
                                        sx={{mt: '15px', mb: '20px'}}
                                    >
                                        {t('login.btn_login')}
                                    </Button>
                                </Link>
                            </>
                        }
                    </Typography>
                    <Box width="100%" sx={{mt: 1}}/>
                </Container>
            </Container>
            {isBranded &&
                <div style={{
                    position: 'absolute',
                    bottom: '0px',
                    width: '100%'
                }}>
                    <BrandedKucFooter/>
                </div>
            }
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        // backgroundColor: '#49545d',  // Gris clair 1
        background: 'linear-gradient(#49545D, #1C2326)'
    },
    loginContainer: {
        backgroundColor: '#292e32'
    },
    registerContainer: {
        backgroundColor: '#1d2124', // Gris foncé 1
        borderTop: 'solid #373B3E',
        paddingTop: '20px',
        paddingBottom: '20px'
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    loginBtn: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: '#fbc70f',
        color: 'black',
    },
    white: {
        color: 'white'
    },
    yellow: {
        color: '#fbc70f'
    },
    centerText: {
        alignItems: "center",
        display: "flex"
    }
}));