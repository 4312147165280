import {createSelector, createSlice} from '@reduxjs/toolkit'

var initialState = {
    'needFetch': false,
    'accountData': null
};

// try {
//     // TODO Utilise le loadFromSessionStorage ?
//     initialState = sessionStorage.getItem('session') ? JSON.parse(sessionStorage.getItem('session')) : {};
// } catch (error) {
//     console.log('getError', error)
// }

// TODO NB : l'appel à l'api ne se fait pas ici ( trop complexe, à lier avec mon systeme d'api call actuel, avec de l'async.. )
// Avec des middlewares..
// https://redux.js.org/tutorials/essentials/part-5-async-logic


export const accountSlice = createSlice({
    name: 'account',
    initialState: initialState,
    reducers: {
        fetchAccountNeeded: (state) => {
            let newState = {...state};
            newState.needFetch = true;
            return newState;
        },
        accountDataFetched: (state, action) => {
            let newState = {...state};
            newState.accountData = action.payload;
            newState.needFetch = false;
            return newState;
        }
    }
})

// const selfState = state => state;
// export const getAccount = createSelector(
//     [selfState],
//     (state) => {
//         return state.account;
//     });

// Action creators are generated for each case reducer function
export const {fetchAccountNeeded, accountDataFetched} = accountSlice.actions

export default accountSlice.reducer