import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    Box, Button, CircularProgress, Container, Grid, Modal
} from "@mui/material";
import {
    useLocation, useNavigate,
    useOutletContext,
    useSearchParams
} from "react-router-dom";

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import {useTranslation} from "react-i18next";
import ServiceSuccess from "../../components/ServiceSuccess";
import ServiceStepStripe from "../../components/ServiceStepStripe";
import ServiceStepPaymentMethods from "../../components/ServiceStepPaymentMethods";
import useFetch from "../../hooks/useFetch";
import MobileContext from "../../contexts/mobileContext";
import ShowcaseStep1 from "./ShowcaseStep1";
import ShowcaseStepper from "./ShowcaseStepper";
import ShowcaseStep2 from "./ShowcaseStep2";
import ShowcaseStep3 from "./ShowcaseStep3";
import ShowcaseStep4 from "./ShowcaseStep4";
import {fetchAccountNeeded} from "../account/accountSlice";
import {useDispatch} from "react-redux";

export default function ShowcasePannel({
                                           step = -1,
                                           isCreationCallback = false
                                       }) {
    // Steps
    const [activeStep, setActiveStep] = useState(step);    // Step de 0 à 3 , -1 => A déterminer
    const [contentLoaded, setContentLoaded] = useState(false);

    const previousBtnRef = useRef();
    const nextBtnRef = useRef();

    // When changing step, there is a save, then we switch step
    const wantedStep = useRef();

    const childRef = useRef();

    const [apiUri] = useOutletContext();
    const {t} = useTranslation('common');

    const [label, setLabel] = useState('showcase.default_step_title');

    const [userData, setUserData] = useState(null);

    const [locationGroupData, setLocationGroupData] = useState(null);
    const [subscriptionData, setSubscriptionData] = useState(null);
    const [currentStripeProduct, setCurrentStripeProduct] = useState(null);
    const [dataLoaded, setDataLoaded] = useState(false);

    const {call, callPost, isSending} = useFetch();

    // Delay => Si retour de callback, il y a une attente de qq secondes pour que le weebhook soit bien passé
    const [delayState, setDelayState] = useState('disabled');   // disabled / in_progress / done
    const delayTime = 10000;
    const navigate = useNavigate();

    const dispatch = useDispatch();

    function contentLoadedHandler() {
        setContentLoaded(true);
    }

    useEffect(() => {
        accountGet();
        if (isCreationCallback) {
            startDelay();
        } else {
            getFromServer();
        }
    }, []);

    useEffect(() => {
        if (delayState == 'done') {
            getFromServer();
            // On redirige, ceci va pas tout recharger.. ( pour ca qu'on fait le getFromServer qd meme )
            navigate('/showcase/payment');
        }
    }, [delayState]);

    // NB : chaque fois que les données d'abo change, on recharge l'account, pour savoir si le warning de boutique a changé
    // Cas d'un warning à cause d'un past_due par exemple, qu'on régularise
    useEffect(() => {
        if (subscriptionData != null) {
            dispatch(fetchAccountNeeded());
        }
    }, [subscriptionData])

    function startDelay() {
        setDelayState('in_progress');
        setTimeout(function () {
            setDelayState('done');
        }, delayTime);
    }

    function isEditMode() {
        if (locationGroupData == null) {
            return false;
        }
        if (locationGroupData.current_step != null) {
            return false;
        }
        return true;
    }

    // TODO Repetition avec Profile.js
    async function accountGet() {
        call(apiUri + 'account', (data) => {
            setUserData(data.user);
        });
    }

    async function getFromServer() {
        call(apiUri + 'get_provider_showcase', (resultData) => {
            let newlocationGroupData = resultData.message.location_group;
            // On force le step provenant de l'url , si besoin
            if (newlocationGroupData == null) {
                setActiveStep(0);
            } else if (newlocationGroupData.current_step != null) {
                setActiveStep(newlocationGroupData.current_step);
            } else {
                // Edit mode => on va en step 3
                setActiveStep(3);
            }
            setLocationGroupData(newlocationGroupData);
            setSubscriptionData(resultData.message.subscription);
            setCurrentStripeProduct(resultData.message.current_product);
            setDataLoaded(true);
        });
    }

    // User clicked on a different step => Notify current content
    function changeStepFromMenuHandler(e, stepValue) {
        // console.log('changeStepFromMenuHandler stepValue', stepValue);
        wantedStep.current = stepValue;
        if (activeStep == 0) {
            gotoWantedStep(0);
        } else {
            childRef.current.saveFromExternal();
        }
    }

    function getCurrentStepTag(stepValue) {
        // console.log('getCurrentStepTag', stepValue, tireStep);
        let tag;
        switch (stepValue) {
            case 0 :
                tag = <ShowcaseStep1/>;
                break;
            case 1 :
                tag = <ShowcaseStep2/>;
                break;
            case 2 :
                tag = <ShowcaseStep3/>;
                break;
            case 3 :
                tag = <ShowcaseStep4/>;
                break;
        }
        return tag;
    }

    function gotoWantedStep(defaultStep) {
        if (wantedStep.current != null) {
            setActiveStep(wantedStep.current);
            wantedStep.current = null;
        } else {
            setActiveStep(defaultStep);
        }
    }

    function validateStep1() {
        gotoWantedStep(1);
    }

    // Data est un array de ce qui a changé à cette étape
    // callBackInsteadOfRedirect si rempli, c'est cette fonction qui est appelée plutot que la redirection
    function validateStep2(data, callBackInsteadOfRedirect = null) {
        if (!isEditMode()) {
            if (callBackInsteadOfRedirect == null) {
                data.current_step = 2;  // On passera en étape 3
            } else {
                data.current_step = 1;  // On reste en étape 2
            }
        }
        // TODO Gérer les erreurs, fusionner avec validateStep3
        callPost(apiUri + 'post_provider_showcase', data, (resultData => {
            setLocationGroupData(resultData.location_group);
            if (callBackInsteadOfRedirect == null) {
                gotoWantedStep(2);
            } else {
                callBackInsteadOfRedirect(resultData.location_group);
            }
        }));
    }

    function validateStep3(data) {
        if (!isEditMode()) {
            data.current_step = 3;  // On passe en étape 4
        }
        // TODO Gérer les erreurs, fusionner avec validateStep2
        callPost(apiUri + 'post_provider_showcase', data, (resultData => {
            setLocationGroupData(resultData.location_group);
            gotoWantedStep(3);
        }));
    }

    // NB : ya rien à valider, l'étape se valide par le paiement
    function validateStep4(data) {
        if (!isEditMode()) {
            data.current_step = 3;  // On reste en étape 4
        }
        gotoWantedStep(3);

        // NB : ya rien à faire ici, on garde juste la compatibilité avec le steepper
        // callPost(apiUri + 'post_provider_showcase', data, (resultData => {
        //     setLocationGroupData(resultData.location_group);
        //     gotoWantedStep(3);
        // }));
    }

    //
    function subscriptionDataChangedHandler(newData) {
        setSubscriptionData(newData);
    }

    function previousClickedHandler() {
        setActiveStep(activeStep - 1);
    }

    return (
        <div style={{
            width: '100%'
        }}>
            <Box sx={
                {}
            }>
                {/* Header */}
                <Grid container justifyContent="flex-end">
                    <Grid item sx={{flexGrow: 1}}>
                        <Grid container justifyContent="center">
                            <h3>{
                                t(label)
                            }</h3>
                        </Grid>
                    </Grid>
                </Grid>

                {userData && dataLoaded ?
                    <>
                        <Box>
                            <ShowcaseStepper
                                editMode={isEditMode()}
                                activeStep={activeStep}
                                changeStepHandler={changeStepFromMenuHandler}
                            />
                            {
                                React.cloneElement(getCurrentStepTag(activeStep),
                                    {
                                        previousBtn: previousBtnRef,
                                        nextBtn: nextBtnRef,
                                        editMode: isEditMode(),
                                        contentLoadedHandler: contentLoadedHandler,
                                        setLabel: setLabel,
                                        userData: userData,
                                        locationGroupData: locationGroupData,
                                        subscriptionData: subscriptionData,
                                        currentStripeProduct: currentStripeProduct,
                                        validateStep1: validateStep1,
                                        validateStep2: validateStep2,
                                        validateStep3: validateStep3,
                                        validateStep4: validateStep4,
                                        subscriptionDataChangedHandler: subscriptionDataChangedHandler,
                                        ref: childRef,
                                    })
                            }
                        </Box>
                        <Grid container justifyContent="center">
                            <Button ref={previousBtnRef}
                                    color="yellow"
                                    disabled={isSending}
                                    onClick={(e) => previousClickedHandler()}>
                                <NavigateBeforeIcon sx={
                                    {
                                        color: 'orange',
                                        fontSize: 60,
                                    }}/>
                                {t('common.previous_step')}
                            </Button>
                            {isEditMode() ?
                                <Button
                                    ref={nextBtnRef}
                                    disabled={isSending}
                                    variant="contained"
                                    color="yellow"
                                    sx={{mt: '15px', mb: '20px'}}
                                >
                                    {t('common.save')}
                                </Button>
                                :
                                <Button
                                    ref={nextBtnRef}
                                    disabled={isSending}
                                    color="yellow">

                                    {t('common.next_step')}
                                    <NavigateNextIcon sx={
                                        {
                                            color: 'orange',
                                            fontSize: 60,
                                        }}/>
                                </Button>
                            }
                        </Grid>
                    </> :
                    <Grid container justifyContent="center">
                        <CircularProgress/>
                    </Grid>
                }
            </Box>
        </div>
    );
}