import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    Box, Button, Grid, Modal
} from "@mui/material";
import {useLocation, useNavigate, useOutletContext, useParams} from "react-router-dom";

import btnClose from "../assets/buttons/close/close-nobackground.svg";
import btnCloseOver from "../assets/buttons/close/close-nobackground.svg";
import ImageButton from "./ImageButton";

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import {useTranslation} from "react-i18next";
import VehicleStepper from "./VehicleStepper";
import MobileContext from "../contexts/mobileContext";

import VehicleStep1 from "./VehicleStep1";
import VehicleStep2 from "./VehicleStep2";

export default function VehicleModal({
                                         step = 0,
                                         children = null,
                                         standaloneAdd = false,
                                         standaloneVehicleUpdatedHandler = null
                                     }) {
    let params = useParams();

    const location = useLocation();

    const [activeStep, setActiveStep] = useState(parseInt(step));

    const [contentLoaded, setContentLoaded] = useState(false);
    const [vehicleData, setVehicleData] = useState(null);

    const previousBtnRef = useRef();
    const nextBtnRef = useRef();

    const childRef = useRef();

    const navigate = useNavigate();

    const [apiUri, vehicleUpdatedHandler] = useOutletContext();
    const {t} = useTranslation('common');
    const mobileContext = useContext(MobileContext);
    const isMobile = mobileContext.isMobile;

    const [standAloneChildren, setStandAloneChildren] = useState(null);
    const [standAloneNewVehicleId, setStandAloneNewVehicleId] = useState();

    useEffect(() => {
        if (standaloneAdd) {
            setActiveStep(0);
            setStandAloneChildren(<VehicleStep1/>);
        }
    }, [standaloneAdd])

    useEffect(() => {
        setActiveStep(parseInt(step));
    }, [step])

    // TODO depend de là ou on s'est arrété ( donc on doit récupérer le contenu de l'appel api )
    function isEditMode() {
        if (standaloneAdd) {
            return false;
        }

        if (
            location.pathname == '/vehicle/add' ||
            location.pathname.startsWith('/vehicle/add_step2')
        ) {
            return false;
        }
        return true;
    }

    function contentLoadedHandler(locationData) {
        setContentLoaded(true);
        setVehicleData(locationData);
    }

    // User clicked on a different step => Notify current content
    function changeStepFromMenuHandler(e, stepValue) {
        childRef.current.wantChangeTab(stepValue);
    }

    // Current content want to switch step
    function changeStepFromContentHandler(stepValue, newVehicleId = null) {
        // TODO Pas top , stocker les routes dans un tableau à part en commun avec la définition ?
        let path = null;
        let vehicleId = null;
        if (!standaloneAdd) {
            vehicleId = params.vehicleId;
            if (newVehicleId != null) {
                vehicleId = newVehicleId;
            }
        } else {
            if (newVehicleId) {
                setStandAloneNewVehicleId(newVehicleId);
                vehicleId = newVehicleId;
            } else {
                vehicleId = standAloneNewVehicleId;
            }
        }

        if (vehicleId == null) {
            console.log("No current vehicleId");
            return;
        }

        if (standaloneAdd) {
            switch (stepValue) {
                case -1:
                    standaloneVehicleUpdatedHandler(vehicleId);
                    break;
                case 0:
                    setActiveStep(stepValue);
                    setStandAloneChildren(<VehicleStep1/>);
                    break;
                case 1:
                    setActiveStep(stepValue);
                    setStandAloneChildren(<VehicleStep2 standaloneVehicleId={newVehicleId}/>)
                    break;
            }
        } else {
            switch (stepValue) {
                case -1:
                    path = '/vehicle/';
                    vehicleUpdatedHandler();
                    break;
                case 0 :
                    path = '/vehicle/step1/' + vehicleId;
                    break;
                case 1:
                    if (newVehicleId != null) {
                        path = '/vehicle/add_step2/' + vehicleId;
                    } else {
                        path = '/vehicle/step2/' + vehicleId;
                    }
                    break;
                default:
                    console.log("Unknown step : " + stepValue);
                    return;
            }

            // console.log("Navigating to " + path);
            navigate(path);
        }
    }

    function closeButtonHandler() {
        if (standaloneAdd) {
            standaloneVehicleUpdatedHandler();
        } else {
            vehicleUpdatedHandler();
        }
    }

    return (
        <Modal
            open={true}
            style={{
                overflowY: 'scroll',
                // marginTop : '50vh',
                // display:'flex',
                // alignItems : 'center',
                // justifyContent : 'center'
            }}
            disableEnforceFocus
        >
            <div style={isMobile ? style_mobile : style}>
                <Box sx={styleInner}>
                    <Grid container justifyContent="flex-end">
                        <Grid item sx={{flexGrow: 1}}>
                            <Grid container justifyContent="center">
                                <h3>{
                                    contentLoaded && (
                                        vehicleData == null ?
                                            t('vehicle.modal_default_title')
                                            :
                                            vehicleData.name)
                                }</h3>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <ImageButton src={btnClose} srcOver={btnCloseOver} onClickHandler={closeButtonHandler}/>
                        </Grid>
                    </Grid>
                    <Box>
                        {contentLoaded &&
                        <VehicleStepper activeStep={activeStep} editMode={isEditMode()}
                                        changeStepHandler={changeStepFromMenuHandler}/>
                        }
                        {
                            (children || standAloneChildren) &&
                            React.cloneElement(children || standAloneChildren,
                                {
                                    previous_btn: previousBtnRef,
                                    next_btn: nextBtnRef,
                                    isEditMode: isEditMode(),
                                    changeStepFromContentHandler: changeStepFromContentHandler,
                                    contentLoadedHandler: contentLoadedHandler,
                                    ref: childRef
                                }
                            )}
                    </Box>

                    <Grid container justifyContent="center">
                        <Button ref={previousBtnRef}>
                            <NavigateBeforeIcon sx={
                                {
                                    color: 'orange',
                                    fontSize: 60,
                                }}/>
                        </Button>
                        {isEditMode() ?
                            <Button
                                ref={nextBtnRef}
                                variant="contained"
                                color="yellow"
                                sx={{mt: '15px', mb: '20px'}}
                            >
                                {t('common.save')}
                            </Button>
                            :
                            <Button ref={nextBtnRef}
                                    color="yellow">

                                {t('common.next_step')}
                                <NavigateNextIcon sx={
                                    {
                                        color: 'orange',
                                        fontSize: 60,
                                    }}/>
                            </Button>
                        }
                    </Grid>
                    <Box width="100%" sx={{height: '50px'}}/>
                </Box>
            </div>
        </Modal>
    );
}

const style = {
    position: 'absolute',
    top: '2.5%',
    bottom: '2.5%',
    left: '2.5%',
    right: '2.5%',
};

const style_mobile = {
    position: 'absolute',
    top: '0px',
    bottom: '0px',
    left: '0px',
    right: '0px',
}

const styleInner = {
    bgcolor: 'background.paper',
    border: '2px solid #000',
    minHeight: '100%'
}