import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Step, StepLabel, Stepper, Tabs} from "@mui/material";
import {withStyles} from "@mui/styles";
import Tab from "@mui/material/Tab";

function ShowcaseStepper({
                            activeStep,
                            classes,
                            editMode,
                            changeStepHandler
                        }) {
    const {t} = useTranslation('common');
    const steps = [
        t('showcase.step1.step_title'),
        t('showcase.step2.step_title'),
        t('showcase.step3.step_title'),
        t('showcase.step4.step_title'),
    ];

    // Pas ouf, quand on est sans le step 1, on décale l'active step du composant
    function getStepIndex() {
        return activeStep;
    }

    return (
        <>
            {
                editMode ?
                    <Tabs
                        value={activeStep}
                        onChange={changeStepHandler}
                        textColor="inherit"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                    >
                        {steps.map((label, index) => (
                            !(index == 0 && false) &&
                            <Tab key={index} label={label}/>
                        ))}

                    </Tabs>
                    :
                    <Stepper activeStep={getStepIndex()} alternativeLabel>
                        {steps.map((label, index) => (
                            !(index == 0 && false) &&
                            <Step key={index}>
                                <StepLabel StepIconProps={{
                                    classes: {
                                        active: classes.icon,
                                        completed: classes.icon,
                                    }
                                }}>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
            }
        </>
    );
}

const styles = theme => ({
    icon: {
        color: "orange !important"
    }
});

export default withStyles(styles)(ShowcaseStepper);