import React, {forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState} from "react";
import {
    Box, Button,
    Container, Grid, Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Navigate, useOutletContext} from "react-router-dom";
import '../../App.css';
import useFetch from "../../hooks/useFetch";
import {Editor} from "@tinymce/tinymce-react";
import Swal from "sweetalert2";
import ConfigurationContext from "../../contexts/configurationContext";
import ShowcaseEditor from "./ShowcaseEditor";


function ShowcaseStep2({
                           previousBtn,
                           nextBtn,
                           contentLoadedHandler,
                           setLabel,
                           validateStep2,
                           locationGroupData,
                           editMode
                       }, ref) {
    const {t} = useTranslation('common');

    const [loaded, setLoaded] = useState(false);
    const editorLogoRef = useRef(null);
    const editorDescriptionRef = useRef(null);
    const editorFooterRef = useRef(null);
    const applicationSettingsContext = useContext(ConfigurationContext);

    useEffect(() => {
        setLabel('showcase.step2.step_title');
        previousBtn.current.style.display = editMode ? 'none' : '';
        nextBtn.current.style.display = '';
        nextBtn.current.addEventListener('click', nextClickedHandler);
        contentLoadedHandler();
        setLoaded(true);
        return () => {
            if (nextBtn.current) {
                nextBtn.current.removeEventListener('click', nextClickedHandler);
            }
        }
    }, [])

    function getStoragePath() {
        return applicationSettingsContext == null ? '' : applicationSettingsContext.storage_path;
    }

    // Depuis le bouton Next
    function nextClickedHandler() {
        save();
    }

    // Depuis l'extérieur ( stepper )
    useImperativeHandle(ref, () => ({
        saveFromExternal() {
            save();
        },
    }));

    function save(callBackInsteadOfRedirect = null) {
        validateStep2({
            'description_logo': editorLogoRef.current.getContent(),
            'description_top': editorDescriptionRef.current.getContent(),
            'description_bottom': editorFooterRef.current.getContent(),
        }, callBackInsteadOfRedirect);
    }

    function getShowcasePath(locationGroupData) {
        return applicationSettingsContext == null ? '' : (applicationSettingsContext.client_path + '/lg/' + locationGroupData.slug);
    }

    function previewClickedHandler(e) {
        save((locationGroupData) => {
            window.open(getShowcasePath(locationGroupData));
        });
    }

    return (
        <Container>
            {loaded && <Typography component="div" sx={{
                mt: 5
            }}>
                <Grid container justifyContent="center">
                    <Button
                        color="yellow"
                        variant="contained"
                        style={{}}
                        onClick={previewClickedHandler}>
                        {t('showcase.preview_button')}
                    </Button>
                </Grid>
                <ShowcaseEditor
                    title={t('showcase.step2.logo_title')}
                    defaultValue={t('showcase.step2.logo_default_value')}
                    value={locationGroupData?.description_logo}
                    storagePath={getStoragePath()}
                    ref={editorLogoRef}
                    height={200}
                />
                <br/>
                <ShowcaseEditor
                    title={t('showcase.step2.header_title')}
                    defaultValue={t('showcase.step2.header_default_value')}
                    value={locationGroupData?.description}
                    storagePath={getStoragePath()}
                    ref={editorDescriptionRef}
                    height={300}
                />
                <br/>
                <ShowcaseEditor
                    title={t('showcase.step2.footer_title')}
                    defaultValue={t('showcase.step2.footer_default_value')}
                    value={locationGroupData?.description_bottom}
                    storagePath={getStoragePath()}
                    ref={editorFooterRef}
                    height={300}
                />
                <br/>
                <Grid container justifyContent="center">
                    <Button
                        color="yellow"
                        variant="contained"
                        style={{}}
                        onClick={previewClickedHandler}>
                        {t('showcase.preview_button')}
                    </Button>
                </Grid>
            </Typography>
            }
        </Container>
    )
};

export default forwardRef(ShowcaseStep2);