import React, {useEffect, useRef, useState} from "react";
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid, InputLabel,
    Link, MenuItem, OutlinedInput,
    Radio,
    RadioGroup, Select,
    TextField
} from "@mui/material";
import LocationCarItem from "./LocationCarItem";
import LocationTireItem from "./LocationTireItem";
import {useTranslation} from "react-i18next";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import LocationCustomItem from "./LocationCustomItem";
import LocationServiceOptions from "./LocationServiceOptions";
import TireListSelector from "./TireListSelector";
import TireOrWheelSelector from "./TireOrWheelSelector";
import FormatUtils from "../utils/FormatUtils";
import {ServiceType} from "../constants/ServiceType";

export default function LocationServiceTab({
                                               index,
                                               currency,
                                               currentTabIndex,
                                               serviceTypeId,
                                               canDelete,
                                               deleteServiceHandler,
                                               addCategoryHandler,
                                               deleteCategoryHandler,
                                               labelChangedHandler,
                                               serviceData,
                                               newOptionHandler,
                                               deleteOptionHandler,
                                               refreshDataHandler,
                                               tireSelectionChangedHandler,
                                               tireOrWheelChangedHandler,
                                               ...other
                                           }) {
    const maxCategoryCount = 12;
    const defaultUniquePrice = false;

    const {t} = useTranslation('common');

    const [uniquePrice, setUniquePrice] = useState(defaultUniquePrice);
    const [uniquePriceValue, setUniquePriceValue] = useState(0);

    const [canAddCategory, setCanAddCategory] = useState(false);

    useEffect(() => {
        if (serviceTypeId == ServiceType.CARWASH || serviceTypeId == ServiceType.TIRES) {
            setCanAddCategory(false);
        } else {
            setCanAddCategory(true);
        }
    }, [serviceTypeId])

    useEffect(() => {
        // console.log("LocationServiceTab serviceData:", serviceData);
    }, [serviceData])

    function deleteServiceLocalHandler(e) {
        deleteServiceHandler(e, serviceData);
    }

    function priceChangedHandler(e) {
        //
        setUniquePriceValue(e.target.value);
        refreshDataHandler();
    }

    function newOptionLocalHandler(e) {
        newOptionHandler(e, serviceData);
    }

    function tireSelectionChangedLocalHandler(values) {
        tireSelectionChangedHandler(serviceData, values);
    }

    function tireOrWheelChangedLocalHandler(value) {
        tireOrWheelChangedHandler(serviceData, value);
    }


    function getTireCategoriesChecked() {
        let values = [];
        // return values;
        serviceData.prices.forEach(price => {
            values.push(price.tire_category_id);
        })
        // console.log(values);
        return values;
    }

    return (
        <div
            role="tabpanel"
            hidden={currentTabIndex !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {currentTabIndex === index && (
                <Box sx={{p: 3}}>
                    {
                        serviceTypeId == ServiceType.TIRES &&
                        <>
                            <TireOrWheelSelector
                                selectedValue={serviceData.tire_or_wheel}
                                selectionChangedHandler={tireOrWheelChangedLocalHandler}/>
                            <br/>
                            <FormControl fullWidth>
                                <InputLabel
                                    shrink>{t('location.services.tire.tire_count_title')}</InputLabel>
                                <Select
                                    required={true}
                                    input={<OutlinedInput notched
                                                          label={t('location.services.tire.tire_count_title')}/>}
                                    size="small"
                                    value={serviceData.tire_count || ''}
                                    onChange={(e) => {
                                        serviceData.tire_count = e.target.value;
                                        refreshDataHandler();
                                    }}
                                >
                                    <MenuItem
                                        value="2">2</MenuItem>
                                    <MenuItem
                                        value="4">4</MenuItem>
                                </Select>
                            </FormControl>
                        </>
                    }

                    <TextField
                        margin="normal"
                        fullWidth
                        id="label"
                        label={t('location.services.service_label')}
                        value={serviceData.name}
                        inputProps={{maxLength: 50}}
                        onChange={(e) => {
                            serviceData.name = e.target.value;
                            refreshDataHandler();
                        }}
                        error={FormatUtils.isNotSet(serviceData.name)}
                    />
                    <TextField
                        margin="normal"
                        multiline
                        rows={2}
                        fullWidth
                        id="label"
                        label={t('location.services.service_description')}
                        value={serviceData.description}
                        onChange={(e) => {
                            serviceData.description = e.target.value;
                            refreshDataHandler();
                        }}
                    />
                    {serviceTypeId == ServiceType.CARWASH &&
                    <FormControl>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={serviceData.carwash_category_id}
                            onChange={(e) => {
                                serviceData.carwash_category_id = e.target.value;
                                refreshDataHandler();
                            }}
                        >
                            <FormControlLabel value="1" control={<Radio/>}
                                              label={t('location.services.carwash_interior')}/>
                            <FormControlLabel value="2" control={<Radio/>}
                                              label={t('location.services.carwash_exterior')}/>
                            <FormControlLabel value="3" control={<Radio/>}
                                              label={t('location.services.carwash_both')}/>
                        </RadioGroup>
                    </FormControl>
                    }
                    <br/>

                    {
                        serviceTypeId == ServiceType.TIRES &&
                        <TireListSelector
                            selectedValues={getTireCategoriesChecked()}
                            selectionChangedHandler={tireSelectionChangedLocalHandler}/>
                    }

                    {serviceTypeId == ServiceType.CARWASH &&
                    <FormControlLabel
                        control={<Checkbox checked={uniquePrice} onChange={e => setUniquePrice(e.target.checked)}/>}
                        label={t('location.services.unique_price')}/>
                    }
                    <Grid container>
                        {serviceData.prices.map((priceData, index) => {
                            return <Grid item xs={12 / 5} key={index}>
                                {serviceTypeId == ServiceType.CARWASH ?
                                    <LocationCarItem
                                        key={index}
                                        uniquePriceValue={uniquePriceValue}
                                        uniquePrice={uniquePrice}
                                        currency={currency}
                                        priceChangedHandler={(e) => priceChangedHandler(e, priceData)}
                                        priceData={priceData}
                                    /> : (serviceTypeId == ServiceType.TIRES ?
                                            <LocationTireItem
                                                key={index}
                                                currency={currency}
                                                priceChangedHandler={(e) => priceChangedHandler(e, priceData)}
                                                priceData={priceData}
                                            />
                                            : <LocationCustomItem
                                                key={index}
                                                canDelete={serviceData.prices.length > 1}
                                                uniquePriceValue={uniquePriceValue}
                                                uniquePrice={uniquePrice}
                                                currency={currency}
                                                priceChangedHandler={(e) => priceChangedHandler(e, priceData)}
                                                deleteCategoryHandler={
                                                    () => {
                                                        deleteCategoryHandler(serviceData, priceData);
                                                    }

                                                }
                                                refreshDataHandler={refreshDataHandler}
                                                priceData={priceData}
                                            />
                                    )
                                }
                            </Grid>
                        })
                        }
                        {
                            canAddCategory && (serviceData.prices.length < maxCategoryCount) &&
                            <Grid item xs={2}>
                                <Button
                                    style={{width: '100%', height: '100%'}}
                                    onClick={() => {
                                        addCategoryHandler(serviceData)
                                    }}
                                >
                                    <AddCircleIcon style={{width: '50px', height: '50px'}}/>
                                </Button>
                            </Grid>
                        }
                    </Grid>
                    <br/>
                    <LocationServiceOptions
                        serviceData={serviceData}
                        serviceOptionData={serviceData.options}
                        newOptionHandler={newOptionLocalHandler}
                        deleteOptionHandler={deleteOptionHandler}
                        refreshDataHandler={refreshDataHandler}
                    />
                    {canDelete &&
                    <Link
                        href="#"
                        color="#8b0000"
                        onClick={deleteServiceLocalHandler}
                    >
                        {t('location.services.button_delete')}
                    </Link>
                    }
                </Box>
            )}
        </div>
    );
}