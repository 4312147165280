import React, {useEffect, useState} from "react";
import {
    PaymentElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import {useTranslation} from "react-i18next";
import {Button} from "@mui/material";

// Ajout d'un moyen de paiement ( pour abonnement par exemple )
export default function CheckoutPaymentSetup({
                                                 clientSecret
                                             }) {
    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const {t} = useTranslation('common');

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        let location = window.location;
        let callbackUrl = location.protocol + '//' + location.hostname + ':' + location.port + '/showcase/payment_setup_callback';

        const {error} = await stripe.confirmSetup({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: callbackUrl,
            },
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
            setMessage(error.message);
        } else {
            setMessage("An unexpected error occurred.");
        }

        setIsLoading(false);
    };

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <PaymentElement/>
            <Button
                id="submit"
                type={null}
                disabled={isLoading || !stripe || !elements}
                variant="contained"
                color="yellow"
                sx={{mt: '15px', mb: '20px'}}
            >
                {t('service.step_stripe.pay_button')}
            </Button>

            {/* Show any error or success messages */}
            {message && <div id="payment-message">{message}</div>}
        </form>
    );
}