import {Editor} from "@tinymce/tinymce-react";
import React, {forwardRef, useRef} from "react";
import useFetch from "../../hooks/useFetch";
import {useOutletContext} from "react-router-dom";
import {Typography} from "@mui/material";

function ShowcaseEditor({
                            title = null,
                            defaultValue = null,
                            value,
                            storagePath,
                            height = 500
                        }, ref) {

    const [apiUri] = useOutletContext();
    const {callPostForm, isSending} = useFetch();

    const imagesUploadHandler = (blobInfo, progress) => new Promise((resolve, reject) => {
        let data = new FormData();
        data.append("file", blobInfo.blob());

        callPostForm(apiUri + 'post_provider_showcase_file', data, (resultData) => {
            // console.log('resultData', resultData);
            resolve(resultData.location);
        });
    });

    function getValue() {
        if (value == null) {
            return defaultValue;
        }
        return value;
    }

    return (
        <>
            <Typography>{title}</Typography>
            <Editor
                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                onInit={(evt, editor) => ref.current = editor}
                initialValue={getValue()}
                // value={locationGroupData.description}
                init={{
                    height: height,
                    menubar: false,
                    plugins: ['image',
                        // 'advlist', 'autolink', 'lists', 'link',, 'charmap',
                        // 'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        // 'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
                    ],
                    toolbar:
                        'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat' +
                        '| image | help',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    // paste_data_images: true,    // Drag&drop
                    images_upload_handler: imagesUploadHandler,
                    document_base_url: storagePath,
                }}
            />
        </>
    )
}

export default forwardRef(ShowcaseEditor);