import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Step, StepLabel, Stepper, Tabs} from "@mui/material";
import {withStyles} from "@mui/styles";
import Tab from "@mui/material/Tab";

function ServiceStepper({
                            activeStep,
                            classes,
                            editMode,
                            changeStepHandler,
                            withVehicleStep = true,
                            withLabels = true
                        }) {
    const {t} = useTranslation('common');
    const steps = [
        t('service.steps.step1'),
        t('service.steps.step2'),
        t('service.steps.step3'),
        t('service.steps.step4')
    ];

    // Pas ouf, quand on est sans le step 1, on décale l'active step du composant
    function getStepIndex() {
        if (withVehicleStep) {
            return activeStep;
        } else {
            return activeStep - 1;
        }
    }

    return (
        <>
            {
                editMode ?
                    <Tabs
                        value={activeStep}
                        onChange={changeStepHandler}
                        textColor="inherit"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                    >
                        {steps.map((label, index) => (
                            !(index == 0 && !withVehicleStep) &&
                            <Tab key={index} label={label}/>
                        ))}

                    </Tabs>
                    :
                    <Stepper activeStep={getStepIndex()} alternativeLabel>
                        {steps.map((label, index) => (
                            !(index == 0 && !withVehicleStep) &&
                            <Step key={index}>
                                <StepLabel StepIconProps={{
                                    classes: {
                                        active: classes.icon,
                                        completed: classes.icon,
                                    }
                                }}>{withLabels ? label : null}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
            }
        </>
    );
}

const styles = theme => ({
    icon: {
        color: "orange !important"
    }
});

export default withStyles(styles)(ServiceStepper);