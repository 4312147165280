import React, {useEffect, useRef, useState} from 'react';
import {Box, Button, ButtonBase, Grid, IconButton, InputAdornment, Link, Modal, TextField} from "@mui/material";
import {useTranslation} from "react-i18next";
import SendIcon from '@mui/icons-material/Send';
import PictureUpload from "./PictureUpload";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from "@mui/icons-material/Close";
import TextWithBr from "./TextWithBr";
import FormatUtils from "../utils/FormatUtils";
import MessageUploadDocument from "./MessageUploadDocument";

// messages = {type : mine / their / system}
export default function MessageBox({
                                       width,
                                       height,
                                       newMessageHandler,
                                       messages,
                                       orderData,
                                       withUpload = false,
                                       uploadDocumentDoneHandler,
                                       withVehicleDocuments,
                                       isMobile
                                   }) {
    const {t} = useTranslation('common');
    const [message, setMessage] = useState('');
    const [textHeight, setTextHeight] = useState(0);
    const [selectedPicture, setSelectedPicture] = useState(null);
    const [showUploadDocumentModal, setShowUploadDocumentModal] = useState(false);

    const divRef = useRef(null);
    const inputRef = useRef(null);

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        maxWidth: '600px',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
    };

    useEffect(() => {
        divRef.current.scrollIntoView({behavior: "instant"});   // NB : smooth ne permet pas d'avoir l'animation sur les 2 chatboxses ( la dernière prend le dessus )
    }, [messages]);

    useEffect(() => {

        let maxLine = isMobile ? 1 : 3;
        // Adapt la taille de la zone à son contenu TODO il y a encore un petit décalage si on enleve des lignes
        let lineHeight = parseInt(getComputedStyle(inputRef.current).lineHeight);
        let currentHeight = inputRef.current.scrollHeight;
        let height = Math.min(currentHeight, lineHeight * maxLine) + 15;
        console.log("UE", lineHeight, currentHeight, height);
        setTextHeight(height);
    }, [message]);

    function saveClickedHandler() {
        newMessageHandler(message);
        setMessage('');
    }

    function getStyleByType(type) {
        let props = {}

        switch (type) {
            case 'mine':
                props.justifyContent = 'right';
                // props.backgroundColor = '#00FF00';
                break;
            case 'their':
                props.justifyContent = 'left';
                break;
            case 'system':
                props.justifyContent = 'center';
                props.border = 'black solid 1px';
                props.borderRadius = '5px';
                props.backgroundColor = 'orange';
                props.color = 'black';
                break;
        }
        return props;
    }

    return <><Box
        // mt={1}
        sx={{
            p: "2px",
            width: width,
            height: height,
            border: 2,
            borderRadius: '8px',
            borderColor: 'orange',
            display: 'grid',
            gridTemplateRows: '1fr 40px',
        }}
        style={{
            backgroundColor: 'grey',
        }}
    >
        <Box p={1} overflow="auto">
            {
                messages && messages.map((message, i) => {
                    return <Box
                        display="flex"
                        sx={{
                            // backgroundColor: "#FF0000"
                        }}
                        key={i}
                        style={{...getStyleByType(message.type)}}
                    >
                        {message.picture != null
                            &&
                            (FormatUtils.fileNameExtension(message.picture) == 'pdf'
                                    ?
                                    <Link
                                        href={message.picture}
                                        target="_blank"
                                    >
                                        {FormatUtils.fileNameFromURL(message.picture)}
                                    </Link>
                                    : <img
                                        // width={width * 0.6}
                                        height={height * 0.6}
                                        src={message.picture}
                                        style={{
                                            cursor: 'pointer',
                                            objectFit: 'contain',
                                            margin: '2px'
                                        }}
                                        onClick={(e) => setSelectedPicture(message.picture)}
                                    />
                            )
                            // message.picture => 66% de la messagebox ( en largeur et hauteur )

                        }
                        {message.text != null && <TextWithBr
                            textAlign={getStyleByType(message.type).justifyContent}
                            useTypographyTag={true}
                            text={message.text}/>}
                    </Box>
                })
            }
            <div style={{
                float: "left",
                clear: "both"
            }}
                 ref={divRef}>
            </div>
        </Box>
        <TextField
            multiline
            // rows={1}
            rows={isMobile ? 1 : ''}
            // minRows={1}
            maxRows={3}
            inputRef={inputRef}
            sx={{
                ml: 'auto',
                borderRadius: '8px',
                border: 2,
                backgroundColor: 'white',
                maxWidth: '500px',
                // maxHeight: '500px'
                height: textHeight
            }}
            style={{
                borderColor: 'orange'
            }}
            id="notes"
            size="small"
            inputProps={{
                style: {color: "black"}
            }}
            onChange={(e) => {
                setMessage(e.currentTarget.value);
            }}
            InputProps={{
                endAdornment:
                    <InputAdornment position="end">
                        {withUpload &&
                            // <PictureUpload
                            //     default_picture={<AttachFileIcon htmlColor="#555555" style={{marginTop: '5px'}}/>}
                            //     uploadDocumentVersion={true}
                            //     api_uri="order_message_upload_picture"
                            //     model_id={orderData.id}
                            //     uploadDocumentDoneHandler={uploadDocumentDoneHandler}
                            // />

                            <IconButton
                                style={{
                                    color: "#555555"
                                }}
                                onClick={() => setShowUploadDocumentModal(true)}
                            >
                                <AttachFileIcon/>
                            </IconButton>
                        }
                        <IconButton
                            style={{
                                color: "#555555"
                            }}
                            onClick={saveClickedHandler}
                        >
                            <SendIcon/>
                        </IconButton>
                    </InputAdornment>
            }}
            fullWidth
            placeholder={t('service_card.message_prompt')}
            value={message}
        />
    </Box>
        <Modal
            open={selectedPicture != null}
            onClose={() => {
                setSelectedPicture(null)
            }}
        >
            <>
                <Box sx={modalStyle}>
                    <Box display="flex" alignItems="center" style={{
                        margin: '0px',
                    }}>
                        <Box flexGrow={1}></Box>
                        <Box>
                            <IconButton onClick={() => {
                                setSelectedPicture(null)
                            }}>
                                <CloseIcon/>
                            </IconButton>
                        </Box>
                    </Box>
                    <img src={selectedPicture} width="100%"></img>
                </Box>
            </>
        </Modal>

        <MessageUploadDocument
            open={showUploadDocumentModal}
            orderData={orderData}
            withVehicleDocuments={withVehicleDocuments}
            vehicleId={orderData.vehicle_id}
            uploadDocumentDoneHandler={uploadDocumentDoneHandler}
            closeHandler={() => setShowUploadDocumentModal(false)}
        />
    </>;
}