import {
    Box,
    Button,
    FormControl,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    Stack,
    Typography
} from "@mui/material";
import DocumentIcon from "../assets/buttons/document/document-white-black.png";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import FormatUtils from "../utils/FormatUtils";
import useFetch from "../hooks/useFetch";
import {useOutletContext} from "react-router-dom";
import Swal from "sweetalert2";

export default function MessageUploadDocument({
                                                  open,
                                                  closeHandler,
                                                  orderData,
                                                  vehicleId,
                                                  withVehicleDocuments,
                                                  uploadDocumentDoneHandler
                                              }) {

    const formRef = React.useRef();
    const {t} = useTranslation('common');
    const [localVehicleData, setLocalVehicleData] = useState(null);

    const [documentId, setDocumentId] = useState('');
    const [file, setFile] = useState();

    const {call} = useFetch();
    const [apiUri] = useOutletContext();

    const maxFileSize = 5;  // Mb

    useEffect(() => {
        if (withVehicleDocuments) {
            getVehicleFromServer(vehicleId);
        }
    }, []);


    useEffect(() => {
        // console.log('open : ', open);
        setDocumentId('');
    }, [open]);

    // TODO redondant
    async function getVehicleFromServer(id) {
        call(apiUri + 'vehicle_get/' + id, (resultData => {
            setLocalVehicleData(FormatUtils.cloneData(resultData.vehicle));
        }));
    }

    function submitHandler(event) {
        event.preventDefault();
        if (documentId != '') {
            let data = new FormData();
            data.append('id', orderData.id);
            data.append('document_id', documentId);
            call(apiUri + 'order_message_upload_picture', (resultData) => {
                uploadDocumentDoneHandler(resultData.message);
                closeHandler();
            }, 'POST', data);
            return;
        }

        // Upload d'un document
        if (file != null) {
            let data = new FormData();
            data.append('id', orderData.id);
            data.append('file', file);

            call(apiUri + 'order_message_upload_picture', (resultData) => {
                uploadDocumentDoneHandler(resultData.message);
                closeHandler();
            }, 'POST', data);
            return;
        }

        Swal.fire({
            text: t('service_card.add_document.warning_need_action'),
            icon: 'error',
            target: document.getElementById('swal_container'),
        });
    }

    // Upload d'un nouveau document
    function fileChangedHandler(e) {
        let fileInput = document.getElementById("file");
        var ext = fileInput.value.match(/\.([^\.]+)$/)[1];
        ext = ext.toLowerCase();
        switch (ext) {
            case 'jpg':
            case 'bmp':
            case 'png':
            case 'tif':
            case 'pdf':
                break;
            default:
                Swal.fire({
                    text: t('vehicle.item.documents.add.error_format'),
                    icon: 'error',
                    target: document.getElementById('swal_container'),
                });
                fileInput.value = '';
                return;
        }
        if (fileInput.files[0].size > maxFileSize * 1024 * 1024) {
            Swal.fire({
                text: t('vehicle.item.documents.add.error_size'),
                icon: 'error',
                target: document.getElementById('swal_container'),
            });
            fileInput.value = '';
            return;
        }

        setFile(fileInput.files[0]);
    }

    return (<Modal
        open={open}
        onClose={closeHandler}
    >
        <>
            {(localVehicleData || !withVehicleDocuments) &&
            <Box sx={style}>
                <Grid container>
                    <Grid item xs={0}>
                        <img src={DocumentIcon} style={{width: '50px'}}></img>
                    </Grid>
                    <Grid item xs={10} container alignContent="center">
                        <Typography fontSize="18px"
                                    marginLeft="10px"><b>{t('service_card.add_document.title')}</b></Typography>
                    </Grid>
                </Grid>
                <form onSubmit={submitHandler} ref={formRef}>
                    <Stack>
                        {withVehicleDocuments &&
                        <>
                            <FormControl sx={{
                                mt: '20px'
                            }}>
                                <InputLabel>{t('service_card.add_document.list_caption')}</InputLabel>
                                <Select
                                    // id="document_id"
                                    // name="document_id"
                                    label={t('service_card.add_document.list_caption')}
                                    value={documentId}
                                    disabled={file != null}
                                    onChange={(e) => {
                                        setDocumentId(e.target.value)
                                    }}
                                >
                                    <MenuItem value="">{t('service_card.add_document.list_caption')}</MenuItem>
                                    {
                                        localVehicleData.documents.map((document, i) => {
                                            return (
                                                <MenuItem key={i}
                                                          value={document.id}>{document.label}</MenuItem>)
                                        })
                                    }
                                </Select>
                            </FormControl>
                            <Typography fontSize="15px"
                                        marginTop="10px">{t('service_card.add_document.free_document')}</Typography>
                        </>
                        }

                        <Input
                            sx={{
                                height: '50px'
                            }}
                            inputProps={{accept: 'image/*,application/pdf'}}
                            id="file"
                            type="file"
                            onChange={fileChangedHandler}
                            disabled={documentId != ''}
                        />
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="yellow"
                                    sx={{mt: '15px', mb: '20px'}}
                                    onClick={
                                        () => {
                                            closeHandler();
                                        }}
                                >
                                    {t('common.cancel')}
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="yellow"
                                    sx={{mt: '15px', mb: '20px'}}
                                >
                                    {t('vehicle.item.documents.add.save')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Stack>
                </form>
            </Box>
            }
        </>
    </Modal>)
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};