import {Box, Grid, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import TireServiceStatic from "../services/TireServiceStatic";
import React from "react";

import ImageButton from "./ImageButton";

export default function TireOrWheelSelector({selectedValue = null, selectionChangedHandler = null}) {
    const {t} = useTranslation('common');
    const tireOrWheel = TireServiceStatic.tireOrWheel;

    return (
        <Box>
            <Typography>{t('location.services.tire.tire_or_wheel_title')}</Typography>
            <Grid container justifyContent="left">
                {tireOrWheel.map(tireCategory => {
                    return <Box key={tireCategory.id} alignContent="center" style={{
                        // backgroundColor:'red'
                    }}>
                        <Grid container justifyContent="center" alignItems="center" style={{
                            backgroundColor: (selectedValue == tireCategory.id) ? '#ff9c0d' : null
                        }}>
                            <ImageButton
                                height="75px"
                                src={tireCategory.icon}
                                onClickHandler={(e) => selectionChangedHandler(tireCategory)}/>
                        </Grid>
                    </Box>
                })}
            </Grid>
        </Box>
    )
}