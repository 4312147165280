import {Box, Grid, Radio, Stack, Typography} from "@mui/material";
import React, {useContext, useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@mui/styles";
import defaultImage from "../assets/menu/location/emplacement-b-g.png";
import ServiceLocationCalendar from "./ServiceLocationCalendar";
import MobileContext from "../contexts/mobileContext";
import FormatUtils from "../utils/FormatUtils";

export default function ServiceLocationItem({
                                                locationData,
                                                selected,
                                                clickedHandler,
                                                selectedDay,
                                                selectedPeriod,
                                                periodClicked
                                            }) {
    const {t} = useTranslation('common');
    const classes = useStyles();

    const mobileContext = useContext(MobileContext);
    const isMobile = mobileContext.isMobile;

    const anchorRef = useRef(null);

    useEffect(() => {
        if (selected) {
            // scrollTo();
        }
    }, [selected]);


    // TODO From LocationInfo
    function getOptionExists(data, optionTypeId) {
        let option = data.options.find(option => option.location_option_type_id == optionTypeId);
        let exists = (option != null);
        return (exists);
    }

    function isMobileLocation(locationData) {
        return getOptionExists(locationData, 2);
    }

    // TODO Pas ouf de le faire à chaque rendered, alors qu'on veut juste savoir quand le calendrier est finit d'être rendu
    function calendarRenderedHandler() {
        scrollTo();
    }

    function scrollTo() {
        // console.log('SCROLLTO');
        anchorRef.current.scrollIntoView({behavior: "instant"});
    }

    return (
        <div ref={anchorRef}>
            <Box sx={{
                border: 0,
                p: isMobile ? '0px' : '16px',
                m: '0px',
                mt: isMobile ? '5px' : '10px',
                mb: isMobile ? '5px' : '10px',
            }}>

                <Grid container>
                    <Grid item xs={2} container alignContent="center">
                        <Radio
                            sx={{
                                '& .MuiSvgIcon-root': {
                                    fontSize: 40,
                                },
                            }}
                            checked={selected}
                            onClick={(e) => clickedHandler(locationData)}
                        />
                    </Grid>
                    <Grid item xs={7} container>
                        <Stack width="100%">
                            <Grid width="100%" container justifyContent="space-between">
                                <Typography
                                    onClick={(e) => clickedHandler(locationData)}
                                    style={{
                                        cursor: 'pointer',
                                        color: "#fbc70f",
                                        fontSize: isMobile ? '0.9em' : "18px",
                                        fontWeight: "bold",
                                        // backgroundColor : '#FF0000'
                                    }}>{locationData.name.toUpperCase()}</Typography>
                            </Grid>
                            <Typography
                                fontSize={isMobile ? '0.8em' : null}>
                                {locationData.dayScheduleHuman}</Typography>
                            <Typography fontSize={isMobile ? '0.8em' : null}
                            >({locationData.distance} Km) {locationData.address} {locationData.city}</Typography>
                            {FormatUtils.getLeasingCompaniesText(locationData, t)}
                        </Stack>
                    </Grid>
                    <Grid item xs={3} container alignContent="center">
                        <img src={
                            locationData.picture ? locationData.picture : defaultImage
                        } className={isMobile ? classes.pictureImg_mobile : classes.pictureImg}/>
                    </Grid>
                    {selected &&
                    <ServiceLocationCalendar
                        locationId={locationData.id}
                        locationData={locationData}
                        selectedDay={selectedDay}
                        selectedPeriod={selectedPeriod}
                        periodClicked={periodClicked}
                        renderedHandler={calendarRenderedHandler}
                    />
                    }
                </Grid>
            </Box>
            {!isMobile &&
            <Grid sx={{
                borderBottom: 1,
                borderColor: 'divider',
                marginTop: "10px",
                marginBottom: '10px'
            }}/>
            }
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    pictureImg: {
        height: '100px',
        width: '90%',
        objectFit: 'contain',
        borderRadius: '5px'
    },
    pictureImg_mobile: {
        height: '50px',
        width: '90%',
        objectFit: 'contain',
        borderRadius: '5px'
    }
}));