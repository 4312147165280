import React, {useContext, useEffect, useState} from 'react';
import Swal from 'sweetalert2'

import logo from '../assets/logo-header.png';
// import logo from '../logo-header.png';
import {Button, Container, Grid, TextField, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {Link, useOutletContext} from "react-router-dom";
import {useTranslation} from "react-i18next";
import useFetch from "../hooks/useFetch";
import RedirectToEntryPoint from "./RedirectToEntryPoint";
import MobileContext from "../contexts/mobileContext";
import {useSelector} from "react-redux";
import BrandedKucFooter from "../features/branded/BrandedKucFooter";
import {getIsBranded} from "../features/branded/locationGroupSlice";
import BrandedHeaderFooter from "../features/branded/BrandedHeaderFooter";
import useLocalStorageState from "use-local-storage-state";

export default function Login({}) {
    const classes = useStyles();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [apiUri] = useOutletContext();
    const {t, i18n} = useTranslation('common');

    const [loginPredone, setLoginPredone] = useState(false);
    const [loginDone, setLoginDone] = useState(false);

    const [accountType, setAccountType] = useState(null);
    const [accessToken, setAccessToken] = useLocalStorageState('accessToken');
    const {callWithParams, isSending} = useFetch();

    const mobileContext = useContext(MobileContext);
    const isMobile = mobileContext.isMobile;

    const isBranded = useSelector(getIsBranded);
    const brandDescriptionLogo = useSelector(state => state.locationGroup.locationGroupData?.description_logo);

    // useEffect(() => {
    //     console.log('isBranded :', isBranded);
    // }, [isBranded]);

    async function loginUser(credentials) {
        callWithParams({
            url: apiUri + 'login',
            method: 'POST',
            body: credentials,
            jsonBody: true,
            withAuth: false,
            callback: (resultData) => {
                setLoginPredone(true);
                Swal.fire(t('login.success.title'), t('login.success.desc'), "success", {
                    buttons: false,
                    timer: 2000,
                })
                    .then((value) => {
                        localStorage.setItem('accountType', resultData.account['account_type_id']);   // Necessaire au menu
                        setAccessToken(resultData.account['api_token']);
                        setAccountType(resultData.account.account_type_id);
                        setLoginDone(true);
                    });
            }
        });
    }

    const handleSubmit = async e => {
        e.preventDefault();
        loginUser({
            email,
            password
        });
    }

    let redirect;
    if (loginDone) {
        redirect = <RedirectToEntryPoint/>;
    }

    return (<>
            {redirect ?
                redirect
                :
                <Grid container
                      alignItems="center"
                      flexDirection="column"
                    // justifyContent="space-between"
                      justifyContent="center"
                      style={isMobile ? {
                          // height: '100%',
                          minHeight: '100vh'
                      } : {}}
                >
                    <Grid container
                          maxWidth='sm'
                          flexDirection="column"
                          flex={1}
                          justifyContent="space-around"
                          style={{
                              // backgroundColor: '#292e32',
                              height: '100%',
                              // backgroundColor: 'red'
                          }}
                          sx={{
                              borderRadius: isMobile ? '' : '16px'
                          }}>
                        {brandDescriptionLogo ?
                            <BrandedHeaderFooter descriptionLogo={brandDescriptionLogo}/>
                            :
                            <img src={logo} className="logo"/>
                        }

                        <Container>
                            {/*<Typography align='center' variant='subtitle1'>*/}
                            {/*    {t('login.title')}*/}
                            {/*</Typography>*/}
                            <div>
                                <form className={classes.form} noValidate onSubmit={handleSubmit}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="email"
                                        name="email"
                                        label={t('login.email')}
                                        onChange={e => setEmail(e.target.value)}
                                        InputProps={{
                                            className: classes.input
                                        }}
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="password"
                                        name="password"
                                        label={t('login.password')}
                                        type="password"
                                        onChange={e => setPassword(e.target.value)}
                                    />
                                    <Container
                                        sx={{
                                            mt: 1,
                                            mb: 1
                                        }}
                                    >
                                        <Link to="/reset_password"
                                              style={{
                                                  color: 'white',
                                              }}>{t('login.reset_password')}
                                        </Link>
                                    </Container>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="yellow"
                                        className={classes.loginBtn}
                                        sx={{mt: '15px', mb: '20px'}}
                                        disabled={isSending || loginPredone}
                                    >
                                        {t('login.btn_login')}
                                    </Button>
                                </form>
                            </div>
                        </Container>
                        <Container maxWidth='sm' className={classes.registerContainer}
                                   sx={{
                                       borderBottomLeftRadius: '16px',
                                       borderBottomRightRadius: '16px'
                                   }}>
                            <Link to="/register" style={{textDecoration: 'none'}}>
                                <Button
                                    margin="normal"
                                    fullWidth
                                    color="yellow"
                                >
                                    {t('login.btn_register')}
                                </Button>
                            </Link>
                        </Container>
                    </Grid>
                    {isBranded &&
                        <div style={
                            isMobile ? {
                                width: '100%'
                            } : {
                                width: '100%',
                                position: 'absolute',
                                bottom: '0px',
                            }

                        }>
                            <BrandedKucFooter/>
                        </div>
                    }
                </Grid>} </>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        // backgroundColor: '#49545d',  // Gris clair 1
        background: 'linear-gradient(#49545D, #1C2326)'
    },
    input: {
        color: '#49545d'  // gris clair 2
    },
    registerContainer: {
        backgroundColor: '#1d2124', // Gris foncé 1
        borderTop: 'solid #373B3E',
        paddingTop: '20px',
        paddingBottom: '20px'
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    loginBtn: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: '#fbc70f',
        color: 'black',
    },
    white: {
        color: 'white'
    },

    centerText: {
        alignItems: "center",
        display: "flex"
    }
}));