import React, {forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState} from "react";
import {
    Box,
    Container, Grid, Modal, TextField, Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import '../../App.css';
import ConfigurationContext from "../../contexts/configurationContext";
import {QRCodeSVG} from 'qrcode.react';
import {QRCodeCanvas} from 'qrcode.react';
import StripePaymentSetup from "./StripePaymentSetup";
import QrcodePopin from "./QrcodePopin";


function ShowcaseStep3({
                           previousBtn,
                           nextBtn,
                           contentLoadedHandler,
                           setLabel,
                           validateStep3: validateStep3,
                           locationGroupData,
                           editMode
                       }, ref) {
    const {t} = useTranslation('common');
    const formRef = React.useRef();
    const [loaded, setLoaded] = useState(false);
    const editorFooterRef = useRef(null);
    const applicationSettingsContext = useContext(ConfigurationContext);

    const [slug, setSlug] = useState('');
    const slugRef = useRef();

    const [showQrcode, setShowQrcode] = useState(false);

    slugRef.current = slug;

    useEffect(() => {
        setLabel('showcase.step3.step_title');
        previousBtn.current.style.display = editMode ? 'none' : '';
        nextBtn.current.style.display = '';
        nextBtn.current.addEventListener('click', nextClickedHandler);
        contentLoadedHandler();
        setLoaded(true);
        return () => {
            if (nextBtn.current) {
                nextBtn.current.removeEventListener('click', nextClickedHandler);
            }
        }
    }, [])

    useEffect(() => {
        if (locationGroupData != null) {
            setSlug(locationGroupData.slug ?? '');
        } else {
            setSlug('');
        }
    }, [locationGroupData]);

    function getStoragePath() {
        return applicationSettingsContext == null ? '' : applicationSettingsContext.storage_path;
    }

    function nextClickedHandler() {
        save();
    }

    function slugChangedHandler(event) {
        // On autorise uniquement les alphanumériques
        let value = event.target.value;
        const ALPHA_NUMERIC_REGEX = /[^a-zA-Z0-9]/gi;
        let remplaced = value.replace(ALPHA_NUMERIC_REGEX, '');
        remplaced = remplaced.toLowerCase();
        setSlug(remplaced);
    }

    function getShowcaseBasePath() {
        return applicationSettingsContext == null ? '' : (applicationSettingsContext.client_path + '/lg/');
    }

    // Depuis l'extérieur ( stepper )
    useImperativeHandle(ref, () => ({
        saveFromExternal() {
            save();
        },
    }));

    function save() {
        if (!formRef.current.reportValidity()) {
            return;
        }
        validateStep3({
            'slug': slugRef.current,
        });
    }

    // TODO same as ShowcaseStep2/4
    function getShowcasePath(locationGroupData) {
        return applicationSettingsContext == null ? '' : (applicationSettingsContext.client_path + '/lg/' + locationGroupData.slug);
    }

    return (
        <>
            <Container>
                {loaded && <Typography component="div" sx={{
                    mt: 5
                }}>
                    <Grid container justifyContent="center">
                        <Box sx={{fontWeight: 'bold', m: 1, mb: 4}}>{t('showcase.step3.title')}</Box>
                        <Grid container
                              justifyContent="center"
                              sx={{
                                  mb: 4
                              }}
                        >
                            <form
                                ref={formRef}>
                                <TextField label={t('showcase.step3.domain_name')}
                                           variant="outlined"
                                           value={getShowcaseBasePath()}
                                           disabled
                                />
                                <TextField label={t('showcase.step3.personal_link')}
                                           disabled={editMode}
                                           variant="outlined"
                                           value={slug}
                                           required
                                           inputProps={{
                                               minLength: 3,
                                               maxLength: 32
                                           }}
                                           onChange={slugChangedHandler}
                                />
                            </form>
                            {editMode &&
                            <Grid container justifyContent="center" mt={2}>
                                <QRCodeCanvas
                                    value={getShowcasePath(locationGroupData)}
                                    style={{
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                        setShowQrcode(true)
                                    }}
                                />
                            </Grid>
                            }
                        </Grid>
                    </Grid>
                </Typography>
                }
            </Container>
            <Modal open={showQrcode}
                   onClose={() => {
                       setShowQrcode(false)
                   }}
                   style={{
                       display: 'flex',
                       alignItems: 'center',
                       justifyContent: 'center'
                   }}
            >
                <QrcodePopin url={getShowcasePath(locationGroupData)}/>
            </Modal>
        </>
    )
};

export default forwardRef(ShowcaseStep3);