import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import FormatUtils from "../utils/FormatUtils";
import MySwal from "sweetalert2";

export default function useAlert() {
    const {t} = useTranslation('common');

    // useEffect(() => {
    // });

    const showWarning = (translationId, values = null) => {
        show('warning', translationId, values);
    }

    function showInfo(translationId, values = null) {
        show('info', translationId, values);
    }

    function show(icon, translationId, values) {
        let text = '';
        if (values != null) {
            text = FormatUtils.changeTextWithValues(
                t(translationId),
                values);
        } else {
            text = t(translationId);
        }

        MySwal.fire({
            text: text,
            icon: icon,
            target: document.getElementById('swal_container')
        });
    }

    return {showWarning, showInfo};
}