import React, {useEffect, useState} from 'react';
import {Box, Button, Container, Grid, Radio, RadioGroup, Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import Swal from "sweetalert2";
import {useOutletContext} from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';

export default function PaymentMethodItem({
                                              methodData,
                                              selectMode,
                                              selected,
                                              selectHandler = null,
                                              detachHandler = null
                                          }) {
    const {t} = useTranslation('common');

    return <Box
        m={1}
        p={1}
        sx={{
            border: 'solid 1px',
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 20px',
            gridTemplateRows: '1fr 1fr',
            cursor: selectMode ? 'pointer' : null
        }}
        onClick={(e) => {
            if (selectMode) {
                selectHandler(methodData);
            }
        }}
    >
        <Typography>{methodData.card.brand}</Typography>
        <Typography>**** **** **** {methodData.card.last4}</Typography>
        {
            selectMode ? <Radio checked={selected}/>
                :
                <Button size="small"
                        sx={{
                            justifySelf: 'end'
                        }}
                        style={{
                            maxWidth: '25px',
                            minWidth: '25px'
                        }}
                        onClick={
                            () => {
                                detachHandler(methodData);
                            }
                        }>
                    <CloseIcon fontSize="small"/>
                </Button>
        }
        <Typography>LOGO</Typography>
        <Typography>{t('payment_methods.expiration_date')}{methodData.card.exp_month}/{methodData.card.exp_year}</Typography>
    </Box>
}