import React, {forwardRef, useEffect, useRef, useState} from "react";
import {
    Box,
    Container, Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Navigate, useOutletContext} from "react-router-dom";
import '../../App.css';
import useFetch from "../../hooks/useFetch";
import FormatUtils from "../../utils/FormatUtils";


function ShowcaseStep1({
                           previousBtn,
                           nextBtn,
                           contentLoadedHandler,
                           setLabel,
                           validateStep1,
                           subscriptionData,
                           editMode
                       }, ref) {
    const [apiUri] = useOutletContext();
    const {t} = useTranslation('common');

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setLabel('showcase.step1.step_title');
        previousBtn.current.style.display = 'none';
        nextBtn.current.style.display = editMode ? 'none' : '';
        nextBtn.current.addEventListener('click', nextClickedHandler);
        contentLoadedHandler();
        setLoaded(true);
        return () => {
            if (nextBtn.current) {
                nextBtn.current.removeEventListener('click', nextClickedHandler);
            }
        }
    }, [])

    function nextClickedHandler() {
        validateStep1();
    }

    return (
        <Container>
            {loaded && <Typography component="div" sx={{
                mt: 5
            }}>
                <Box sx={{fontWeight: 'bold', m: 1}}>{t('showcase.step1.title')}</Box>
                <Box sx={{fontWeight: 'regular', m: 1}}>{t('showcase.step1.line1')}</Box>
                <ul>
                    <li>
                        {t('showcase.step1.line2')}
                    </li>
                    <li>
                        {t('showcase.step1.line3')}
                    </li>
                    <li>
                        {t('showcase.step1.line4')}
                    </li>
                    <li>
                        {t('showcase.step1.line5')}
                    </li>
                    <li>
                        {
                            FormatUtils.changeTextWithValues(
                                t('showcase.step1.line6'),
                                {
                                    'fee': subscriptionData.showcase_kuc_fee
                                }
                            )
                        }
                    </li>
                    <li>
                        {t('showcase.step1.line7')}
                    </li>
                    <li>
                        {t('showcase.step1.line8')}
                    </li>
                    <li>
                        {t('showcase.step1.line9')}
                    </li>
                </ul>
            </Typography>
            }
        </Container>
    )
};

export default forwardRef(ShowcaseStep1);