import React, {useEffect, useState} from 'react';
import {
    Box, Button, Grid, Typography
} from "@mui/material";
import {Link, Navigate, useLocation, useNavigate, useOutletContext, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import ProviderOrderBook from "./ProviderOrderBook";
import useFetch from "../hooks/useFetch";

export default function Dashboard({
                                      setLabel
                                  }) {
    const {t} = useTranslation('common');
    const [apiUri] = useOutletContext();
    const [loaded, setLoaded] = useState(false);
    const [actionsToDo, setActionsToDo] = useState([]);
    const {call} = useFetch();
    const params = useParams();

    useEffect(() => {
        setLabel('dashboard.title');
        accountGet();
    }, []);

    async function accountGet() {
        call(apiUri + 'account', (data) => {
            setLoaded(true);
            if ('actions_to_do' in data) {
                setActionsToDo(data.actions_to_do);
            }
        });
    }

    return (
        <>
            {/*<Avatar src={user.avatar} className={classes.large} />*/}
            <Typography variant="h4">
                {t("dashboard.title")}
            </Typography>
            <Box width="100%" sx={{mt: 1}}/>
            <Typography variant="h5">
                {t("dashboard.welcome")}
            </Typography>

            {/*<Button variant="outlined">Edit</Button>*/}
            <Box width="100%" sx={{mt: 1}}/>
            {loaded &&
            <>
                <Box width="100%" sx={{mt: 1}}/>
                {
                    actionsToDo.includes('add_stripe_account') &&
                    <Grid justifyContent="center" container>
                        {t('dashboard.actions_to_do.add_stripe_account.description')}
                        <Box width="100%" sx={{mt: 1}}/>
                        <Link to="/profile_stripe" style={{textDecoration: 'none'}}>
                            <Button
                                variant="contained"
                                color="yellow"
                                sx={{mt: '15px', mb: '20px'}}
                            >
                                {t('dashboard.actions_to_do.add_stripe_account.button_label')}
                            </Button>
                        </Link>
                    </Grid>
                }
                {
                    actionsToDo.includes('add_first_location') &&
                    <Grid justifyContent="center" container>
                        {t('dashboard.actions_to_do.add_first_location.description')}
                        <Box width="100%" sx={{mt: 1}}/>
                        <Link to="/location/add" style={{textDecoration: 'none'}}>
                            <Button
                                variant="contained"
                                color="yellow"
                                sx={{mt: '15px', mb: '20px'}}
                            >
                                {t('dashboard.actions_to_do.add_first_location.button_label')}
                            </Button>
                        </Link>
                    </Grid>
                }
                {
                    actionsToDo.length == 0 &&
                    <ProviderOrderBook locationId={params.locationId} tabIndexDeeplink={params.tabIndex}/>
                }
            </>
            }
        </>
    );
}