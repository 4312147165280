import React, {useEffect} from 'react';
import {useOutletContext} from "react-router-dom";
import useFetch from "../hooks/useFetch";

function VehicleDocumentTypesService({setDocumentTypes}) {
    const [apiUri] = useOutletContext();
    const {call} = useFetch();

    useEffect(() => {
        getVehicleDocumentTypes();
    }, []);

    function getVehicleDocumentTypes() {
        call(apiUri + 'vehicle_document_types', (data) => {
                setDocumentTypes(data.document_types);
            }
        );
    }
}

export default VehicleDocumentTypesService;