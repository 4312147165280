import React, {useContext, useState} from 'react';
import Swal from 'sweetalert2'

import logo from '../assets/logo-header.png';
import {Button, Container, Grid, Switch, TextField, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {Link, Navigate, useNavigate, useOutletContext} from "react-router-dom";
import {useTranslation} from "react-i18next";
import useFetch from "../hooks/useFetch";
import MobileContext from "../contexts/mobileContext";
import {AccountType} from "../constants/AccountType";
import {useSelector} from "react-redux";
import {getIsBranded} from "../features/branded/locationGroupSlice";
import BrandedKucFooter from "../features/branded/BrandedKucFooter";
import BrandedHeaderFooter from "../features/branded/BrandedHeaderFooter";
import {KucPhoneTextField} from "./common/KucPhoneTextField";

export default function Register() {
    const classes = useStyles();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [company, setCompany] = useState('');
    const [phone, setPhone] = useState('');
    const [validPhone, setValidPhone] = useState();
    const [apiUri] = useOutletContext();
    const [accountType, setAccountType] = useState(1);
    const formRef = React.useRef();
    const {t, i18n} = useTranslation('common');
    const [registerDone, setRegisterDone] = useState(false);
    const navigate = useNavigate();
    const {callWithParams, isSending} = useFetch();
    const mobileContext = useContext(MobileContext);
    const isMobile = mobileContext.isMobile;

    const isBranded = useSelector(getIsBranded);
    const brandDescriptionLogo = useSelector(state => state.locationGroup.locationGroupData?.description_logo);
    const locationGroupId = useSelector(state => state.locationGroup.locationGroupData?.id);

    async function sendRegister(data) {
        callWithParams({
            url: apiUri + 'register',
            method: 'POST',
            body: data,
            jsonBody: true,
            withAuth: false,
            callback: (resultData) => {
                Swal.fire(t('register.success.title'), t('register.success.desc'), "success", {
                    buttons: false,
                    timer: 2000,
                })
                    .then((value) => {
                        setRegisterDone(true);
                    });
            },
            failCallback: (failResultData) => {
                if (failResultData.message == 'register.error.email_already_exists') {
                    Swal.fire(
                        {
                            title: t('common.error_title'),
                            text: t(failResultData.message),
                            confirmButtonText: t('register.login_btn'),
                            showCloseButton: true
                        }).then((result) => {
                        if (result.isConfirmed) {
                            navigate('/login');
                        }
                    });
                } else {
                    Swal.fire(
                        t('common.error_title'),
                        failResultData.message,
                        "error");
                }
            }
        });
    }

    const handleSubmit = async e => {
        e.preventDefault();

        if (!formRef.current.reportValidity()) {
            return;
        }

        if (password != confirmPassword) {
            Swal.fire(t('register.error_title'), t('register.error_password'), "error");
            return;
        }

        // Téléphone OK ?
        if (!validPhone && accountType === AccountType.PROVIDER) {
            Swal.fire(t('register.error_title'), t('register.error_phone'), "error");
            return;
        }

        const response = sendRegister({
            'user': {
                account_type_id: accountType,
                last_name: lastName,
                first_name: firstName,
                email,
                password,
                phone,
                company,
                origin_location_group_id: locationGroupId,
                language: i18n.language
            }
        });
    }

    return (
        <>
            {registerDone ?
                <Navigate to='/login'/>
                :
                <Grid container
                      alignItems="center"
                      justifyContent="center"
                      style={isMobile ? {
                          // height: '100%',
                          margin: 0,
                          minHeight: '100vh',
                          flexDirection: 'column'
                      } : {}}
                >
                    {/* Login Form  */}
                    <Grid container
                          maxWidth='sm'
                          flexDirection="column"
                          flex={1}
                          className={classes.loginContainer}
                          sx={{
                              borderRadius: isMobile ? '' : '16px',
                          }}
                          disableGutters>
                        {brandDescriptionLogo ?
                            <BrandedHeaderFooter descriptionLogo={brandDescriptionLogo}/>
                            :
                            <img src={logo} className="logo"/>
                        }
                        <Container>
                            <form className={classes.form} noValidate onSubmit={handleSubmit} ref={formRef}>
                                {isBranded ?
                                    <>
                                    </>
                                    :
                                    <Grid container alignItems="center"
                                          justifyContent="center">
                                        <Typography
                                            fontSize={isMobile ? '3vw' : ''}
                                            className={accountType === AccountType.CUSTOMER ? classes.yellow : null}>{t('register.customer_label')}</Typography>
                                        <Switch onChange={function (e) {
                                            setAccountType(e.target.checked ? 2 : 1)
                                        }}/>
                                        <Typography
                                            fontSize={isMobile ? '3vw' : ''}
                                            className={accountType == AccountType.PROVIDER ? classes.yellow : null}>{t('register.company_label')}</Typography>
                                    </Grid>
                                }
                                {accountType == AccountType.CUSTOMER ?
                                    <>
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="firstname"
                                            name="firstname"
                                            label={t('register.firstname')}
                                            value={firstName}
                                            onChange={e => setFirstName(e.target.value)}
                                        />
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="lastname"
                                            name="lastname"
                                            label={t('register.lastname')}
                                            value={lastName}
                                            onChange={e => setLastName(e.target.value)}
                                        />
                                    </>
                                    : <>
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="company"
                                            name="company"
                                            label={t('register.company')}
                                            value={company}
                                            onChange={e => setCompany(e.target.value)}
                                        />
                                    </>
                                }

                                <KucPhoneTextField
                                    variant={'outlined'}
                                    margin="normal"
                                    required={accountType == AccountType.PROVIDER}
                                    fullWidth
                                    id="phone"
                                    name="phone"
                                    label={t('register.phone')}
                                    value={phone}
                                    onChange={(value, isValid) => {
                                        setPhone(value);
                                        setValidPhone(isValid);
                                    }}
                                />

                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    name="email"
                                    label={t('register.email')}
                                    type="email"
                                    onChange={e => setEmail(e.target.value)}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="password"
                                    name="password"
                                    label={t('register.password')}
                                    type="password"
                                    onChange={e => setPassword(e.target.value)}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="confirm_password"
                                    name="confirm_password"
                                    label={t('register.password_again')}
                                    type="password"
                                    onChange={e => setConfirmPassword(e.target.value)}
                                />
                                <Typography
                                    fontSize={isMobile ? '3.5vw' : ''}
                                >
                                    {t('register.login_remind')}
                                    <Link to="/login" style={{
                                        color: 'white'
                                    }}>{t('register.login_remind_link')}</Link>
                                </Typography>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="yellow"
                                    className={classes.loginBtn}
                                    sx={{mt: '15px', mb: '20px'}}
                                    disabled={isSending}
                                >
                                    {t('register.btn_register')}
                                </Button>
                            </form>
                        </Container>
                    </Grid>
                    {isBranded &&
                        <div style={
                            isMobile ? {
                                    width: '100%'
                                } :
                                {
                                    position: 'absolute',
                                    bottom: '0px',
                                    width: '100%'
                                }
                        }>
                            <BrandedKucFooter/>
                        </div>
                    }
                </Grid>}
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        // backgroundColor: '#49545d',  // Gris clair 1
        background: 'linear-gradient(#49545D, #1C2326)'
    },
    // input: {
    //     color: '#49545d'  // gris clair 2
    // },
    loginContainer: {
        backgroundColor: '#292e32'
    },
    registerContainer: {
        backgroundColor: '#1d2124', // Gris foncé 1
        borderTop: 'solid #373B3E',
        paddingTop: '20px',
        paddingBottom: '20px'
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    loginBtn: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: '#fbc70f',
        color: 'black',
    },
    white: {
        color: 'white'
    },
    yellow: {
        color: '#fbc70f'
    },
    centerText: {
        alignItems: "center",
        display: "flex"
    }
}));