import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {
    Box, Container, FormControl, FormControlLabel, Radio, RadioGroup, Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import '../App.css';
import LocationTireStorageType from "./LocationTireStorageType";
import TireService from "../services/TireService";

function LocationTireStorage({
                                 tireStorageData,
                                 refreshTireStorageDataHandler
                             }, ref) {
    const {t} = useTranslation('common');

    const [tireCategories, setTireCategories] = useState(null);
    const [tireWidths, setTireWidths] = useState(null);

    let newDataCount = 0;

    useEffect(() => {
        if (tireStorageData.list.length < 3) {
            // On ajoute un de chaque
            newOptionHandler('W');
            newOptionHandler('D');
            newOptionHandler('U');
        }
    }, [tireStorageData]);

    function getTireStorageValues(type) {
        let list = tireStorageData.list.filter(
            tire => {
                return tire.range_type === type;
            });
        return list;
    }

    function newOptionHandler(type = null) {
        if (type == null) {
            type = tireStorageData.type;
        }
        newDataCount++;
        tireStorageData.list.push({
            id: 'N' + newDataCount,
            range_type: type,
            from_width: null,
            to_width: null,
            from_tire_category_id: null,
            to_tire_category_id: null,
            price: null
        });
        refreshTireStorageDataHandler();
    }

    function deleteOptionHandler(option) {
        tireStorageData.list.splice(tireStorageData.list.indexOf(option), 1);
        refreshTireStorageDataHandler();
    }

    return (
        <Container sx={{
            ml: 1
        }}>
            <TireService setTireCategories={setTireCategories} setTireWidthsHandler={setTireWidths}/>
            <Box sx={{width: '50%'}}>
                {/*<Typography>{t('location.services.tire_storage.title')}</Typography>*/}
                <FormControl>
                    <RadioGroup
                        row
                        value={tireStorageData.type}
                        onChange={(e) => {
                            tireStorageData.type = e.target.value;
                            refreshTireStorageDataHandler();
                        }}
                    >
                        <FormControlLabel value="W" control={<Radio/>}
                                          label={t('location.services.tire_storage.width_dependant')}/>
                        <FormControlLabel value="D" control={<Radio/>}
                                          label={t('location.services.tire_storage.diameter_dependant')}/>
                        <FormControlLabel value="U" control={<Radio/>}
                                          label={t('location.services.tire_storage.unique_price')}/>
                    </RadioGroup>
                </FormControl>
                <LocationTireStorageType type={tireStorageData.type}
                                         tireCategories={tireCategories}
                                         tireWidths={tireWidths}
                                         tireStorageValues={getTireStorageValues(tireStorageData.type)}
                                         newOptionHandler={newOptionHandler}
                                         deleteOptionHandler={deleteOptionHandler}
                                         refreshTireStorageDataHandler={refreshTireStorageDataHandler}
                />
            </Box>
        </Container>
    )
};

export default LocationTireStorage;