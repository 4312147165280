import {Box, Button, Container, Grid} from "@mui/material";
import {Link} from "react-router-dom";
import ImageButton from "./ImageButton";
import btnEdit from "../assets/buttons/edit/write-white-yellow.png";
import btnEditOver from "../assets/buttons/edit/write-white-yellow.png";
import React from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {useTranslation} from "react-i18next";
import {makeStyles} from "@mui/styles";
import IconCarwash from "../assets/services/carwash/Car-Wash-B-G.png";
import IconTires from "../assets/services/tires/Pneu-B-G.png";
import defaultImage from "../assets/menu/location/emplacement-b-g.png";
import FormatUtils from "../utils/FormatUtils";

export default function LocationItem({
                                         locationData,
                                     }) {
    const {t} = useTranslation('common');
    const classes = useStyles();

    // TODO Redondant avec la définition des routes, et avec LocationModal
    function getEditPath(step, locationId) {
        let path = null;
        switch (step) {
            case 1 :
            case null:
                path = '/location/edit/' + locationId;
                break;
            case 2:
                path = '/location/info/' + locationId;
                break;
            case 3:
                path = '/location/services/' + locationId;
                break;
            case 4:
                path = '/location/schedules/' + locationId;
                break;
            default:
                console.log("Unknown step in item : " + step);
                return;
        }
        return path;
    }

    function getServiceIcon(serviceTypeId) {
        switch (serviceTypeId) {
            case 1:
                return IconCarwash;
                break;
            case 2:
                return IconTires;
                break;
            default:
                return null;
        }
    }

    // TODO From LocationInfo
    function getOptionExists(data, optionTypeId) {
        let option = data.options.find(option => option.location_option_type_id == optionTypeId);
        let exists = (option != null);
        return (exists);
    }

    function isMobileLocation(locationData) {
        return getOptionExists(locationData, 2);
    }

    return (
        <Container>
            <Box sx={{
                border: 0,
                borderRadius: '24px',
                p: '16px',
                m: '10px',
                backgroundColor: isMobileLocation(locationData) ? '#fbc70f' : 'black'
            }}>
                {/*borderColor: 'red'*/}
                <Grid container alignContent="center">
                    <Grid item xs={3} container alignContent="center">
                        <img src={
                            locationData.picture ? locationData.picture : defaultImage
                        } className={classes.pictureImg}/>
                    </Grid>
                    <Grid item xs={6} container alignContent="center">
                        {locationData.name}<br/>
                        {locationData.address}<br/>
                        {locationData.postal_code} {locationData.city}<br/>
                        {locationData.country}<br/>
                        {isMobileLocation(locationData) &&
                        <Grid container justifyContent="center">
                            <b>{t('location.list.mobile_location')}</b>
                        </Grid>
                        }
                        {FormatUtils.getLeasingCompaniesText(locationData, t)}
                    </Grid>
                    <Grid item xs={2} container alignContent="center">
                        <img src={getServiceIcon(locationData.service_type_id)} className={classes.serviceImg}/>
                    </Grid>
                    <Grid container item xs={1} alignContent="center" justifyContent="center">
                        {locationData.currentStep == null ?
                            <>
                                <Link to={getEditPath(locationData.currentStep, locationData.id)}>
                                    <ImageButton src={btnEdit} srcOver={btnEditOver}/>
                                </Link>
                                <Link to={getEditPath(4, locationData.id)}>
                                    <Button>
                                        <CalendarMonthIcon sx={
                                            {
                                                height: '40px',
                                                color: 'orange',
                                                fontSize: 60,
                                            }}/>
                                    </Button>
                                </Link>
                            </>
                            :
                            <Grid item alignContent="center" justifyContent="center">
                        <span style={{color: '#ff9c0d'}}>
                            <b>{t('location.list.draft')}</b> ({((locationData.currentStep - 1) / 4) * 100}%)
                            </span>
                                <Link to={getEditPath(locationData.currentStep, locationData.id)}>
                                    <Button>
                                        <NavigateNextIcon sx={
                                            {
                                                height: '40px',
                                                color: 'orange',
                                                fontSize: 60,
                                            }}/>
                                    </Button>
                                </Link>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}

const useStyles = makeStyles((theme) => ({
    pictureImg: {
        height: '100px',
        width: '90%',
        objectFit: 'contain',
        borderRadius: '5px'
    },
    serviceImg: {
        height: '100px',
    }
}));