import React, {forwardRef, useContext, useEffect, useRef, useState} from 'react';
import Swal from "sweetalert2";
import {useTranslation} from "react-i18next";
import {
    Box,
    Container,
    FormControl,
    FormControlLabel,
    FormGroup,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from "@mui/material";
import TireService from "../services/TireService";
import VehicleItemSubSectionTire from "./VehicleItemSubSectionTire";
import {useOutletContext} from "react-router-dom";
import FormatUtils from "../utils/FormatUtils";
import useFetch from "../hooks/useFetch";
import {TireAvailabilityType} from "../constants/TireAvailabilityType";
import {TireChoiceType} from "../constants/TireChoiceType";
import MobileContext from "../contexts/mobileContext";

function ServiceStepChooseTire({
                                   previousBtn,
                                   nextBtn,
                                   vehicle,
                                   location,
                                   validateTires,
                                   setLabel
                               }, ref) {

    const [apiUri] = useOutletContext();
    const {t} = useTranslation('common');
    // const token = localStorage.getItem('accessToken');

    const [localLocationData, setLocalLocationData] = useState(null);
    const [localVehicleData, setLocalVehicleData] = useState(null);

    const [localVehicleDataAggregated, setLocalVehicleDataAggregated] = useState(null);

    const [tireCategories, setTireCategories] = useState(null);
    const [selectedTires, setSelectedTires] = useState([]);

    const [tryToSelectTiresId, setTryToSelectTiresId] = useState(null);

    const [tireChoice, setTireChoice] = useState(null);
    const [quotationComment, setQuotationComment] = useState(null);

    const selectedTiresRef = useRef(null);
    selectedTiresRef.current = selectedTires;

    const localLocationDataRef = useRef(null);
    localLocationDataRef.current = localLocationData;

    const tireChoiceRef = useRef(null);
    tireChoiceRef.current = tireChoice;

    const quotationCommentRef = useRef(null);
    quotationCommentRef.current = quotationComment;

    const {call} = useFetch();

    const mobileContext = useContext(MobileContext);
    const isMobile = mobileContext.isMobile;

    useEffect(() => {
        setLabel('service.step_choose_tires.title');
        getVehicleFromServer(vehicle.id);
        getLocationFromServer(location.id);

        previousBtn.current.style.display = '';
        nextBtn.current.style.display = '';

        nextBtn.current.addEventListener('click', nextClickedHandler);

        return () => {
            if (nextBtn.current) {
                nextBtn.current.removeEventListener('click', nextClickedHandler);
            }
        }
    }, [])

    useEffect(() => {
        if (localVehicleDataAggregated == null) {
            return;
        }

        if (tryToSelectTiresId != null) {
            let newSelectedTires = [];
            tryToSelectTiresId.forEach(id => {
                let tire = localVehicleDataAggregated.tires.find(tire => tire.available == TireAvailabilityType.AVAILABLE && tire.id == id);
                if (tire != null) {
                    newSelectedTires.push(tire);
                }
            });
            setTryToSelectTiresId(null);
            setSelectedTires(newSelectedTires);
            return;
        }

        // On vérifie que les pneu selectionnés existent toujours sur le véhicule ( cas ou on a delete un pneu à la volée )
        let toDelete = [];
        selectedTires.forEach(tire => {
            let exists = localVehicleDataAggregated.tires.find(tire => tire.available && tire.id == selectedTires.id);
            if (exists == null) {
                toDelete.push(tire);
            }
        });

        toDelete.forEach(tire => {
            selectedTires.splice(selectedTires.indexOf(tire), 1);
        });

        setSelectedTires(selectedTires);
    }, [localVehicleDataAggregated]);

    // Aggregation des pneus et des pneus dispo sur l'emplacement
    useEffect(() => {
        if (localVehicleData == null || localLocationData == null) {
            return;
        }

        // On aggrège quels pneus sont dispos dans cet emplacement ( rempli le tire.available )
        localVehicleData.tires.forEach(tire => {
            tire.available = getLocationTireAvailableByCustomerTire(tire);
        });

        // Tri pour avoir les dispos en premier
        localVehicleData.tires.sort((a, b) => {
            if (a.available < b.available) {
                return -1;
            }
            if (a.available > b.available) {
                return 1;
            }
            if (a.available == b.available) {
                return 0;
            }
        });

        setLocalVehicleDataAggregated(FormatUtils.cloneData(localVehicleData));

    }, [localVehicleData, localLocationData]);

    function getLocationTireAvailableByCustomerTire(customerTire) {
        if (
            customerTire.store_location != null
            && customerTire.store_location.id != localLocationData.id) {
            return TireAvailabilityType.NON_AVAILABLE_STORE;
        }
        let isWheel = (customerTire.is_wheel == 1);
        for (let serviceIndex in localLocationData.services) {
            let service = localLocationData.services[serviceIndex];

            // console.log((service.tire_or_wheel == 1 && !isWheel), (service.tire_or_wheel == 2 && isWheel));
            if ((service.tire_or_wheel == 1 && !isWheel) || (service.tire_or_wheel == 2 && isWheel)) {
                // console.log('ok2');
                for (let priceIndex in service.prices) {
                    let price = service.prices[priceIndex];
                    if (price.tire_category_id == customerTire.tire_category_id) {
                        return TireAvailabilityType.AVAILABLE;
                    }
                }
            }
        }
        return TireAvailabilityType.NON_AVAILABLE_TYPE;
    }

    function nextClickedHandler() {
        if (checkSelectedTires()) {
            validateTires(selectedTiresRef.current, tireChoiceRef.current, quotationCommentRef.current);
        }
    }

    function checkSelectedTires() {
        let tires = selectedTiresRef.current;

        // console.log(tires);

        if (tires.length == 0) {
            Swal.fire({
                text: t('service.step_choose_tires.warning_must_select_tires'),
                icon: 'warning',
                target: document.getElementById('swal_container')
            });
            return false;
        }

        // Regles :
        // total count = 2 ou 4
        // pas plus de 1 de la même position
        // tous les pneus doivent etre du meme type ( roue / pneu )
        // tous les pneus doievent etre du meme diametre
        // si c'est un prestataire qui n'a que des pneus x2, on ne peut pas en commander 4
        let totalCount = 0;
        let positionCount = {
            null: 0,
            'F': 0,
            'B': 0
        }

        let categCount = {}

        let typeCount = {
            'tire': 0,
            'wheel': 0
        }

        tires.forEach(tire => {
            totalCount += tire.count;

            if (!(tire.tire_category_id in categCount)) {
                categCount[tire.tire_category_id] = 0;
            }
            categCount[tire.tire_category_id]++;

            positionCount[tire.position]++;
            if (tire.is_wheel == '1') {
                typeCount['wheel']++;
            } else {
                typeCount['tire']++;
            }
        });

        if (totalCount > 4) {
            Swal.fire({
                text: t('service.step_choose_tires.warning_too_many_tires'),
                icon: 'warning',
                target: document.getElementById('swal_container')
            });
            return false;
        }

        // console.log('totalCount:', totalCount, 'max:', getMaxTireCountForProvider());

        if (totalCount > getMaxTireCountForProvider()) {
            Swal.fire({
                text: t('service.step_choose_tires.warning_too_many_tires_service'),
                icon: 'warning',
                target: document.getElementById('swal_container')
            });
            return false;
        }

        if (totalCount < getMinTireCountForProvider()) {
            Swal.fire({
                text: t('service.step_choose_tires.warning_not_enough_tires_service'),
                icon: 'warning',
                target: document.getElementById('swal_container')
            });
            return false;
        }

        // console.log(categCount);

        if (Object.keys(categCount).length > 1) {
            Swal.fire({
                text: t('service.step_choose_tires.warning_categ_tires'),
                icon: 'warning',
                target: document.getElementById('swal_container')
            });
            return false;
        }

        for (const property in positionCount) {
            if (positionCount[property] > 1) {
                Swal.fire({
                    text: t('service.step_choose_tires.warning_position_count_tires'),
                    icon: 'warning',
                    target: document.getElementById('swal_container')
                });
                return false;
            }
        }

        if (typeCount['tire'] > 0 && typeCount['wheel'] > 0) {
            Swal.fire({
                text: t('service.step_choose_tires.warning_type_tires'),
                icon: 'warning',
                target: document.getElementById('swal_container')
            });
            return false;
        }
        return true;
    }

    // Renvoi le nombre maximum de pneus / roues que gère ce service
    function getMaxTireCountForProvider() {
        let max = 0;
        for (let serviceIndex in localLocationDataRef.current.services) {
            let service = localLocationDataRef.current.services[serviceIndex];
            if (service.tire_count > max) {
                max = service.tire_count;
            }
        }
        return max;
    }

    // Renvoi le nombre minimum de pneus / roues que gère ce service
    function getMinTireCountForProvider() {
        // console.log(localLocationData);
        let min = null;
        for (let serviceIndex in localLocationDataRef.current.services) {
            let service = localLocationDataRef.current.services[serviceIndex];
            if (min == null || service.tire_count < min) {
                min = service.tire_count;
            }
        }
        return min;
    }

    // newTiresId => Si des nouveaux pneus ont été ajoutés ( pour tenter des les cocher par défaut )
    function reloadHandler(newTiresId = null) {
        // console.log("newTiresId=", newTiresId);
        setTryToSelectTiresId(newTiresId);
        // TODO Tenter des les cocher ( après reload )
        getVehicleFromServer(vehicle.id);
    }

    function selectedHandler(tires) {
        // console.log('selected!', tires);
        setSelectedTires(tires);
    }

    // TODO redondant
    async function getVehicleFromServer(id) {
        call(apiUri + 'vehicle_get/' + id, (resultData => {
            setLocalVehicleData(FormatUtils.cloneData(resultData.vehicle));
        }));
    }

    // TODO Redondant
    async function getLocationFromServer(id) {
        call(apiUri + 'location_get/' + id, (resultData => {
            setLocalLocationData(resultData.location);
        }));
    }

    return (
        <>
            <TireService setTireCategories={setTireCategories}/>
            {
                tireCategories != null &&
                localVehicleDataAggregated &&
                <Container>
                    <FormControl>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={tireChoice}
                            onChange={(e) => {
                                setTireChoice(e.currentTarget.value);
                            }}
                        >
                            <FormControlLabel value={TireChoiceType.MINE}
                                              control={<Radio/>}
                                              label={t('service.step_choose_tires.tire_option_got_my_tires')}/>
                            <FormControlLabel value={TireChoiceType.BUY}
                                              control={<Radio/>}
                                              label={t('service.step_choose_tires.tire_option_buy_tires')}/>
                        </RadioGroup>
                    </FormControl>

                    {tireChoice == TireChoiceType.MINE &&
                    <VehicleItemSubSectionTire
                        vehicleData={localVehicleDataAggregated}
                        tireCategories={tireCategories}
                        reloadHandler={reloadHandler}
                        selectedTires={selectedTires}
                        selectedHandler={selectedHandler}
                    />}
                    {tireChoice == TireChoiceType.BUY &&
                    <>
                        <Typography color="orange">{t('service.step_choose_tires.tire_buy_notice')}</Typography>
                        <VehicleItemSubSectionTire
                            buyVersion={true}
                            vehicleData={localVehicleDataAggregated}
                            tireCategories={tireCategories}
                            reloadHandler={reloadHandler}
                            selectedTires={selectedTires}
                            selectedHandler={selectedHandler}
                        />
                        <FormGroup>
                            <FormControlLabel
                                style={{
                                    marginLeft: isMobile ? 0 : null,
                                    marginRight: isMobile ? 0 : null,
                                }}
                                labelPlacement="top" control={
                                <TextField
                                    style={{
                                        width: isMobile ? '100%' : '565px',
                                        padding: isMobile ? '0px' : null,
                                        paddingLeft: isMobile ? null : '20px',
                                    }}
                                    multiline
                                    rows={3}
                                    placeholder={t('service.step_choose_tires.tire_buy_comment_placeholder')}
                                    value={quotationComment || ''}
                                    onChange={(e) => {
                                        setQuotationComment(e.currentTarget.value);
                                    }}
                                />
                            }
                                label={<Typography
                                    fontSize={isMobile ? 18 : 20}
                                    color="#fbc70f"
                                    m={isMobile ? 0 : 2}
                                    mt={isMobile ? 1 : null}
                                    ml={isMobile ? 0 : null}
                                >
                                    {t('service.step_choose_tires.tire_buy_comment')}
                                </Typography>}/>
                        </FormGroup>
                    </>}
                </Container>
            }
        </>
    );
};

export default forwardRef(ServiceStepChooseTire);