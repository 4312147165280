import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid, Typography
} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MobileContext from "../contexts/mobileContext";

export default function VehicleItemSection({
                                               icon,
                                               label,
                                               detailContent,
                                               expandedHandler = null,
                                               defaultExpanded = false
                                           }) {
    const [expanded, setExpanded] = useState(defaultExpanded);
    const mobileContext = useContext(MobileContext);
    const isMobile = mobileContext.isMobile;

    useEffect(() => {
        if (defaultExpanded) {
            setExpanded(defaultExpanded);
        }
    }, [defaultExpanded])

    return (
        <Accordion
            disableGutters={isMobile}
            expanded={expanded}

            sx={{
                backgroundColor: '#666666',
            }}
            onChange={(e, exp) => {
                setExpanded(exp);
                if (exp) {
                    if (expandedHandler != null) {
                        expandedHandler();
                    }
                }
            }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                    padding: isMobile ? 1 : '',
                    height: isMobile ? '50px' : '80px'
                }}
            >
                <Grid container>
                    <Grid item xs={2} sm={1}>
                        <img src={icon} style={{width: isMobile ? '30px' : '50px'}}></img>
                    </Grid>
                    <Grid item xs={10} sm={11} container alignContent="center">
                        <Typography fontSize={isMobile ? '' : '18px'} color="black"><b>{label}</b></Typography>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    padding: isMobile ? 1 : ''
                }}
            >
                <Grid container>
                    {detailContent}
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}