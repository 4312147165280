// https://react-international-phone.vercel.app/docs/Advanced%20Usage/useWithUiLibs
import React, {useEffect} from 'react';
import MuiPhoneNumber from "mui-phone-number";
import {PhoneNumberUtil} from "google-libphonenumber";

export const KucPhoneTextField = ({value, onChange, ...restProps}) => {

    const phoneUtil = PhoneNumberUtil.getInstance();

    function checkPhone(newPhone) {
        try {
            return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(newPhone));
        } catch (error) {
            // console.log(error);
            return false;
        }
    }

    // Première fois que value est setté, je veux checker le phone
    // NB : MuiPhoneNumber ne dispatch pas de onChange à ce moment là
    useEffect(() => {
        onChange(value, checkPhone(value));
    }, [])

    function onChangeHandler(value) {
        onChange(value, checkPhone(value));
    }

    return (
        <MuiPhoneNumber
            defaultCountry={'ch'}
            preferredCountries={['ch', 'fr']}
            disableAreaCodes={true}
            value={value}
            onChange={onChangeHandler}
            {...restProps}
        />
    );
};