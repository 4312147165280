import React, {useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import {useOutletContext} from "react-router-dom";
import {useTranslation} from "react-i18next";

import btnEdit from "../assets/buttons/edit/write-white-yellow.svg";
import defaultImage from "../assets/menu/profile/profil-b-g.png";
import useFetch from "../hooks/useFetch";

function ProfilePictureUpload(props) {
    const classes = useStyles();
    const [imageUrl, setImageUrl] = useState(null);

    const [apiUri] = useOutletContext();
    const {call} = useFetch();

    useEffect(() => {
        setImageUrl(props.profile_picture);
    }, [])

    const handleUploadClick = event => {
        var file = event.target.files[0];

        let data = new FormData();
        data.append("file", file);

        call(apiUri + 'upload_user_picture', (resultData) => {
            setImageUrl(resultData.profile_picture)
        }, 'POST', data);
    };

    return <>
        <input
            accept="image/*"
            className={classes.uploadInput}
            id="contained-button-file"
            multiple
            type="file"
            onChange={handleUploadClick}
        />
        <label htmlFor="contained-button-file">
            <img src={imageUrl == null ? defaultImage : imageUrl} className={classes.profilePictureImg}/>
            <img src={btnEdit} className={classes.editBtn}/>
        </label>
    </>
}

export default ProfilePictureUpload;

const useStyles = makeStyles((theme) => ({
    profilePictureImg: {
        height: '100px',
        width: '100px',
        objectFit: 'contain',
        borderRadius: '5px',
        cursor: 'pointer'
    },
    editBtn: {
        height: '32px',
        cursor: 'pointer',
        marginLeft: '5px'
    },
    uploadInput: {
        display: "none"
    }
}));