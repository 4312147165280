import React from "react";
import {
    Box,
    Button,
    Grid,
} from "@mui/material";
import {useTranslation} from "react-i18next";
import LocationScheduleRow from "./LocationScheduleRow";

export default function LocationScheduleTab(props) {

    const {t} = useTranslation('common');

    const {
        index,
        currentTabIndex,
        scheduleData,
        refreshDataHandler,
        applyAllHandler,
        ...other
    } = props;

    return (
        <div
            role="tabpanel"
            hidden={currentTabIndex !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {currentTabIndex === index && (
                <Box sx={{p: 3}}>
                    <LocationScheduleRow scheduleData={scheduleData} dataChangedHandler={refreshDataHandler} type="am"/>
                    <Grid sx={{borderBottom: 1, borderColor: 'divider', marginTop: "10px", marginBottom: '10px'}}/>
                    <LocationScheduleRow scheduleData={scheduleData} dataChangedHandler={refreshDataHandler} type="pm"/>
                    <br/>
                    <Grid container alignContent="center" justifyContent="center">
                        <Button
                            variant="contained"
                            color="yellow"
                            onClick={() => applyAllHandler(scheduleData)}
                        >
                            {t('location.schedules.apply_to_all')}
                        </Button>
                    </Grid>
                </Box>
            )}
        </div>
    );
}