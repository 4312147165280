import React, {useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import {useOutletContext} from "react-router-dom";
import {useTranslation} from "react-i18next";
import useFetch from "../hooks/useFetch";
import {Box, Grid, Typography} from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export default function PictureUploadMini({
                                              picture_uri,
                                              model_id,
                                              api_uri,
                                              default_picture
                                          }) {
    const classes = useStyles();
    const [imageUrl, setImageUrl] = useState(null);

    const [apiUri] = useOutletContext();
    const {t} = useTranslation('common');
    const {call} = useFetch();

    useEffect(() => {
        setImageUrl(picture_uri);
    }, [])

    const handleUploadClick = event => {
        var file = event.target.files[0];

        let data = new FormData();
        data.append('id', model_id);
        data.append('file', file);

        call(apiUri + api_uri, (resultData) => {
            setImageUrl(resultData.picture)
        }, 'POST', data);
    };

    return <Box display="flex" justifyContent="left"
                sx={{
                    border: 2,
                    borderRadius: '8px',
                    borderColor: 'orange',
                    p: '8px',
                    mt: '0px',
                    ml: '16px',
                    mr: '16px',
                    color: 'orange',
                    width: '100%'
                }}>
        <Grid container p={0}>
            <Grid item xs={6} container alignContent="center">
                <Typography>{t('vehicle.form.picture_field_label')}</Typography>
            </Grid>
            <Grid item xs={4} container justifyContent="center" alignContent="center"
                  style={{
                      // backgroundColor : "black"
                  }}>
                <img src={imageUrl == null ? default_picture : imageUrl} style={{}}
                     className={classes.profilePictureImg}/>
            </Grid>
            <Grid item xs={2} container justifyContent="right" alignContent="center">
                <input
                    accept="image/*"
                    className={classes.uploadInput}
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={handleUploadClick}
                />
                <label htmlFor="contained-button-file"
                       style={{
                           lineHeight: '0px',
                           // height: '32px',
                           // width: '32px'
                       }}
                >
                    <AddCircleOutlineIcon className={classes.editBtn}/>
                </label>
            </Grid>
        </Grid>
    </Box>
}

const useStyles = makeStyles((theme) => ({
    profilePictureImg: {
        height: '30px',
        width: '100%',
        objectFit: 'contain',
        borderRadius: '5px',
        cursor: 'pointer'
    },
    editBtn: {
        height: '32px',
        cursor: 'pointer',
        // marginLeft: '5px'
    },
    uploadInput: {
        display: "none"
    }
}));