import React, {forwardRef, useContext, useEffect, useImperativeHandle, useState} from "react";
import {
    Box, Button, Checkbox, FormControlLabel, FormGroup,
    Grid, IconButton, InputAdornment,
    Stack, TextField,
    Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import '../App.css';
import TextWithBr from "./TextWithBr";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DoneIcon from '@mui/icons-material/Done';
import MobileContext from "../contexts/mobileContext";
import StarIcon from '@mui/icons-material/Star';
import FormatUtils from "../utils/FormatUtils";
import SearchIcon from '@mui/icons-material/Search';
import InfoIcon from "@mui/icons-material/Info";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

function ServiceOptionPriceItem({
                                    optionData,
                                    serviceData,    // For currency only
                                    selected,
                                    clickedHandler: clickedHandler,
                                    valetSearchHandler: valetSearchHandler,
                                    valetData,
                                    valetComment
                                }, ref) {
    const {t} = useTranslation('common');

    const [expanded, setExpanded] = useState(false);

    const mobileContext = useContext(MobileContext);
    const isMobile = mobileContext.isMobile;

    // Valet
    const [valetAddress, setValetAddress] = useState(valetData ? valetData.origin : ''); // NB Util lors du retour
    const [canValidateAddress, setCanValidateAddress] = useState(false);
    const [localValetComment, setLocalValetComment] = useState(valetComment);
    const MySwal = withReactContent(Swal);

    useImperativeHandle(ref, () => ({
        getValetComment() {
            return localValetComment;
        }
    }));

    let border = 1;
    let borderColor = '';
    if (selected) {
        border = 2;
        borderColor = '#fbc70f';
    }

    function isTireStorage() {
        if ('isStoreTire' in optionData && optionData.isStoreTire) {
            return true;
        } else {
            return false;
        }
    }

    function isValet() {
        if ('type' in optionData && optionData.type == 'valet') {
            return true;
        } else {
            return false;
        }
    }

    function isDisabled() {
        return (optionData.hasOwnProperty('enabled') && optionData.enabled == false);
    }

    function expandArrowClicked() {
        setExpanded(!expanded);
    }

    function getHeaderPrice() {
        if (!isValet()) {
            return FormatUtils.formatPrice(optionData.price.price);
        } else {
            if (selected && valetData && valetData.price) {

                // resultData.valet_intent.price = FormatUtils.formatAmount(resultData.valet_intent.price);
                // console.log("valetData.price=", valetData.price);
                return FormatUtils.formatAmount(valetData.price, true);
            } else {
                return null;
            }
        }
    }

    useEffect(() => {
        if (isValet()) {
            setExpanded(selected);
        }
    }, [selected]);

    // Si valetData change ca veut dire que ca vient de l'appel WS
    // useEffect(() => {
    //     setCanValidateAddress(false);
    // }, [valetData]);

    function showValetInfoHandler() {
        MySwal.fire({
            text: t('service.step3.valet_description_disabled_info'),
            confirmButtonText: t('common.continue'),
            icon: 'warning',
            target: document.getElementById('swal_container')
        });
    }

    return (
        <div style={{
            position: 'relative'
        }}>
            <Box p={1} border={border} borderColor={borderColor} style={{
                opacity: isDisabled() ? 0.3 : 1,
                pointerEvents: isDisabled() ? 'none' : 'initial',
            }}>
                <Grid container={!isMobile}>
                    <Grid item width={isMobile ? '100%' : '50%'}
                          style={{
                              // backgroundColor: 'blue'
                          }}
                    >
                        <Stack>
                            <Grid container
                                  alignItems="center"
                                  style={{
                                      width: '100%'
                                  }}
                            >
                                {!isMobile &&
                                    <Grid item xs={isMobile ? 1 : null}>
                                        <Checkbox
                                            sx={{
                                                p: isMobile ? 0 : null,
                                            }}
                                            checked={selected}
                                            onChange={(e) => clickedHandler(optionData, !selected)}
                                        />
                                    </Grid>
                                }
                                <Grid item xs={isMobile ? 11 : null}>
                                    <Grid container
                                          style={{
                                              // backgroundColor: 'red'
                                          }}
                                    >
                                        {isValet() &&
                                            <Grid item
                                                  style={{
                                                      // backgroundColor: 'blue',
                                                      display: 'flex',
                                                      alignItems: 'center'
                                                  }}
                                            >
                                                <StarIcon
                                                    style={{
                                                        color: 'orange',
                                                        marginRight: '2px'
                                                    }}
                                                />
                                            </Grid>
                                        }
                                        <Grid item>
                                            <TextWithBr fontSize={isMobile ? 15 : 20}
                                                        text={optionData.name}></TextWithBr>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={isMobile ? 1 : null} display="flex"
                                    // alignContent="center"
                                >
                                    <KeyboardArrowDownIcon
                                        onClick={(e) => expandArrowClicked()}
                                        style={{
                                            cursor: 'pointer',
                                            transform: expanded ? 'rotate(180deg)' : ''
                                        }}/>
                                </Grid>
                            </Grid>
                            {
                                expanded &&
                                <TextWithBr text={optionData.description}></TextWithBr>
                            }
                            {expanded && isValet() && selected &&
                                <Stack>
                                    <TextField
                                        style={{
                                            marginTop: '10px',
                                            marginBottom: '10px'
                                        }}
                                        // multiline
                                        // rows={2}
                                        label={t('service.step3.valet_address')}
                                        value={valetAddress}
                                        required
                                        onChange={(e) => {
                                            setValetAddress(e.currentTarget.value);
                                            if (e.currentTarget.value != '') {
                                                setCanValidateAddress(true);
                                            } else {
                                                setCanValidateAddress(false);
                                            }
                                        }}
                                        onKeyDown={(ev) => {
                                            console.log(`Pressed keyCode ${ev.key}`);
                                            if (ev.key === 'Enter') {
                                                setCanValidateAddress(false);
                                                valetSearchHandler(valetAddress);
                                                ev.preventDefault();
                                            }
                                        }}
                                    />
                                    <Box textAlign='center'>
                                        <Button
                                            disabled={!canValidateAddress}
                                            sx={{
                                                height: '30px',
                                                width: '100%',
                                                mb: '10px'
                                            }}
                                            variant="contained"
                                            color="yellow"
                                            onClick={
                                                (e) => {
                                                    setCanValidateAddress(false);
                                                    valetSearchHandler(valetAddress);
                                                }
                                            }
                                        >
                                            {t('service.step3.valet_search')}
                                        </Button>
                                    </Box>

                                    {valetData &&
                                        <>
                                            {valetData.gmap_status != 'OK' ?
                                                <Typography style={{
                                                    color: 'red'
                                                }}>
                                                    {t('service.step3.warning_address_not_valid')}
                                                </Typography>
                                                : <>
                                                    <Typography>
                                                        {t('service.step3.valet_validated_address')}
                                                        <span
                                                            style={{
                                                                color: '#fbc70f'
                                                            }}
                                                        >{(valetData.gmap_origin)}</span>
                                                    </Typography>
                                                    <Typography>
                                                        {t('service.step3.valet_km')}
                                                        <span
                                                            style={{
                                                                color: '#fbc70f'
                                                            }}
                                                        >{FormatUtils.formatDistance(valetData.gmap_distance)}</span>
                                                    </Typography>

                                                    <Typography>
                                                        {t('service.step3.valet_price')}
                                                        <span
                                                            style={{
                                                                color: '#fbc70f'
                                                            }}
                                                        >{FormatUtils.formatAmount(valetData.price, true)}</span>
                                                    </Typography>
                                                    <TextField
                                                        style={{
                                                            marginTop: '10px',
                                                            marginBottom: '10px'
                                                        }}
                                                        // multiline
                                                        // rows={2}
                                                        label={t('service.step3.valet_comment')}
                                                        value={localValetComment}
                                                        onChange={(e) => {
                                                            setLocalValetComment(e.currentTarget.value);
                                                        }}
                                                    />
                                                </>
                                            }
                                        </>
                                    }
                                </Stack>
                            }
                        </Stack>
                    </Grid>

                    <Grid item container={isMobile} justifyContent={isMobile ? "center" : null}
                          style={{
                              // backgroundColor: 'red'
                          }}
                    >

                        {isDisabled() &&
                            <Typography mt="5px">
                                {optionData.description}
                            </Typography>
                        }
                        <Typography color="#fbc70f" mt="5px"
                                    fontSize={isMobile ? 15 : 20}>{getHeaderPrice()}</Typography>
                    </Grid>
                    <Grid item container={isMobile}
                          justifyContent={isMobile ? "center" : null}
                          ml={isMobile ? null : 'auto'}
                          style={{
                              // backgroundColor: '#afbff0'
                          }}
                    >
                        <Button
                            sx={{
                                height: '30px',
                                width: '200px',
                                // ml: isMobile ? null : 'auto',
                                mr: isMobile ? null : '20px',
                                mt: '5px'
                            }}
                            variant="contained"
                            color="yellow"
                            onClick={(e) => clickedHandler(optionData, !selected)}
                        >
                            {selected ?
                                <DoneIcon/>
                                :
                                t('service.step3.option_select')
                            }
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            {isValet() && isDisabled() &&
                <Box style={{
                    position: 'absolute',
                    top: 10,
                    right: 10,
                    zIndex: 1
                }}>
                    <InfoIcon
                        cursor="pointer"
                        onClick={showValetInfoHandler}
                        sx={
                            {
                                color: 'orange',
                                fontSize: 28,
                            }}/>
                </Box>
            }
        </div>
    )
};

export default forwardRef(ServiceOptionPriceItem);