import React, {useEffect, useRef, useState} from 'react';
import {
    Box, Button, Grid, Modal, TextField, Typography
} from "@mui/material";
import {Link, Navigate, Outlet, useLocation, useNavigate, useOutletContext, useParams} from "react-router-dom";
import LocationStepper from "./LocationStepper";

import btnClose from "../assets/buttons/close/close-nobackground.svg";
import btnCloseOver from "../assets/buttons/close/close-nobackground.svg";
import ImageButton from "./ImageButton";

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import {useTranslation} from "react-i18next";
import Swal from "sweetalert2";

export default function LocationModal({
                                          step,
                                          children
                                      }) {
    let params = useParams();
    const [activeStep, setActiveStep] = useState(parseInt(step));

    const [contentLoaded, setContentLoaded] = useState(false);
    const [locationData, setLocationData] = useState(null);
    const locationDataRef = useRef();
    locationDataRef.current = locationData;

    const previousBtnRef = useRef();
    const nextBtnRef = useRef();

    const childRef = useRef();

    const navigate = useNavigate();

    const [apiUrl, locationUpdatedHandler] = useOutletContext();

    const {t} = useTranslation('common');

    // Gestion de la sauvegarde
    const [needSave, setNeedSave] = useState(false);
    const [actionAfterSave, setActionAfterSave] = useState(null);
    const [tabAfterSave, setTabAfterSave] = useState(null);

    // TODO Externaliser ca ( dans un hook ? Utiliser les ref, car ca n'a pas à mettre à jour l'état )
    const actionAfterSaveRef = useRef();
    const tabAfterSaveRef = useRef();
    actionAfterSaveRef.current = actionAfterSave;
    tabAfterSaveRef.current = tabAfterSave;

    // useEffect(() => {
    //     console.log('locationId', locationId);
    // }, [locationId]);

    useEffect(() => {
        setActiveStep(parseInt(step));
    }, [step])

    // TODO depend de là ou on s'est arrété ( donc on doit récupérer le contenu de l'appel api )
    function isEditMode() {
        return locationDataRef.current != null && locationDataRef.current.currentStep == null;
    }

    function contentLoadedHandler(locationData) {
        setContentLoaded(true);
        setLocationData(locationData);
    }

    // User clicked on a different step => Notify current content
    function changeStepFromMenuHandler(e, stepValue) {
        if (needSave) {
            saveNeeded('tab', stepValue);
        } else {
            changeStepFromContentHandler(stepValue);
        }

        // childRef.current.wantChangeTab(stepValue);
    }

    // Current content want to switch step
    function changeStepFromContentHandler(stepValue, newLocationId = null) {
        // TODO Pas top , stocker les routes dans un tableau à part en commun avec la définition ?
        setContentLoaded(false);

        let path = null;
        let locationId = params.locationId;
        if (newLocationId != null) {
            locationId = newLocationId;
        }

        if (locationId == null) {
            console.log("No current locationId");
            return;
        }

        switch (stepValue) {
            case -1:
                path = '/location/';
                locationUpdatedHandler();
                break;
            case 0 :
                path = '/location/edit/' + locationId;
                break;
            case 1:
                path = '/location/info/' + locationId;
                break;
            case 2:
                path = '/location/services/' + locationId;
                break;
            case 3:
                path = '/location/schedules/' + locationId;
                break;
            default:
                console.log("Unknown step : " + stepValue);
                return;
        }

        // console.log("Navigating to " + path);
        navigate(path);
    }

    function closeButtonHandler() {
        if (needSave) {
            saveNeeded('close');
        } else {
            closeFromContentHandler();
        }
    }

    // Children allows to close content
    function closeFromContentHandler() {
        locationUpdatedHandler();
    }

    function saveNeededHandler(value) {
        setNeedSave(value);
    }

    // Content canceled save ( an error in needed fields for example )
    function canceledSaveHandler() {
        // setNeedSave(false);
        setTabAfterSave(null);
        setActionAfterSave(null);
    }

    function contentSavedHandler(currentStep, newLocationId = null) {
        // setLocationId(newLocationId);
        console.log('contentSavedHandler', isEditMode(), actionAfterSaveRef.current, tabAfterSaveRef.current);
        setNeedSave(false);

        if (actionAfterSaveRef.current === 'close') {
            setActionAfterSave(null);
            setTabAfterSave(null);
            changeStepFromContentHandler(-1, newLocationId);
            return;
        }

        if (isEditMode()) {
            if (actionAfterSaveRef.current === 'tab') {
                setActionAfterSave(null);
                setTabAfterSave(null);
                changeStepFromContentHandler(tabAfterSaveRef.current);
            }
        } else {
            currentStep++;
            if (currentStep > 3)   // Dernière étape
            {
                currentStep = -1;
            }
            changeStepFromContentHandler(currentStep, newLocationId);
        }
    }

    function saveNeeded(action, tab = null) {
        Swal.fire({
            title: t('common.warning.need_save'),
            showDenyButton: true,
            confirmButtonText: t('common.yes'),
            denyButtonText: t('common.no'),
            target: document.getElementById('swal_container'),
        }).then((result) => {
            if (result.isConfirmed) {
                setActionAfterSave(action);
                setTabAfterSave(tab);
                childRef.current.save();
            } else if (result.isDenied) {
                setNeedSave(false);
                if (action == 'close') {
                    closeFromContentHandler();
                }
                if (action == 'tab') {
                    changeStepFromContentHandler(tab);
                }
            }
        })
    }

    // function newLocationIdHandler(newLocationId) {
    //     setLocationId(newLocationId);
    // }

    return (
        <Modal
            open={true}
            style={{
                overflowY: 'scroll'
            }}
            disableEnforceFocus
        >
            <div style={style}>
                <Box sx={styleInner}>
                    <Grid container justifyContent="flex-end">
                        <Grid item sx={{flexGrow: 1}}>
                            <Grid container justifyContent="center">
                                <h3>{
                                    contentLoaded && (
                                        locationData == null ?
                                            t('location.modal_default_title')
                                            :
                                            locationData.name)
                                }</h3>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <ImageButton src={btnClose} srcOver={btnCloseOver} onClickHandler={closeButtonHandler}/>
                        </Grid>
                    </Grid>
                    <Box>
                        {contentLoaded &&
                        <LocationStepper activeStep={activeStep} editMode={isEditMode()}
                                         changeStepHandler={changeStepFromMenuHandler}/>
                        }
                        {
                            React.cloneElement(children,
                                {
                                    previous_btn: previousBtnRef,
                                    next_btn: nextBtnRef,
                                    isEditMode: isEditMode(),
                                    contentLoaded: contentLoaded,
                                    contentLoadedHandler: contentLoadedHandler,
                                    changeStepFromContentHandler: changeStepFromContentHandler,
                                    closeFromContentHandler,
                                    saveNeededHandler,
                                    contentSavedHandler,
                                    canceledSaveHandler,
                                    ref: childRef
                                }
                            )}
                    </Box>

                    <Grid container justifyContent="center">
                        {/*<Link to="/location">*/}
                        <Button ref={previousBtnRef}>
                            <NavigateBeforeIcon sx={
                                {
                                    color: 'orange',
                                    fontSize: 60,
                                }}/>
                        </Button>

                        {isEditMode() ?
                            <Button
                                ref={nextBtnRef}
                                variant="contained"
                                color="yellow"
                                sx={{mt: '15px', mb: '20px'}}
                            >
                                {t('common.save')}
                            </Button>
                            :
                            <Button ref={nextBtnRef}
                                    color="yellow">

                                {t('common.next_step')}
                                <NavigateNextIcon sx={
                                    {
                                        color: 'orange',
                                        fontSize: 60,
                                    }}/>
                            </Button>
                        }

                    </Grid>
                </Box>
            </div>
        </Modal>
    );
}

const style = {
    position: 'absolute',
    top: '2.5%',
    bottom: '2.5%',
    left: '2.5%',
    right: '2.5%',
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    // width: '95%',
    // maxHeight: '100%',
    // height : '100%'
};

const styleInner = {
    bgcolor: 'background.paper',
    border: '2px solid #000',
    minHeight: '100%'
}