import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {
    Button,
    Container, FormControl, FormControlLabel,
    Grid, InputLabel,
    MenuItem, OutlinedInput, Paper, Radio,
    Select,
    Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import '../App.css';
import DeleteIcon from "@mui/icons-material/Delete";
import PriceField from "./PriceField";


// type = W / D
function LocationTireStorageElement({
                                        tireStorageValue,
                                        type,
                                        tireCategories,
                                        tireWidths,
                                        refreshTireStorageDataHandler,
                                        canDelete,
                                        deleteOptionHandler
                                    }) {

    const {t} = useTranslation('common');

    let inputLabel = null;
    switch (type) {
        case 'W':
            inputLabel = t('location.services.tire_storage.width');
            break;
        case 'D':
            inputLabel = t('location.services.tire_storage.diameter');
            break;
        case 'U':
            break;
    }

    return (
        <Container maxWidth={false} disableGutters={true}>
            <Grid container justifyContent="center" sx={{width: '100%'}}>
                <Grid container
                      sx={{
                          maxWidth: '100%',
                          backgroundColor: '#292e32',
                          marginTop: '1px'
                      }}>
                    <>
                        {type != 'U' &&
                        <>
                            <Grid item container xs={1} alignContent="center" justifyContent="center">
                                <Typography>{t('common.from')}</Typography>
                            </Grid>
                            <Grid item container xs={3} alignContent="center" justifyContent="center">
                                <FormControl fullWidth size="small">
                                    <InputLabel shrink>{inputLabel}</InputLabel>
                                    {type === 'D' && tireCategories != null &&

                                    <Select
                                        input={<OutlinedInput notched
                                                              label={inputLabel}/>}
                                        value={tireStorageValue.from_tire_category_id || ''}
                                        onChange={
                                            e => {
                                                tireStorageValue.from_tire_category_id = e.target.value;
                                                refreshTireStorageDataHandler();
                                            }
                                        }
                                    >
                                        {tireCategories.map((tireCategory) => {
                                            return <MenuItem key={tireCategory.id}
                                                             value={tireCategory.id}>{tireCategory.label}</MenuItem>
                                        })}
                                    </Select>
                                    }
                                    {type === 'W' && tireWidths != null &&
                                    <Select
                                        input={<OutlinedInput notched
                                                              label={inputLabel}/>}
                                        value={tireStorageValue.from_width || ''}
                                        onChange={
                                            e => {
                                                tireStorageValue.from_width = e.target.value;
                                                refreshTireStorageDataHandler();
                                            }
                                        }
                                    >
                                        {tireWidths.map((value) => {
                                            return <MenuItem key={value} value={value}>{value}mm</MenuItem>
                                        })}
                                    </Select>
                                    }
                                </FormControl>
                            </Grid>
                            <Grid item container xs={1} alignContent="center" justifyContent="center">
                                <Typography>{t('common.to')}</Typography>
                            </Grid>
                            <Grid item container xs={3} alignContent="center" justifyContent="center">
                                <FormControl fullWidth size="small">
                                    <InputLabel shrink>{inputLabel}</InputLabel>
                                    {type === 'D' && tireCategories &&
                                    <Select

                                        input={<OutlinedInput notched
                                                              label={inputLabel}/>}

                                        value={tireStorageValue.to_tire_category_id || ''}
                                        onChange={
                                            e => {
                                                tireStorageValue.to_tire_category_id = e.target.value;
                                                refreshTireStorageDataHandler();
                                            }
                                        }
                                    >
                                        {tireCategories.map((tireCategory) => {
                                            return <MenuItem key={tireCategory.id}
                                                             value={tireCategory.id}>{tireCategory.label}</MenuItem>
                                        })}
                                    </Select>
                                    }
                                    {type === 'W' && tireWidths != null &&

                                    <Select
                                        input={<OutlinedInput notched
                                                              label={inputLabel}/>}

                                        value={tireStorageValue.to_width || ''}
                                        onChange={
                                            e => {
                                                tireStorageValue.to_width = e.target.value;
                                                refreshTireStorageDataHandler();
                                            }
                                        }
                                    >
                                        {tireWidths.map((value) => {
                                            return <MenuItem key={value} value={value}>{value}mm</MenuItem>
                                        })}
                                    </Select>
                                    }
                                </FormControl>
                            </Grid>
                            <Grid item container xs={3} alignContent="center" justifyContent="center" sx={{
                                p: 1
                            }}>
                                <PriceField

                                    value={tireStorageValue.price}
                                    valueChangedHandler={(price) => {
                                        tireStorageValue.price = price;
                                        refreshTireStorageDataHandler();
                                    }}
                                />
                            </Grid>
                            <Grid item container xs={1} alignContent="center" justifyContent="center">
                                {canDelete &&
                                <Button onClick={(e) => deleteOptionHandler(tireStorageValue)}>
                                    <DeleteIcon sx={
                                        {
                                            color: 'orange',
                                            fontSize: 16,
                                        }}/>
                                </Button>}
                            </Grid>
                        </>
                        }
                        {type == 'U' &&
                        <Grid item container xs={3} alignContent="center" justifyContent="center" sx={{
                            m: 1
                        }}>
                            <PriceField
                                value={tireStorageValue.price}
                                valueChangedHandler={(price) => {
                                    tireStorageValue.price = price;
                                    refreshTireStorageDataHandler();
                                }}
                            />
                        </Grid>
                        }
                    </>
                </Grid>
            </Grid>
        </Container>
    )
};

export default LocationTireStorageElement;