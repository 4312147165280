import {IconButton} from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import ServicePriceItem from "./ServicePriceItem";
import {Carousel} from "react-responsive-carousel";
import React, {useContext} from "react";
import MobileContext from "../contexts/mobileContext";

export function ServiceCarousel({
                                    servicesData,
                                    selectedService,
                                    serviceSelectedHandler
                                }) {
    function isSelectedService(serviceData) {
        if (selectedService == null || serviceData == null) {
            return false;
        }
        return selectedService.id == serviceData.id;
    }

    return (
        <Carousel
            // width="200px"
            centerMode
            centerSlidePercentage={servicesData.length <= 1 ? 100 : 75}
            infiniteLoop={false}
            showIndicators={servicesData.length <= 1 ? false : true}
            showThumbs={false}
            showArrows={true}
            showStatus={false}


            // renderArrowPrev={(onClickHandler, hasPrev, label) =>
            //     hasPrev && (
            //         <IconButton
            //             size="small"
            //             color="yellow"
            //             onClick={onClickHandler}
            //             sx={{
            //                 width: isMobile ? '20px' : null,
            //                 // marginLeft: isMobile ? '-10px' : null,
            //             }}
            //         >
            //             <NavigateBeforeIcon sx={
            //                 {
            //                     color: 'orange',
            //                     fontSize: 30,
            //                 }}/>
            //         </IconButton>
            //
            //
            //         // <button type="button" onClick={onClickHandler} title={label}
            //         //         style={{
            //         //             // ...arrowStyles,
            //         //             left: 15
            //         //         }}>
            //         //     -
            //         // </button>
            //     )
            // }
            // renderArrowNext={(onClickHandler, hasNext, label) =>
            //     hasNext && (
            //         <button type="button" onClick={onClickHandler} title={label}
            //                 style={{
            //                     // ...arrowStyles,
            //                     right: 15
            //                 }}>
            //             +
            //         </button>
            //     )
            // }
        >
            {
                servicesData.map((serviceData, i) => {
                    return (<ServicePriceItem key={i} selected={isSelectedService(serviceData)}
                                              serviceData={serviceData}
                                              clickedHandler={serviceSelectedHandler}/>);
                })
            }
        </Carousel>
    )
}