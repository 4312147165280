import React from "react";
import {Button, Typography} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import {useTranslation} from "react-i18next";
import {useOutletContext} from "react-router-dom";
import useFetch from "../hooks/useFetch";

function VehicleDocumentRow({vehicleData, document, reloadHandler}) {
    const {t} = useTranslation('common');
    const [apiUri] = useOutletContext();
    const {call} = useFetch();

    function deleteDocumentHandler(e) {
        e.stopPropagation();
        Swal.fire({
            title: t('vehicle.item.documents.delete.warning'),
            showCancelButton: true,
            confirmButtonColor: '#dd6b55',
            confirmButtonText: t('common.yes'),
            cancelButtonText: t('common.no'),
        }).then((result) => {
            if (result.isConfirmed) {
                deleteDocument();
            }
        })
    }

    async function deleteDocument() {
        call(apiUri + 'vehicle_document', (resultData => {
            Swal.fire(t('vehicle.item.documents.delete.success'), '', 'success', {timer: 2000})
                .then(() => {
                    reloadHandler();
                })
        }), 'DELETE', {
            vehicle_id: vehicleData.id,
            id: document.id
        }, true);
    }

    return <Typography>
        <a href={document.picture} target="_blank">{document.label}</a>
        <Button onClick={(e) => deleteDocumentHandler(e)}>
            <DeleteIcon sx={
                {
                    color: 'orange',
                    fontSize: 16,
                }}/>
        </Button>
    </Typography>
}

export default VehicleDocumentRow;