import {useTranslation} from "react-i18next";
import {Button, Checkbox, ClickAwayListener, TableCell, TableRow, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import {withStyles} from "@mui/styles";
import * as PropTypes from "prop-types";
import LocationOptionPrice from "./LocationOptionPrice";

function LocationOptionRow({
                               option,
                               deleteOptionHandler,
                               refreshDataHandler
                           }) {
    const defaultUniquePrice = false;

    const {t} = useTranslation('common');

    // Label
    const [editLabel, setEditLabel] = useState(false);

    // Desc
    const [editDesc, setEditDesc] = useState(false);

    const [uniquePrice, setUniquePrice] = useState(defaultUniquePrice);
    const [uniquePriceValue, setUniquePriceValue] = useState(0);

    function priceChangedHandler(price) {
        setUniquePriceValue(price);
        refreshDataHandler();
    }

    function determinateUniquePrice() {
        let price = null;
        for (const [index, priceData] of option.prices.entries()) {
            if (price == null) {
                price = priceData.price;
            } else {
                if (price != priceData.price) {
                    return false;
                }
            }
        }
        return true;
    }


    return <TableRow>
        <TableCell>
            {
                true ?
                    <ClickAwayListener onClickAway={() => {
                        setEditLabel(false);
                    }
                    }>
                        <TextField
                            style={{width: 200}}
                            margin="normal"
                            size="small"
                            fullWidth
                            id="option_label"
                            label={t('location.options.option_label')}
                            onChange={(e) => {
                                // setLabel(e.target.value);
                                option.name = e.target.value;
                            }}
                            defaultValue={option.name}
                        />
                    </ClickAwayListener>
                    :
                    <div width="100%" onClick={(e) => {
                        setEditLabel(true);
                    }
                    }>
                        {option.name}&nbsp;
                    </div>
            }

        </TableCell>
        <TableCell>
            {
                true ?
                    <ClickAwayListener onClickAway={() => {
                        setEditDesc(false);
                    }
                    }>
                        <TextField
                            style={{width: 200}}
                            multiline
                            rows={2}
                            margin="normal"
                            size="small"
                            fullWidth
                            id="option_desc"
                            label={t('location.options.option_description')}
                            onChange={(e) => {
                                // setDesc(e.target.value);
                                option.description = e.target.value;
                            }}
                            defaultValue={option.description}
                        />
                    </ClickAwayListener>
                    :
                    <div onClick={(e) => {
                        setEditDesc(true);
                    }
                    }>
                        {option.description}
                    </div>
            }

        </TableCell>
        <TableCell>
            <Checkbox checked={uniquePrice} onChange={e => setUniquePrice(e.target.checked)}/>
        </TableCell>
        {option.prices.map((priceData, index) => {
            return <TableCell
                key={index}>
                <LocationOptionPrice
                    uniquePriceValue={uniquePriceValue}
                    uniquePrice={uniquePrice}
                    priceChangedHandler={priceChangedHandler}
                    priceData={priceData}
                />
            </TableCell>
        })}
        <TableCell>
            <Button onClick={deleteOptionHandler}>X</Button>
        </TableCell>
    </TableRow>
}

const styles = theme => ({});

LocationOptionRow.propTypes = {
    classes: PropTypes.object.isRequired
};


export default withStyles(styles)(LocationOptionRow);